import { IoClose } from "react-icons/io5";
import Button from "components/button";
import listIcon from "assets/images/save-list.svg";
import OutlinedInput from "components/outlinedInput";
import { toast } from "react-toastify";
import nameCheck from "assets/images/name-check.svg";
import { useEditProspectName } from "services/prospecting-api";
import { useProspectStore } from "stores";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const EditListModal = (props) => {
  const {
    isOpen,
    onClose,
    saveEdit,
    currentId,
    fetchProspectList,
    edit,
    setEdit,
    prospectId,
  } = props;
  const navigate = useNavigate();
  const { prospect, setProspectInfo } = useProspectStore();
  const editNameMutation = useEditProspectName();
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsModalVisible(true);
      if (currentId?.prospect_name){
        setEdit?.(currentId?.prospect_name);
      } 
    } else {
      setIsModalVisible(false);
    }
  }, [isOpen, currentId, setEdit]);

  if (!isOpen && !isModalVisible) return null;

  const handleNameEdit = async () => {
    if (edit === "") {
      return toast.error("Please enter name");
    }
    try {
      const response = await editNameMutation.mutateAsync({
        prospect_id:
          prospectId || prospect?.prospect_id || prospect?.prospects_id,
        prospect_name: edit,
      });
      if (response.status === 200) {
        saveEdit?.(edit);
        onClose();
        toast.success("Name Updated", {
          icon: () => <img src={nameCheck} alt="name" />,
        });
        if (!fetchProspectList) {
          setProspectInfo({ ...prospect, prospect_name: edit });
        }
        fetchProspectList();
        setEdit?.("");
        navigate("/prospecting");
      }
    } catch (error) {
      if (error?.data && error?.data?.detail) {
        toast.error(error.data.detail);
      } else {
        console.log(error, "error");
      }
    }
  };

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[400ms] ease-in-out ${
        isModalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className="fixed inset-0 bg-black opacity-10"
        onClick={onClose}
      ></div>
      <div
        className={`bg-white p-[24px] rounded-lg modal-shadow z-10 w-full max-w-[600px] relative ease-in-out transition-all duration-[150ms] ${
          isModalVisible ? "scale-100" : "scale-95"
        }`}
      >
        <IoClose
          className="text-[#667085] text-[20px] w-[24px] h-[24px] absolute right-[24px] top-[26px] cursor-pointer"
          onClick={onClose}
        />
        <div className="modal-scroll">
          <div className="modal-scroll">
            <div className="flex flex-col items-start justify-start mb-[20px] font-inter">
              <div className="flex items-center justify-center border-[1px] border-[#E4E7EC] rounded-[10px] p-[11px] mb-[16px] y-axis-shadow">
                <img
                  src={listIcon}
                  alt="info img"
                  className="h-[24px] w-[24px]"
                />
              </div>
              <h4 className="font-[600] text-[18px] text-[#101828] m-0 font-inter">
                Edit Lead List Name
              </h4>
              <p className="font-normal text-[14px] text-[#475467] font-inter">
                Enter a new name for your list.
              </p>
            </div>
            <OutlinedInput
              value={edit}
              type="text"
              placeholder="Enter a Name"
              className="!text-[16px]"
              onChange={(event) => {
                setEdit(event?.target?.value);
              }}
            />
            <div className="flex items-center justify-center mt-[32px]">
              <Button
                title="Cancel"
                isWhiteBtn={true}
                isRounded="6px"
                onClick={onClose}
                className="!text-[16px] !h-[44px] !font-[600] !text-[#344054] y-axis-shadow !rounded-[8px]"
              />
              <Button
                title={
                  <div className="flex items-center justify-center">
                    {editNameMutation.isPending ? (
                      <div className="w-[20px] h-[20px] border-[3px] border-t-[3px] border-[#fff] border-solid rounded-full animate-spin border-t-[#8A43FF]" />
                    ) : (
                      "Save"
                    )}
                  </div>
                }
                secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9] ml-[12px]"
                className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow !rounded-[8px]"
                isRounded="6px"
                onClick={handleNameEdit}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditListModal;
