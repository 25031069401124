import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { FiMoreHorizontal } from "react-icons/fi";
import editIcon from "assets/images/edit.svg";
import archiveIcon from "assets/images/archive.svg";
import deleteIcon from "assets/images/action-delete.svg";
import { useCampaignStore } from "stores";

const CampaignActionButtons = (props) => {
  const {
    isEditModalOpen,
    setIsEditModalOpen,
    isArchiveModalOpen,
    setIsArchiveModalOpen,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    isShareModalOpen,
    setIsShareModalOpen,
    position,
    id,
    handleEdit,
    handleArchive,
    listType,
    handleUnArchive,
    setIsDraftModalOpen,
    isDraftModalOpen,
    menuIsOpen,
    setCurrentId,
    setPrefillCampaignName,
    name,
  } = props;

  const { setCampaignId } = useCampaignStore();
  const [menuOpen, setMenuOpen] = useState(menuIsOpen);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const [openUpwards, setOpenUpwards] = useState(false); // New state to handle dropdown direction

  useEffect(() => {
    setMenuOpen(menuIsOpen);
  }, [menuIsOpen]);

  const toggleMenu = (e) => {
    e.stopPropagation();
    const buttonRect = e?.target?.getBoundingClientRect();
    const viewportHeight = window?.innerHeight;
    const dropdownHeight = 140; // Approximate height of the dropdown
    const willOverflow =
      (buttonRect?.bottom || 0) + dropdownHeight > viewportHeight;

    setOpenUpwards(willOverflow);

    setDropdownPosition({
      top: willOverflow ? buttonRect.top - dropdownHeight : buttonRect.bottom,
      left: (buttonRect?.right || 0) - 188, // Adjust based on your dropdown width
    });

    setMenuOpen((prev) => !prev);
  };

  const closeMenu = (e) => {
    e?.stopPropagation();
    setMenuOpen(false);
  };

  const handleGetShareLink = async () => {
    setCampaignId(id);
  };

  const dropdownMenu = (
    <div
      className={`w-[192px] bg-[#fff] border-[1px] border-[#E6E7E9] rounded-[6px] absolute actionButton-shadow z-[0] ${
        openUpwards ? "bottom-[100px]" : "top-[15px]"
      } pt-[4px] pb-[2px]`}
      style={{
        top: !openUpwards && dropdownPosition.top,
        left: dropdownPosition.left,
      }}
      role="menu"
      aria-labelledby="more-options"
    >
      <ul className="list-none p-0 m-0">
        {listType === "Archive" && (
          <li
            className="flex items-center px-[12px] py-[6px] cursor-pointer text-[13px] font-[400] text-[#374151] hover:bg-[#cccccc1c] border-b-[1px] border-b-[#F3F4F6]"
            onClick={(e) => {
              e.stopPropagation();
              handleUnArchive(id);
              closeMenu();
              handleArchive(id, "unarchived");
            }}
          >
            <img
              src={editIcon}
              alt="Unarchive"
              className="w-[15px] h-[15px] mr-[9px]"
            />
            Unarchive
          </li>
        )}
        {listType !== "Archive" && (
          <>
            <li
              className="flex items-center px-[12px] py-[6px] cursor-pointer text-[13px] font-[400] text-[#374151] hover:bg-[#cccccc1c] border-b-[1px] border-b-[#F3F4F6]"
              onClick={(e) => {
                e.stopPropagation();
                handleEdit(id);
                setCurrentId(id);
                setPrefillCampaignName(name);
                setIsEditModalOpen(!isEditModalOpen);
                closeMenu();
              }}
            >
              <img
                src={editIcon}
                alt="Edit"
                className="w-[15px] h-[15px] mr-[9px]"
              />
              Edit Campaign Name
            </li>
            {listType !== "Drafts" && <li
              className="flex items-center px-[12px] py-[6px] cursor-pointer text-[13px] font-[400] text-[#374151] hover:bg-[#cccccc1c] border-b-[1px] border-b-[#F3F4F6]"
              onClick={(e) => {
                e.stopPropagation();
                handleArchive(id);
                setCurrentId(id);
                setIsArchiveModalOpen(!isArchiveModalOpen);
                closeMenu();
              }}
            >
              <img
                src={archiveIcon}
                alt="Archive"
                className="w-[15px] h-[15px] mr-[9px]"
              />
              Archive
            </li>}
            <li
              className="flex items-center px-[12px] py-[6px] cursor-pointer text-[13px] font-[400] text-[#374151] hover:bg-[#cccccc1c] border-b-[1px] border-b-[#F3F4F6]"
              onClick={(e) => {
                e.stopPropagation();
                setIsShareModalOpen(!isShareModalOpen);
                handleGetShareLink();
                closeMenu();
              }}
            >
              <img
                src={archiveIcon}
                alt="Share"
                className="w-[15px] h-[15px] mr-[9px]"
              />
              Share
            </li>
          </>
        )}
        <li
          className="flex items-center px-[12px] py-[6px] cursor-pointer text-[13px] font-[400] text-[#374151] hover:bg-[#cccccc1c]"
          onClick={(e) => {
            e.stopPropagation();
            setCurrentId(id);
            setIsDeleteModalOpen(!isDeleteModalOpen);
            closeMenu();
          }}
        >
          <img
            src={deleteIcon}
            alt="Delete"
            className="w-[15px] h-[15px] mr-[9px]"
          />
          Delete
        </li>
      </ul>
    </div>
  );

  return (
    <div className="relative w-[25px] menu-container">
      <FiMoreHorizontal
        className="text-[24px] block mx-auto text-[#5B6871] cursor-pointer pt-1"
        onClick={toggleMenu}
        aria-label="More options"
      />
      {menuOpen && (
        <>
          <div
            className="fixed top-0 left-0 w-full h-full z-50"
            onClick={closeMenu}
            aria-hidden="true"
          />
          {ReactDOM.createPortal(dropdownMenu, document.body)}
        </>
      )}
    </div>
  );
};

export default CampaignActionButtons;
