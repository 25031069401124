import SwitchToggle from "components/switchToggle";
import CampaignActionButtons from "../campaignActionButton";
import arrowDown from "assets/images/arrow-down.svg";
import helpIcon from "assets/images/Help-icon.svg";
import logoMark from "assets/images/Logomark-2.svg";
import happyFaceIcon from "assets/images/face-happy.svg";
import userRightIcon from "assets/images/user-right.svg";
import thumbsUpIcon from "assets/images/thumbs-up.svg";
import messageIcon from "assets/images/message-dots-square.svg";
import heartIcon from "assets/images/heart-square.svg";
import heartHandIcon from "assets/images/heart-hand.svg";
import ProgressBar from "components/progressBar";
import TooltipItem from "../tooltip";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import Loader from "components/loader";
import { useAccountStore, useCampaignStore, useSettingStore } from "stores";
import InfiniteScroll from "react-infinite-scroll-component";
import Stars from "assets/images/rocket.png";
import CommonReactTooltip from "components/commonReactTooltip";
import { Tooltip } from "react-tooltip";
import rb2b from "assets/images/jira1.png";
import Webhooks from "assets/images/webhooks.svg";

const tableHeader = [
  { title: "Campaign Name", icon: "arrow", key: "campaign_name" },
  { title: "Stats", icon: "" },
  { title: "Progress", icon: "help" },
  { title: "Created", icon: "arrow", key: "created_ts" },
  { title: "", icon: "" },
];

const CampaignTable = ({
  isEditModalOpen,
  setIsEditModalOpen,
  isArchiveModalOpen,
  setIsArchiveModalOpen,
  isDeleteModalOpen,
  setIsDeleteModalOpen,
  isShareModalOpen,
  setIsShareModalOpen,
  campaignTableData,
  handleEdit,
  handleArchive,
  handleUnArchive,
  setIsDraftModalOpen,
  isDraftModalOpen,
  tab,
  menuIsOpen,
  onChange,
  isFetching,
  handleTableSort,
  sortBy,
  sortOrder,
  setCurrentId,
  setPrefillCampaignName,
  totalItems,
  onRefetch,
  hasMore,
}) => {
  const navigate = useNavigate();
  const {
    setCampaignId,
    setCampaign,
    setIsDraftCampaignClicked,
    clearTrackerId,
    setDraftListId,
  } = useCampaignStore();
  const { linkedinAccountDetails } = useAccountStore();

  const [tooltipVisibility, setTooltipVisibility] = useState({});

  const handleMouseEnter = (rowIndex, key) => {
    setTooltipVisibility((prev) => ({
      ...prev,
      [`${rowIndex}-${key}`]: true,
    }));
  };

  const handleMouseLeave = (rowIndex, key) => {
    setTooltipVisibility((prev) => ({
      ...prev,
      [`${rowIndex}-${key}`]: false,
    }));
  };

  const handleNavigate = (percentage, item) => {
    clearTrackerId();
    setDraftListId(item?.list_id);
    if (tab === "Drafts") {
      setIsDraftCampaignClicked(item?.campaign_setup || "steps_pending");
      setCampaignId(item?.campaign_id);
      navigate("/new-campaign");
      return;
    }
    navigate("/default-activity");
    setCampaign(item);
    setCampaignId(item?.campaign_id);
  };
  const fetchData = () => {
    setTimeout(() => {
      onRefetch();
    }, 500);
  };

  return (
    <>
      <div className="border border-[#E4E7EC] rounded-xl" id="createCampaign">
        <div
          className={`round-table-scroll overflow-auto ${
            linkedinAccountDetails?.reconnect_status
              ? "max-h-[calc(100vh_-300px)]"
              : "max-h-[calc(100vh_-210px)]"
          }`}
          id="scrollableDiv"
        >
          <InfiniteScroll
            dataLength={campaignTableData?.length || 0}
            next={fetchData}
            hasMore={campaignTableData?.length < totalItems && hasMore}
            loader={
              campaignTableData?.length !== totalItems && (
                <div className="h-[50px] w-full flex items-center justify-center">
                  <div className="w-[20px] h-[20px] border-[3px] border-t-[3px] border-[#7F56D9] border-solid rounded-full animate-spin border-t-[#fff]" />
                </div>
              )
            }
            scrollableTarget="scrollableDiv"
            style={{ height: "100%", overflow: "unset" }}
          >
            <table className="min-w-full h-unset rounded-xl">
              <thead className="sticky top-0 !z-[100] !bg-[#fff] overflow-hidden rounded-[12px]">
                <tr className={`w-full relative`}>
                  {tableHeader.map((header, index) => {
                    const isLastThead = index === tableHeader.length - 1;
                    return (
                      <th
                        key={index}
                        className={`text-[12px] cursor-pointer font-[500] py-[11.8px] px-[22px] text-[#475467] text-left font-inter first:rounded-tl-[12px] last:rounded-tr-[12px] ${
                          isLastThead && "w-[100px]"
                        }`}
                        onClick={() =>
                          header?.key && handleTableSort(header?.key)
                        }
                      >
                        <div
                          className={`flex items-center relative ${
                            header.title === "Campaign Name" ? "ml-10" : "ml-0"
                          }`}
                        >
                          <span>{header.title}</span>
                          {header.icon === "arrow" && (
                            <img
                              src={arrowDown}
                              alt="arrowDown"
                              className={`cursor-pointer ml-[4px] w-[10px] h-[10px] ${
                                sortBy === header?.key && sortOrder === "desc"
                                  ? "rotate-180"
                                  : ""
                              }`}
                            />
                          )}
                          {header.icon === "help" && (
                            <img
                              src={helpIcon}
                              alt="help"
                              className="ml-[4px]"
                              data-tooltip-id="campaign-help-tooltip"
                            />
                          )}
                        </div>
                      </th>
                    );
                  })}
                </tr>
                <span className="w-full border-b border-[#E4E7EC] h-[1px] absolute top-full z-10 left-0" />
              </thead>
              <tbody>
                {isFetching ? (
                  <tr>
                    <td colSpan={tableHeader.length}>
                      <div className="w-full flex items-center justify-center h-[200px]">
                        <div className="mt-[30px] ml-[-10px]">
                          <Loader />
                        </div>
                      </div>
                    </td>
                  </tr>
                ) : campaignTableData?.length > 0 ? (
                  campaignTableData?.map((item, rowIndex, arr) => {
                    const percentage = item?.progress;
                    const lastIndexes = [
                      campaignTableData.length - 2,
                      campaignTableData.length - 1,
                    ];
                    return (
                      <tr
                        className={`${
                          rowIndex !== arr.length - 1 ? "border-b" : ""
                        } hover:bg-gray-50 cursor-pointer`}
                        key={rowIndex}
                      >
                        <td
                          onClick={() => handleNavigate(percentage, item)}
                          className={`${
                            lastIndexes && "first:rounded-bl-[12px]"
                          } text-[13px] py-[18px] px-[22px] text-left w-[550px] max-[1300px]:w-[unset]`}
                        >
                          <div className="flex items-center">
                            {tab !== "Drafts" && (
                              <div
                                className="campaign-toggle mr-[12px] mt-[2px] cursor-pointer"
                                onClick={(e) => e.stopPropagation()}
                              >
                                <SwitchToggle
                                  checked={item?.active_status}
                                  onChange={() =>
                                    onChange(item, item?.campaign_id)
                                  }
                                />
                              </div>
                            )}
                            <div className="flex items-start mt-0.5">
                              {item?.rb2b_integration ? (
                                <img
                                  src={rb2b}
                                  alt="logo mark"
                                  className="w-[21.5px] h-[21.5px] mr-[11.3px]"
                                />
                              ) : (
                                <>
                                  {item?.integration === "Webhook" ? (
                                    <img
                                      src={Webhooks}
                                      alt="logo mark"
                                      className="w-[21.5px] h-[21.5px] mr-[11.3px]"
                                    />
                                  ) : (
                                    <img
                                      src={logoMark}
                                      alt="logo mark"
                                      className="w-[22.7px] h-[22.7px] mr-[11.3px]"
                                    />
                                  )}
                                </>
                              )}
                              <p className="text-[#101828] break-words text-[13px] font-inter font-medium line-clamp-2 webkit-box max-w-full">
                                {item?.campaign_name}
                              </p>
                              {item?.campaign_name?.length > 70 && (
                                <Tooltip
                                  id={`campaign-${rowIndex}`}
                                  className={`actionButton-shadow !bg-[#7F56D9] !z-[9999] !rounded-[8px] !py-[8px] !px-[12px] !max-w-[380px]`}
                                  place="bottom"
                                >
                                  <p className="text-[#fff] text-[600] text-[12px] font-semibold break-all">
                                    {item?.prospect_name}
                                  </p>
                                </Tooltip>
                              )}
                            </div>
                          </div>
                        </td>
                        <td
                          onClick={() => handleNavigate(percentage, item)}
                          className="text-[13px] py-[18px] px-[22px] text-[#252C32] text-left  w-[500px] max-[1300px]:w-[unset]"
                        >
                          <div className="flex items-center relative">
                            <TooltipItem
                              key="happyFace"
                              icon={happyFaceIcon}
                              value={item?.profile_viewed}
                              text="Profile Views"
                              isVisible={
                                tooltipVisibility[`${rowIndex}-happyFace`] ||
                                false
                              }
                              onMouseEnter={() =>
                                handleMouseEnter(rowIndex, "happyFace")
                              }
                              onMouseLeave={() =>
                                handleMouseLeave(rowIndex, "happyFace")
                              }
                            />
                            <TooltipItem
                              key="userRight"
                              icon={userRightIcon}
                              value={item?.invites_sent}
                              text="Requests Sent"
                              isVisible={
                                tooltipVisibility[`${rowIndex}-userRight`] ||
                                false
                              }
                              onMouseEnter={() =>
                                handleMouseEnter(rowIndex, "userRight")
                              }
                              onMouseLeave={() =>
                                handleMouseLeave(rowIndex, "userRight")
                              }
                            />
                            <TooltipItem
                              key="thumbsUp"
                              icon={thumbsUpIcon}
                              value={item?.invites_accepted}
                              text="Accepted"
                              isVisible={
                                tooltipVisibility[`${rowIndex}-thumbsUp`] ||
                                false
                              }
                              onMouseEnter={() =>
                                handleMouseEnter(rowIndex, "thumbsUp")
                              }
                              onMouseLeave={() =>
                                handleMouseLeave(rowIndex, "thumbsUp")
                              }
                            />
                            <TooltipItem
                              key="message"
                              icon={messageIcon}
                              value={item?.followup_sent}
                              text="Follow-ups Sent"
                              isVisible={
                                tooltipVisibility[`${rowIndex}-message`] ||
                                false
                              }
                              onMouseEnter={() =>
                                handleMouseEnter(rowIndex, "message")
                              }
                              onMouseLeave={() =>
                                handleMouseLeave(rowIndex, "message")
                              }
                            />
                            <TooltipItem
                              key="heart"
                              icon={heartIcon}
                              value={item?.like_posts}
                              text="Liked Posts"
                              isVisible={
                                tooltipVisibility[`${rowIndex}-heart`] || false
                              }
                              onMouseEnter={() =>
                                handleMouseEnter(rowIndex, "heart")
                              }
                              onMouseLeave={() =>
                                handleMouseLeave(rowIndex, "heart")
                              }
                            />
                            <TooltipItem
                              key="heartHand"
                              icon={heartHandIcon}
                              value={item?.like_comments}
                              text="Comments Liked"
                              isVisible={
                                tooltipVisibility[`${rowIndex}-heartHand`] ||
                                false
                              }
                              onMouseEnter={() =>
                                handleMouseEnter(rowIndex, "heartHand")
                              }
                              onMouseLeave={() =>
                                handleMouseLeave(rowIndex, "heartHand")
                              }
                            />
                          </div>
                        </td>
                        <td
                          onClick={() => handleNavigate(percentage, item)}
                          className="text-[13px] py-[18px] px-[22px] text-[#252C32] text-left w-[300px] max-[1300px]:w-[unset]"
                        >
                          <div className="w-[200px]">
                            <ProgressBar
                              percentage={item?.progress}
                              activeGreenColor={true}
                            />
                          </div>
                        </td>
                        <td
                          onClick={() => handleNavigate(percentage, item)}
                          className="text-[13px] py-[18px] px-[22px] text-[#252C32] text-left w-[280px] max-[1300px]:w-[unset]"
                        >
                          <p className="text-[#475467] break-words text-[13px] font-inter">
                            {moment(item?.created_ts).format(
                              "MM/DD/YYYY [at] hh:mm A"
                            )}
                          </p>
                        </td>
                        <td
                          className={`${
                            lastIndexes && "last:rounded-br-[12px]"
                          } py-[18px] px-[22px] flex justify-center z-20 w-[100px]`}
                        >
                          <CampaignActionButtons
                            // position={
                            //   rowIndex > 7
                            //     ? lastIndexes.includes(rowIndex)
                            //       ? "!bottom-[20px]"
                            //       : "top-[20px]"
                            //     : null
                            // }
                            isEditModalOpen={isEditModalOpen}
                            setIsEditModalOpen={setIsEditModalOpen}
                            isArchiveModalOpen={isArchiveModalOpen}
                            setIsArchiveModalOpen={setIsArchiveModalOpen}
                            isDeleteModalOpen={isDeleteModalOpen}
                            setIsDeleteModalOpen={setIsDeleteModalOpen}
                            isShareModalOpen={isShareModalOpen}
                            setIsShareModalOpen={setIsShareModalOpen}
                            handleEdit={handleEdit}
                            id={item?.campaign_id}
                            handleArchive={handleArchive}
                            listType={tab}
                            handleUnArchive={handleUnArchive}
                            setIsDraftModalOpen={setIsDraftModalOpen}
                            isDraftModalOpen={isDraftModalOpen}
                            menuIsOpen={menuIsOpen}
                            setCurrentId={setCurrentId}
                            setPrefillCampaignName={setPrefillCampaignName}
                            name={item?.campaign_name}
                          />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td
                      colSpan={tableHeader.length}
                      className="text-[14px] px-[22px] py-[10px] text-center text-[#474563] h-[200px]"
                    >
                      No Data Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </InfiniteScroll>
        </div>
      </div>
      {/* help tooltip */}
      <CommonReactTooltip
        placement="top"
        id="campaign-help-tooltip"
        icon={Stars}
        title="Progress"
        description="See the percentage of campaign steps that are completed."
        className="!w-[239px]"
      />
    </>
  );
};

export default CampaignTable;
