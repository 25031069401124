import { capitalizeWords } from "utils/index";
import profileViewed from "assets/images/profileViewed.svg";
import inviteSent from "assets/images/invite.svg";
import iconFollowUp from "assets/images/iconFollowUp.svg";
import likePost from "assets/images/likePost.svg";
import likeComment from "assets/images/likeComment.svg";
import connected from "assets/images/connected.svg";
import outlinedClose from "assets/images/cross-icon.png";
import linkedinIcon from "assets/images/colored-linkedin.svg";

export const renderStatus = (status) => {
  switch (status) {
    case "PROFILE VIEWED":
      return (
        <>
          <img src={profileViewed} alt="" className="mr-[5px] w-[16px]" />
          {capitalizeWords(status)}
        </>
      );
    case "NOT STARTED":
      return (
        <>
          <span
            className={`${
              status === "NOT STARTED" ? "bg-[#577cff]" : "bg-[#17B26A]"
            } w-[6px] h-[6px] rounded-full mr-[6px] block`}
          />
          Queued
        </>
      );
    case "ACCEPTED":
      return (
        <>
          <span
            className={`${"bg-[#17B26A]"} w-[6px] h-[6px] rounded-full mr-[6px] block`}
          />
          <img src={linkedinIcon} alt="" className="mr-[5px] w-[12px] h-3" />
          {capitalizeWords(status)}
        </>
      );
    case "REQUEST SENT":
      return (
        <>
          <img src={inviteSent} alt="" className="mr-[5px] w-[16px]" />
          Invite Sent
        </>
      );
    case "FOLLOWUP SENT":
      return (
        <>
          <img
            src={iconFollowUp}
            alt=""
            className="mr-[5px] w-[12px] h-[12px]"
          />
          Sent a Follow-Up Message
        </>
      );
    case "REPLIED":
      return (
        <>
          <span
            className={`${"bg-[#577cff]"} w-[6px] h-[6px] rounded-full mr-[6px] block`}
          />
          {capitalizeWords(status)}
        </>
      );
    case "WITHDRAWN":
      return (
        <>
          <span className="bg-[#F43F5E] w-[6px] h-[6px] rounded-full mr-[6px] block" />
          Withdrew
        </>
      );
    case "POST LIKED":
      return (
        <>
          <img src={likePost} alt="" className="mr-[5px] w-3 h-3" />
          Liked Post
        </>
      );
    case "COMMENT LIKED":
      return (
        <>
          <img src={likeComment} alt="" className="mr-[5px] w-[16px]" />
          Liked Comment
        </>
      );
    case "ALREADY CONNECTED":
      return (
        <>
          <img src={connected} alt="" className="mr-[5px] w-[16px]" />
          Already Connected
        </>
      );
    case "NO POSTS FOUND":
      return (
        <>
          <img src={outlinedClose} alt="close" className="w-[14px] mr-[4px]" />
          No Post Found
        </>
      );
    case "NO COMMENTS FOUND":
      return (
        <>
          <img src={outlinedClose} alt="close" className="w-[14px] mr-[4px]" />
          No Comments Found
        </>
      );
    case "ACTION FAILED":
      return (
        <>
          <span className="bg-[#F43F5E] w-[6px] h-[6px] rounded-full mr-[6px] block" />
          Action Failed
        </>
      );
    case "RESPONDED":
      return (
        <>
          <span className="bg-[#577cff] w-[6px] h-[6px] rounded-full mr-[6px] block" />
          {capitalizeWords(status)}
        </>
      );
    default:
      break;
  }
};
export const renderHistoryStatus = (status) => {
  switch (status) {
    case "View Profile":
      return (
        <>
          <img src={profileViewed} alt="" className="mr-[5px] w-[16px]" />
          {capitalizeWords(status)}
        </>
      );
    case "Like Post":
      return (
        <>
          <img src={likePost} alt="" className="mr-[5px] w-3 h-3" />
          Liked Post
        </>
      );
    case "Send Follow-Up Message":
      return (
        <>
          <img
            src={iconFollowUp}
            alt=""
            className="mr-[5px] w-[12px] h-[12px]"
          />
          Sent a Follow-Up Message
        </>
      );
    case "Like Comment":
      return (
        <>
          <img src={likeComment} alt="" className="mr-[5px] w-[16px]" />
          Liked Comment
        </>
      );
    case "Send Connection Request":
      return (
        <>
          <img src={inviteSent} alt="" className="mr-[5px] w-[16px]" />
          Send Connection Request
        </>
      );

    case "Invite Accepted":
      return (
        <>
          <span
            className={`${"bg-[#17B26A]"} w-[6px] h-[6px] rounded-full mr-[6px] block`}
          />
          <img src={linkedinIcon} alt="" className="mr-[5px] w-[12px] h-3" />
          {capitalizeWords(status)}
        </>
      );
    case "No Comment Found":
      return (
        <>
          <img src={outlinedClose} alt="close" className="w-[14px] mr-[4px]" />
          No Comment Found
        </>
      );
    case "No Post Found":
      return (
        <>
          <img src={outlinedClose} alt="close" className="w-[14px] mr-[4px]" />
          No Post Found
        </>
      );
    case "Replied":
      return (
        <>
          <span
            className={`bg-[#577cff] w-[6px] h-[6px] rounded-full mr-[6px] block`}
          />
          {capitalizeWords(status)}
        </>
      );
    case "Withdrawn":
      return (
        <>
          <span className="bg-[#F43F5E] w-[6px] h-[6px] rounded-full mr-[6px] block" />
          Withdrew
        </>
      );
      case "Manually Followed Up":
        return (
          <>
            <img
              src={iconFollowUp}
              alt=""
              className="mr-[5px] w-[12px] h-[12px]"
            />
            Manually Followed Up
          </>
        );
      case "Manually Removed from Campaign":
      return (
        <>
          {" "}
          <span className="bg-[#F43F5E] w-[6px] h-[6px] rounded-full mr-[6px] block" />
          Manually Removed from Campaign
        </>
      );
    default:
      break;
  }
};
