import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "assets/images/logo.svg";
import sideBarBg from "assets/images/sidebarbg.png";
import MinimalButton from "assets/images/minimalButton.svg";
import UserAvatar from "assets/images/emptyAvatar.svg";
import expandIcon from "assets/images/expandIcon.svg";
import helpIcon from "assets/images/helpIcon.svg";
import logoutIcon from "assets/images/logoutIcon.svg";
import { menuItems } from "constant";
import { AppContext } from "context/AppContext";
import useSettingStore from "stores/settingStore";
import { useAuthStore, useCampaignStore } from "stores";
import useRandomImagesStore from "stores/randomImagesStore";
import { getNameInititals, getRandomProfileLogoWithRandom } from "utils/index";
import { logout } from "utils/user";
import GuidedTour from "assets/images/guided-tour.svg";
import useGuidedTourStore from "stores/guidedTourStore";

const Sidebar = ({ isFailed }) => {
  const { setAppState } = useContext(AppContext);
  const storedSideBarValue = JSON.parse(
    localStorage.getItem("isSidebarExpand") || "false"
  );
  const { isAdmin } = useAuthStore();
  const { userData } = useSettingStore();
  const { setRunTour } = useGuidedTourStore();
  const { userDetails } = useAuthStore();

  const {
    clearIsDraftCampaignClicked,
    clearTrackerId,
    clearDraftListId,
    clearOpenLeadsTabDirectly,
  } = useCampaignStore();

  const { profileImage, setProfileImage, setCompanyImage } =
    useRandomImagesStore();
  const navigate = useNavigate();
  const location = useLocation();
  const [expand, setExpand] = useState(storedSideBarValue);
  const [menu, setMenu] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [tooltip, setTooltip] = useState({
    settings: false,
    helpCenter: false,
    logout: false,
  });
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [randomProfileLogoImg, setRandomProfileLogoImg] = useState(
    getRandomProfileLogoWithRandom(profileImage)
  );

  useEffect(() => {
    if (profileImage) {
      const image = getRandomProfileLogoWithRandom(profileImage);
      setRandomProfileLogoImg(image);
    } else {
      const randomNumber = Math.random();
      setProfileImage(randomNumber);
      setCompanyImage(randomNumber);
      const image = getRandomProfileLogoWithRandom(profileImage);
      setRandomProfileLogoImg(image);
    }
  }, [userDetails]);

  const menuPopupRef = useRef(null);
  const userProfileRef = useRef(null);

  const getSettingsData = async () => {
    try {
      setName(userData?.user?.name || userDetails?.name);
      setEmail(userData?.user?.email || userDetails?.email);
    } catch (error) {}
  };

  useEffect(() => {
    getSettingsData();
  }, [userData]);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (mounted) {
      setExpand(storedSideBarValue);
    }
  }, [storedSideBarValue, mounted]);

  const handleExpandToggle = () => {
    setExpand((prev) => {
      const newExpand = !prev;
      localStorage.setItem("isSidebarExpand", JSON.stringify(newExpand));
      return newExpand;
    });
  };

  const handleClickOutside = (event) => {
    if (
      menuPopupRef.current &&
      !menuPopupRef.current.contains(event.target) &&
      !userProfileRef.current.contains(event.target)
    ) {
      setMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleProfileClick = () => {
    setMenu((prev) => !prev);
  };

  const handleNavigate = (route) => {
    clearIsDraftCampaignClicked();
    clearTrackerId();
    clearDraftListId();
    clearOpenLeadsTabDirectly();
    navigate(route);
    setAppState((prev) => ({
      ...prev,
      isSendRequestCheckBoxChecked: true,
      selectedFollowUpMessageOptoin: null,
      selectedConnectionRequestMessageOption: null,
      existingConnectionMessageOption: null,
      sendConnectionRequestMessage: ``,
      followUpMessage: ``,
      existingConnectionMessage: ``,
      alternativeEmail: "",
      selectedLeadList: null,
      numberOfLeadsToEnroll: "",
      activeLeadListSteps: [0],
      activeCampaignName: "",
      leadsStepCompleted: false,
      stepsStepCompleted: false,
      settingsStepCompleted: false,
    }));
  };

  return (
    <div
      className={`h-[100vh] relative ${
        mounted ? "transition-all duration-1000 ease-in-out" : ""
      } ${expand ? "w-[62px]" : "w-[230px]"} py-[10px] px-[6px] ${
        isFailed ? "z-[999]" : ""
      }`}
      style={{
        backgroundImage: `url(${sideBarBg})`,
        backgroundSize: "100% 100%",
        boxShadow: "10px 24px 54px 0px #0000000A",
      }}
    >
      <div className="flex items-center justify-between">
        <img
          src={Logo}
          alt="Logo"
          className={`cursor-pointer ${
            mounted ? "transition-all duration-1000 ease-in-out" : ""
          } ${
            expand
              ? "ml-[8px] h-[40px] w-[40px] min-w-[40px]"
              : "ml-[8px] h-[40px] w-[40px] min-w-[40px]"
          }`}
        />
        <img
          src={MinimalButton}
          alt="MinimalButton"
          className={`h-[24px] w-[24px] cursor-pointer mr-[2px] ${
            expand ? "opacity-0 z-[-1]" : "opacity-100"
          } ${mounted ? "transition-all duration-1000 ease-in-out" : ""}`}
          onClick={handleExpandToggle}
        />
      </div>
      <ul className={`mt-[17px] h-[calc(100vh_-158px)]`}>
        {menuItems?.map((item, index) => {
          const isActive = item?.route === location?.pathname;
          return (
            <li
              className={`flex items-center p-[6px] cursor-pointer hover:bg-[#FFFFFF0D] rounded-[4px] transition-all duration-1000 ease-in-out mb-[10px] ${
                isActive ? "active bg-[#FFFFFF0D]" : ""
              } ${item?.route?.length > 0 ? "group menuItem" : ""} ${
                expand ? "h-[36px] w-[36px] m-auto" : "w-[215px] h-[unset]"
              }`}
              key={index}
              onClick={() => handleNavigate(item?.route)}
              onMouseEnter={() =>
                setTooltip((prev) => ({
                  ...prev,
                  [item?.name.toLowerCase().replace(" ", "")]: true,
                }))
              }
              onMouseLeave={() =>
                setTooltip((prev) => ({
                  ...prev,
                  [item?.name.toLowerCase().replace(" ", "")]: false,
                }))
              }
            >
              {item?.name === "Inbox" ? (
                <img
                  src={item?.icon}
                  alt={item?.name}
                  className={`w-[16px] h-[16px] mr-2 ml-1 ${
                    item?.route?.length > 0 ? "opacity-60" : "opacity-30"
                  }  ${index === 0 && "!opacity-100"} transition-all ${
                    expand ? "mr-2 ml-1 " : "ml-0"
                  } ${
                    mounted ? "transition-all duration-1000 ease-in-out" : ""
                  }`}
                />
              ) : (
                <img
                  src={item?.icon}
                  alt={item?.name}
                  className={`w-[24px] h-[24px] mr-[4px] ${
                    item?.route?.length > 0 ? "opacity-60" : "opacity-30"
                  }  ${index === 0 && "!opacity-100"} transition-all ${
                    expand ? "ml-[0px] mr-0" : "ml-0"
                  } ${
                    mounted ? "transition-all duration-1000 ease-in-out" : ""
                  }`}
                />
              )}
              <p
                className={`text-[14px] font-[500] ${
                  isActive ? "text-primary" : "text-[#D3D3D3]"
                } transition-all ${
                  expand
                    ? "opacity-0"
                    : item?.route?.length > 0
                    ? "opacity-100"
                    : "opacity-50"
                } ${mounted ? "transition-all duration-1000 ease-in-out" : ""}`}
              >
                {item?.name}
              </p>
              {expand && tooltip[item?.name.toLowerCase().replace(" ", "")] && (
                <div
                  id="tooltip-hover"
                  role="tooltip"
                  className="absolute left-[55px] z-[999] opacity-100 inline-block px-3 py-[4px] text-[12px] font-[400] text-white bg-gray-900 rounded-[6px] shadow-sm tooltip dark:bg-gray-700 border border-[#3C3C3C]"
                >
                  {item?.name}
                </div>
              )}
            </li>
          );
        })}
      </ul>
      <img
        src={MinimalButton}
        alt="MinimalButton"
        className={`h-[24px] w-[24px] cursor-pointer rotate-180 m-auto mb-[12px] ${
          !expand ? "opacity-0" : "opacity-100"
        } ${mounted ? "transition-all duration-1000 ease-in-out" : ""}`}
        onClick={handleExpandToggle}
      />
      {/* menu popup */}
      <div
        ref={menuPopupRef}
        className={`menu-popup bottom-[65px] transition-all fixed z-[999] duration-[400ms] delay-75 ease-in-out flex items-center rounded-[7px] cursor-pointer overflow-hidden ${
          expand ? "w-[150px]" : "block w-[218px] !bg-[#EAEAEA26]"
        } ${menu ? "max-h-[140px]" : "max-h-0 p-0"} 
        ${menu && expand ? "bg-[#6325C9]" : ""}`}
      >
        <ul className="w-full p-[7px]">
          <li
            className="p-[10px] cursor-pointer flex justify-between items-center rounded-[4px] hover:bg-[#FFFFFF0D] group"
            onClick={() => {
              setMenu(!menu);
              if (location?.pathname !== "/dashboard") {
                navigate("/dashboard");
                setRunTour(true);
              } else {
                setRunTour(true);
              }
            }}
          >
            <p className="text-[#D3D3D3] text-[11px] font-[400] transition-all duration-1000 ease-in-out">
              Guided Tour{" "}
            </p>
            <img src={GuidedTour} alt="GuidedTour" className="mb-[-2px]" />
          </li>
          <li
            className="p-[10px] cursor-pointer flex justify-between items-center rounded-[4px] hover:bg-[#FFFFFF0D] group"
            onClick={() => {
              setMenu(!menu);
              window.open(
                "https://pipeline-knowledge-base.help.usepylon.com",
                "_blank"
              );
            }}
          >
            <p className="text-[#D3D3D3] text-[11px] font-[400] transition-all duration-1000 ease-in-out">
              Help Center
            </p>
            <img src={helpIcon} alt="help" />
          </li>
          <li
            className="p-[10px] cursor-pointer flex justify-between items-center rounded-[4px] hover:bg-[#FFFFFF0D] group"
            onClick={() => logout()}
          >
            <p className="text-[#D3D3D3] text-[11px] font-[400] transition-all duration-1000 ease-in-out">
              Logout
            </p>
            <img src={logoutIcon} alt="logout" />
          </li>
        </ul>
      </div>

      <div
        ref={userProfileRef}
        className={`bg-[#EAEAEA26] p-[7px] flex items-center rounded-[7px] cursor-pointer h-[47px]`}
        onClick={handleProfileClick}
      >
        {userData?.user?.profile_pic || isAdmin ? (
          <img
            src={
              userData?.user?.profile_pic ||
              userDetails?.profile_pic ||
              randomProfileLogoImg?.src ||
              UserAvatar
            }
            alt="user"
            className="h-[34px] w-[34px] mr-[10px] rounded-full"
          />
        ) : (
          <div className="h-[34px] w-[34px] min-w-[34px] rounded-full flex items-center justify-center text-[14px] font-[700] text-[#4409B9] bg-[#EEEDFD] mr-[12px]">
            {getNameInititals(userData?.user?.name)}
          </div>
        )}
        <div
          className={`flex items-center w-full ${expand && "z-[-1]"} flex-1`}
        >
          <div
            className={`flex-1 ${
              expand ? "opacity-0" : "opacity-100"
            } transition-all duration-1000 ease-in-out delay-300`}
          >
            <h5
              className={`text-[12px] font-[500] text-white text-ellipsis overflow-hidden whitespace-nowrap max-w-[135px] capitalize ${
                expand ? "opacity-0" : "opacity-100"
              } transition-all duration-1000 ease-in-out`}
            >
              {name}
            </h5>
            <p
              className={`text-[10px] font-[400] text-[#ddd] text-ellipsis overflow-hidden whitespace-nowrap max-w-[135px] ${
                expand ? "opacity-0" : "opacity-100"
              } transition-all duration-1000 ease-in-out`}
            >
              {email}
            </p>
          </div>
          <img
            src={expandIcon}
            alt="expand"
            className={`transition-all duration-[1200ms] ease-in-out ${
              expand ? "opacity-0 w-0" : "opacity-100 h-[25px] w-[25px]"
            }`}
          />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
