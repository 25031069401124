import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IoClose } from "react-icons/io5";
import Button from "components/button";
import { useEditInviteEmail } from "services/settings-api";
import inviteSent from "assets/images/loveletter.png";
import { toast } from "react-toastify";

const EditEmailModal = (props) => {
  const { isOpen, onClose, editEmail, refetch } = props;
  const emailEdit = useEditInviteEmail();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      email: editEmail?.email || "",
    },
  });

  useEffect(() => {
    if (isOpen && editEmail?.email) {
      setValue("email", editEmail?.email);
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [isOpen, editEmail, setValue]);

  const onSubmitForm = async (data) => {
    const payload = {
      collaborator_id: editEmail?.collaborator_id,
      proposed_email: data?.email,
    };
    try {
      const response = await emailEdit.mutateAsync(payload);
      if (response?.status === 200) {
        refetch();
        toast.success(response?.message, {
          icon: () => (
            <img src={inviteSent} alt="name" className="!w-[46px] !h-[16px]" />
          ),
        });
        onClose();
      }
    } catch (error) {
      onClose();
      toast.error(error?.data?.detail);
    }
  };

  if (!isOpen && !isModalVisible) return null;

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[300ms] ease-in-out ${
        isModalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className="fixed inset-0 bg-black opacity-10"
        onClick={onClose}
      ></div>
      <div
        className={`bg-white p-[24px] rounded-lg modal-shadow z-10 w-full max-w-[400px] relative transition-all ease-in-out duration-[150ms] ${
          isModalVisible ? "scale-100" : "scale-95"
        }`}
      >
        <IoClose
          className="text-[#667085] text-[20px] w-[24px] h-[24px] absolute right-[24px] top-[26px] cursor-pointer"
          onClick={() => {
            onClose();
            setValue("email", "");
          }}
        />
        <div className="modal-scroll">
          <div className="flex flex-col items-start justify-start mb-[20px] font-inter">
            <h4 className="font-[600] text-[18px] text-[#101828] mb-[5px] font-inter">
              Edit Email Invite
            </h4>
            <p className="font-normal text-[14px] text-[#475467] font-inter mb-[10px]">
              Please enter a new email to send the invite to.
            </p>
            <form onSubmit={handleSubmit(onSubmitForm)} className="w-full">
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="text-sm font-medium text-[#344054] mb-2 block"
                >
                  Email
                </label>
                <input
                  type="text"
                  className="common-input font-inter text-[16px] font-normal placeholder:text-[#667085] py-[9px] px-[14px] y-axis-shadow w-full h-[40px] rounded-[7.5px] border !border-[#D0D5DD] !mb-[4px] !bg-[#fff] text-[#000] outline-none"
                  placeholder="Enter email"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  })}
                  onDrop={(e) => e.preventDefault()}
                  onDragOver={(e) => e.preventDefault()}
                />
                {errors.email && (
                  <div className="text-red-500 text-sm mt-1">
                    {errors.email.message}
                  </div>
                )}
              </div>
              <div className="flex items-center justify-center mt-[30px]">
                <Button
                  type="button"
                  title="Cancel"
                  isWhiteBtn={true}
                  onClick={(e) => {
                    e.preventDefault(); // Prevents form submission
                    e.stopPropagation(); // Prevents event from propagating and triggering form submit
                    onClose(); // Close modal
                    setValue("email", ""); // Reset form values
                  }}
                  className="!text-[16px] !h-[44px] !font-[600] !text-[#344054] y-axis-shadow !rounded-[8px]"
                />
                <Button
                  type="submit"
                  title={
                    emailEdit.isPending ? (
                      <div className="w-[20px] h-[20px] mx-auto border-[3px] border-t-[3px] border-[#fff] border-solid rounded-full animate-spin border-t-[#8A43FF]" />
                    ) : (
                      "Send Invite"
                    )
                  }
                  disabled={isSubmitting}
                  secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9] ml-[12px]"
                  className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditEmailModal;
