import arrowIcon from "assets/images/arrow-square-right.svg";
import { useGetLinkedinActivity } from "services/dashboard-api";
import { useEffect, useState } from "react";
import Loader from "components/loader";
import moment from "moment-timezone";
import { getNameInititals } from "utils/index";
import { useSettingStore } from "stores";
import redHeartIcon from "assets/images/red-heart.svg";
import connectIcon from "assets/images/connection.svg";
import sentMsgIcon from "assets/images/sent-up-msg.svg";
import purpleSmileIcon from "assets/images/purple-smile.svg";
import useGuidedTourStore from "stores/guidedTourStore";
import { SampleGuidedTourActivity } from "constant";

const ActivityFeedModal = ({ setIsVisible, isVisible }) => {
  const getLinkedinActivity = useGetLinkedinActivity();
  const { runTour, currentStepIndex } = useGuidedTourStore();
  const [activityData, setActivityData] = useState([]);
  const { setActivityCount } = useSettingStore();
  const [isSampleDataVisble, setIsSampleDataVisble] = useState(false);
  const getActivity = async () => {
    if (runTour && currentStepIndex === 8) {
      return;
    }
    try {
      const response = await getLinkedinActivity.mutateAsync();
      setActivityData(response?.activity);
      setActivityCount(response?.unread_count);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // if (isVisible) {
    getActivity();
    // }
  }, [isVisible]);

  const handleOpen = () => {
    // setOpenModal({ data: "data" });
    // setIsVisible(false);
  };

  // Render images and colors based on the sequence name
  const renderSequenceImages = (sequence) => {
    let sequenceIcon;
    let sequenceColor;

    switch (sequence) {
      case "View Profile":
        sequenceIcon = (
          <img
            src={purpleSmileIcon}
            alt="View Profile"
            className="w-[10px] h-[10px] mr-[5px] mt-[0.5px]"
          />
        );
        sequenceColor = "#8E67FF";
        break;
      case "Send Connection Request":
        sequenceIcon = (
          <img
            src={connectIcon}
            alt="Send Connection Request"
            className="w-[10px] h-[10px] mr-[5px] mt-[1px]"
          />
        );
        sequenceColor = "#8582FF";
        break;
      case "Send Follow-Up Message":
        sequenceIcon = (
          <img
            src={sentMsgIcon}
            alt="Send Follow-Up Message"
            className="w-[10px] h-[10px] mr-[5px] mt-[1px]"
          />
        );
        sequenceColor = "#2FA8FF";
        break;
      case "Like Post":
        sequenceIcon = (
          <img
            src={redHeartIcon}
            alt="Like Post"
            className="w-[8px] h-[8px] mr-[5px] mt-[1.5px]"
          />
        );
        sequenceColor = "#F43F5E";
        break;
      case "Message Existing Connection":
        sequenceIcon = (
          <img
            src={connectIcon}
            alt="Message Existing Connection"
            className="w-[8px] h-[8px] mr-[5px] mt-[1px]"
          />
        );
        sequenceColor = "#2FA8FF";
        break;
      case "Like Comment":
        sequenceIcon = (
          <img
            src={redHeartIcon}
            alt="Like Comment"
            className="w-[8px] h-[8px] mr-[5px] mt-[1px]"
          />
        );
        sequenceColor = "#F43F5E";
        break;
      default:
        return null;
    }

    return { sequenceIcon, sequenceColor };
  };

  useEffect(() => {
    if (runTour) {
      let timeout = setTimeout(() => {
        setIsSampleDataVisble(true);
      }, 1500);

      return () => {
        clearTimeout(timeout);
        setIsSampleDataVisble(false);
      };
    }
  }, [runTour]);

  return (
    <>
      {isVisible && (
        <div
          className="fixed z-10 h-full w-full top-0 left-0"
          onClick={() => setIsVisible(false)}
        />
      )}
      <div
        style={{
          display:
            currentStepIndex === 8 && runTour
              ? "none !important"
              : "block !important",
        }}
        className={`modal-scroll w-[400px] fixed px-[12px] bg-white overflow-auto h-[96vh] mt-[4px] !z-50 ${
          !runTour && "transition-all duration-500 ease-in-out"
        } ${
          isVisible || (runTour && [7, 8]?.includes(currentStepIndex))
            ? "right-0"
            : "right-[-405px]"
        } border border-[#E4E7EC] font-inter pb-[20px]`}
      >
        <div className="bg-white sticky top-0 mb-[11px] z-10">
          <div className="mr-[6px] py-[12px] ml-[8px] flex justify-between border-b-[2px] border-[#E5E7EB]">
            <div className="">
              <p className="text-[16.7px] font-[600] text-[#101828] leading-[25px] m-0">
                Activity Feed
              </p>
            </div>

            <div
              className="mt-[3px]"
              onClick={() => {
                setIsVisible(false);
              }}
            >
              <img src={arrowIcon} alt="ArrowIcon" className="cursor-pointer" />
            </div>
          </div>
        </div>
        <div id="activityBar">
          {isSampleDataVisble ? (
            SampleGuidedTourActivity?.map((item, index, arr) => {
              const lastIndex = index === SampleGuidedTourActivity?.length - 1;
              const { sequenceIcon, sequenceColor } = renderSequenceImages(
                item?.sequence_name || "View Profile"
              );

              return (
                <div
                  key={index}
                  className="relative flex items-center cursor-pointer border border-[#F5F5F5] rounded-[7px] pt-[13px] pb-[16px] px-[10px] mb-[9px]"
                  onClick={handleOpen}
                >
                  <div className="mr-[9.5px]">
                    {item?.profile_pic ? (
                      <img
                        src={item?.profile_pic}
                        alt="user"
                        className="h-[31.3px] w-[31.3px] min-w-[31.3px] rounded-full"
                      />
                    ) : (
                      <div className="w-[31.3px] min-w-[31.3px] h-[31.3px] rounded-full flex items-center justify-center text-[13px] font-[700] text-[#4409B9] bg-[#EEEDFD]">
                        {getNameInititals(item?.lead_name)}
                      </div>
                    )}
                  </div>
                  <div className="">
                    <p className="font-medium text-[12.5px] text-[#374151] mb-[1px] leading-[18.6px]">
                      {item?.lead_name}{" "}
                    </p>
                    <div className="flex items-center">
                      {sequenceIcon}
                      <p
                        className="text-[10.9px] font-[500] m-0 leading-[15.6px]"
                        style={{ color: sequenceColor }}
                      >
                        {item?.sequence_name === "Send Follow-Up Message"
                          ? "Sent a Follow-Up Message"
                          : item?.sequence_name === "Send Connection Request"
                          ? "Sent Connection Request"
                          : item?.sequence_name || "Viewed Profile"}
                      </p>
                    </div>
                    {item?.socialImg && (
                      <button className="w-[137px] h-[30px] mt-[10px] shadow-sm border rounded-[6px] flex justify-evenly items-center">
                        <img
                          src={item?.socialImg}
                          alt="social"
                          className="h-[13px] w-[13px]"
                        />
                        <p className="text-[#494949] text-[10px] font-semibold leading-[13px] ">
                          {item?.view}
                        </p>
                      </button>
                    )}
                  </div>
                  <p className="text-[#9CA3AF] text-[9.5px] font-[400] absolute right-[10px] bottom-[6px]">
                    {/* {userData?.company?.localTimezone
                      ? moment
                          .utc(item?.created_ts)
                          .tz(userData?.company?.localTimezone)
                          .fromNow()
                      : moment(item?.created_ts).fromNow()} */}
                    {moment(item?.created_ts).fromNow()}
                  </p>
                </div>
              );
            })
          ) : (
            <>
              {getLinkedinActivity?.isSuccess && activityData?.length ? (
                activityData?.map((item, index) => {
                  const lastIndex = index === activityData?.length - 1;
                  const { sequenceIcon, sequenceColor } = renderSequenceImages(
                    item?.sequence_name || "View Profile"
                  );
                  return (
                    <div
                      key={index}
                      className="relative flex items-center cursor-pointer border border-[#F5F5F5] rounded-[7px] pt-[13px] pb-[16px] px-[10px] mb-[9px]"
                      onClick={handleOpen}
                    >
                      <div className="mr-[9.5px]">
                        {item?.profile_pic ? (
                          <img
                            src={item?.profile_pic}
                            alt="user"
                            className="h-[31.3px] w-[31.3px] min-w-[31.3px] rounded-full"
                          />
                        ) : (
                          <div className="w-[31.3px] min-w-[31.3px] h-[31.3px] rounded-full flex items-center justify-center text-[13px] font-[700] text-[#4409B9] bg-[#EEEDFD]">
                            {getNameInititals(item?.lead_name)}
                          </div>
                        )}
                      </div>
                      <div className="">
                        <p className="font-medium text-[12.5px] text-[#374151] mb-[1px] leading-[18.6px]">
                          {item?.lead_name}{" "}
                        </p>
                        <div className="flex items-center">
                          {sequenceIcon}
                          <p
                            className="text-[10.9px] font-[500] m-0 leading-[15.6px]"
                            style={{ color: sequenceColor }}
                          >
                            {item?.sequence_name === "Send Follow-Up Message"
                              ? "Sent a Follow-Up Message"
                              : item?.sequence_name ===
                                "Send Connection Request"
                              ? "Sent Connection Request"
                              : item?.sequence_name || "Viewed Profile"}
                          </p>
                        </div>
                        {item?.socialImg && (
                          <button className="w-[137px] h-[30px] mt-[10px] shadow-sm border rounded-[6px] flex justify-evenly items-center">
                            <img
                              src={item?.socialImg}
                              alt="social"
                              className="h-[13px] w-[13px]"
                            />
                            <p className="text-[#494949] text-[10px] font-semibold leading-[13px] ">
                              {item?.view}
                            </p>
                          </button>
                        )}
                      </div>
                      <p className="text-[#9CA3AF] text-[9.5px] font-[400] absolute right-[10px] bottom-[6px]">
                        {/* {userData?.company?.localTimezone
                      ? moment
                          .utc(item?.created_ts)
                          .tz(userData?.company?.localTimezone)
                          .fromNow()
                      : moment(item?.created_ts).fromNow()} */}
                        {moment(item?.created_ts).fromNow()}
                      </p>
                    </div>
                  );
                })
              ) : getLinkedinActivity?.isPending ? (
                <div className="flex items-center justify-center h-[calc(100vh_-150px)] w-full">
                  <Loader />
                </div>
              ) : (
                <div className="flex justify-center h-[calc(100vh_-150px)] w-full">
                  <p className="text-[13px] text-[#475467] text-center px-8 mt-[150px]">
                    You'll see the latest campaign activity here after launching
                    your first campaign!
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ActivityFeedModal;
