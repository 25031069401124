import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useProspectSearchQ } from "services/prospecting-api";
import Loader from "components/loader";
import { useProspectStore } from "stores";
import { getNameInititals, getRandomCompanyLogo } from "utils/index";
import { debounce } from "utils/index";
import searchIcon from "assets/images/search-lg.svg";
import commandIcon from "assets/images/command-text.svg";
import userCheckIcon from "assets/images/user-check.svg";
import fileHeartIcon from "assets/images/file-heart.svg";
import starsIcon from "assets/images/stars-03.svg";

const HeaderSearchModal = ({ isOpen, onClose, setTab }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setSearchPropspect } = useProspectStore();
  const prospectSearch = useProspectSearchQ();

  const [searchValue, setSearchValue] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [loading, setLoading] = useState(false);

  const searchInputRef = useRef(null);

  const debouncedSearchAPI = useRef(
    debounce(async (text) => {
      if (!text?.trim()) {
        setSearchData([]);
        setLoading(false);
        return;
      }

      setLoading(true);
      try {
        const response = await prospectSearch.mutateAsync({
          q_lead_fuzzy_name: text.trim(),
        });
        setSearchData(response || []);
      } catch (error) {
        console.error("Search error:", error);
        setSearchData([]);
      } finally {
        setLoading(false);
      }
    }, 300)
  ).current;

  useEffect(() => {
    if (isOpen) {
      setSearchValue("");
      setSearchData([]);
      setLoading(false);
      if (searchInputRef.current) {
        searchInputRef.current.focus();
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (searchValue?.length > 1) {
      debouncedSearchAPI(searchValue);
    } else {
      setSearchData([]);
    }
  }, [searchValue, debouncedSearchAPI]);

  const onInputChange = (event) => {
    const value = event.target.value;
    setSearchValue(value);
  };

  const handleNavigation = (leadData) => {
    if (!leadData?.prospect_id) return;
    setSearchPropspect(leadData);
    navigate(`/prospecting/${leadData.prospect_id}`);
    handleClose();
  };

  const handleClose = () => {
    setSearchValue("");
    setSearchData([]);
    setLoading(false);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-[9999] font-inter">
      <div
        className="fixed inset-0 bg-black opacity-10"
        onClick={handleClose}
      />
      <div
        className={`bg-white rounded-[12px] shadow-lg z-10 w-full max-w-[640px] relative font-inter ${
          searchValue.length ? "" : "-mt-60"
        }`}
      >
        <div className="modal-scroll w-full">
          <div className="relative flex items-center justify-start w-full">
            <img
              src={searchIcon}
              className="h-[20px] w-[20px] left-[12px] absolute"
              alt="Search"
            />
            <input
              ref={searchInputRef}
              name="search"
              type="text"
              placeholder="Search"
              className="w-full h-[56px] py-[18px] pl-[40px] pr-[50px] px-[16px] font-[400] rounded-[8px] text-[#667085] bg-[#fff] text-[16px] !outline-none !border-none"
              value={searchValue}
              onChange={onInputChange}
            />
            <div className="right-[12px] absolute flex items-center justify-center border border-[#E4E7EC] bg-[#F9FAFB] py-0.5 px-1 rounded-sm">
              <img
                src={commandIcon}
                className="h-[20px] w-[20px]"
                alt="command"
              />
            </div>
          </div>
          {searchValue && (
            <>
              <div className="border-y border-[#E4E7EC] py-4 px-2">
                <h1 className="text-sm font-medium text-[#475467] ml-2">
                  Menu
                </h1>
                <div className="mt-[14px]">
                  <button
                    onClick={() => {
                      if (location.pathname === "/findLeads") {
                        handleClose();
                      } else {
                        navigate("/findLeads");
                        handleClose();
                      }
                    }}
                    className="bg-[#F9FAFB] h-[60px] flex w-full py-2.5 pr-2.5 rounded-[8px] pl-[14px] cursor-pointer"
                  >
                    <div className="h-10 w-10 shadow-sm rounded-[8px] flex items-center justify-center border border-[#E4E7EC] bg-white">
                      <img
                        src={userCheckIcon}
                        className="h-[21px] w-[21px] ml-1"
                        alt="User check"
                      />
                    </div>
                    <div className="ml-2">
                      <h1 className="text-sm text-start font-medium text-[#101828]">
                        Find Leads
                      </h1>
                      <p className="text-sm text-[#475467]">
                        Prospect new leads with Pipeline's filters.
                      </p>
                    </div>
                  </button>

                  <button
                    onClick={() => {
                      if (location.pathname === "/prospecting") {
                        navigate("/prospecting");
                        handleClose();
                        setTab("My Lists");
                      } else {
                        navigate("/prospecting", { state: "search" });
                        handleClose();
                      }
                    }}
                    className="bg-[#FFF] h-[60px] flex w-full py-2.5 pr-2.5 rounded-[8px] pl-[14px] cursor-pointer mt-2"
                  >
                    <div className="h-10 w-10 shadow-sm rounded-[8px] flex items-center justify-center border border-[#E4E7EC] bg-white">
                      <img
                        src={fileHeartIcon}
                        className="h-[21px] w-[21px] ml-1"
                        alt="File heart"
                      />
                    </div>
                    <div className="ml-2">
                      <h1 className="text-sm text-start font-medium text-[#101828]">
                        My Lead Lists
                      </h1>
                      <p className="text-sm text-[#475467]">
                        View all of your prospected, imported, and shared lead
                        lists.
                      </p>
                    </div>
                  </button>

                  <button
                    onClick={() => {
                      if (location.pathname === "/campaigns") {
                        handleClose();
                      } else {
                        navigate("/campaigns");
                        handleClose();
                      }
                    }}
                    className="bg-[#F9FAFB] h-[60px] flex w-full py-2.5 pr-2.5 rounded-[8px] pl-[14px] cursor-pointer mt-2"
                  >
                    <div className="h-10 w-10 shadow-sm rounded-[8px] flex items-center justify-center border border-[#E4E7EC] bg-white">
                      <img
                        src={starsIcon}
                        className="h-[21px] w-[21px] ml-1"
                        alt="Stars"
                      />
                    </div>
                    <div className="ml-2">
                      <h1 className="text-sm text-start font-medium text-[#101828]">
                        My Campaigns
                      </h1>
                      <p className="text-sm text-[#475467]">
                        View all of your active and paused campaigns.
                      </p>
                    </div>
                  </button>
                </div>
              </div>
              <div className="pt-2">
                <h1 className="text-sm font-medium text-[#475467] ml-4">
                  Recently Viewed Leads
                </h1>
                <div className="mt-0.5 max-h-[230px] innerScrollparent modal-scroll overflow-y-auto pb-2">
                  {loading ? (
                    <div className="w-full flex items-center justify-center h-[150px]">
                      <Loader />
                    </div>
                  ) : searchData.length > 0 ? (
                    searchData.map((item) => (
                      <div
                        key={item.id}
                        onClick={() => handleNavigation(item)}
                        className="flex px-2 pt-[5px] mx-2 my-2 rounded-[8px] hover:bg-gray-50 h-[70px] cursor-pointer"
                      >
                        <div className="relative mt-1">
                          {!item?.profile_pic ? (
                            <div className="w-[40px] min-w-[40px] h-[40px] rounded-full flex items-center justify-center text-[14px] font-[700] text-[#4409B9] bg-[#EEEDFD]">
                              {getNameInititals(item?.full_name)}
                            </div>
                          ) : (
                            <img
                              onError={(e) => {
                                e.target.src = getRandomCompanyLogo().src;
                              }}
                              src={item?.profile_pic}
                              alt="user images"
                              className="h-[40px] w-[40px] min-w-[40px] rounded-full"
                            />
                          )}
                        </div>
                        <div className="flex flex-col items-start ml-1.5 mt-0.5">
                          <p className="text-[#101828] break-words text-[14px] leading-5 font-inter font-medium">
                            {item?.full_name}
                          </p>
                          <p className="text-[#475467] break-words text-[14px] leading-5 font-inter pt-[1px]">
                            {item?.title}
                          </p>
                          <p className="text-[#475467] break-words text-[12px] italic leading-5 font-inter -mt-0.5">
                            Appears in {item?.prospect_name}
                          </p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="w-full flex items-center justify-center h-[150px]">
                      <p>No results found</p>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeaderSearchModal;
