import GuidedTourBanner from "assets/images/GuidedTourContent.svg";
import Button from "components/button";

const GuidedTour = (props) => {
  const { isOpen, onClose, setRunTour, handleUpdateTour } = props;

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 font-inter">
      <div
        className="fixed inset-0 bg-black opacity-10"
        onClick={() => {
          onClose();
          handleUpdateTour();
        }}
      ></div>
      <div className="bg-[#fff] border-[1px] border-[#7575758F] p-[26px] rounded-[12px] shadow-lg z-10 w-full max-w-[447px] relative">
        <div className="flex flex-col items-center">
          <div className="w-full h-[230px] overflow-hidden px-2 mb-[24px]">
            <img src={GuidedTourBanner} alt="logo" />
          </div>
          <div className="modal-scroll">
            <h4 className="font-[600] text-[20.12px] text-[#181D27] leading-[34.35px] mb-[17px] font-inter text-center">
              👋 Welcome to Pipeline!
            </h4>
            <p className="font-[400] text-[15.65px] text-[#535862] font-inter text-center  mb-0">
              We’re glad to have you onboard. Take a quick tour to discover key
              features and tips to get started.{" "}
            </p>
            {/* links */}
            <div className="mt-[40px] mb-[36px] w-[276px]"></div>
            <div className="flex items-center justify-center mt-[32px]">
              <Button
                title="Skip"
                isWhiteBtn={true}
                isRounded="6px"
                onClick={() => {
                  onClose();
                  handleUpdateTour();
                }}
                className="!text-[16px] !h-[44px] !font-[600] !text-[#344054] y-axis-shadow !rounded-[8px]"
              />
              <Button
                title="Take a Tour"
                secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9] ml-[12px]"
                className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow !rounded-[8px]"
                isRounded="6px"
                onClick={() => {
                  onClose();
                  setRunTour(true);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuidedTour;
