import { useMutation } from "@tanstack/react-query";
import axiosInt from "services";

const useRb2bConfig = () =>
  useMutation({
    mutationFn: async () => {
      const response = await axiosInt?.get(`/rb2b/config`);
      return response;
    },
  });

const useRb2bInstall = () =>
  useMutation({
    mutationFn: async () => {
      const response = await axiosInt?.post(`/rb2b/install`);
      return response;
    },
  });

const useRb2bEnrichLeads = () =>
  useMutation({
    mutationFn: async () => {
      const response = await axiosInt?.post(
        `/prospect/import/rb2b_enrich_leads`,
        {}
      );
      return response;
    },
  });

const useRb2bUnInstall = () =>
  useMutation({
    mutationFn: async () => {
      const response = await axiosInt?.delete(`/rb2b/uninstall`);
      return response;
    },
  });

export { useRb2bConfig, useRb2bInstall, useRb2bEnrichLeads, useRb2bUnInstall };
