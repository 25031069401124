import { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { randomCompanyLogo } from "constant";
import { useAccountStore, useProspectStore } from "stores";
import { getNameInititals } from "utils/index";
import arrowDown from "assets/images/arrow-down.svg";
import verified from "assets/images/verified.svg";
import linkedInImg from "assets/images/colored-linkedin.svg";
import Avatar from "assets/images/manFace.jpg";

const SearchResultTable = ({
  onRowClick,
  selectedRow,
  data,
  setSortOrder,
  setSortBy,
  sortOrder,
  sortBy,
  setPaginationData,
  totalItems,
  onRefetch,
  hasMore,
}) => {
  const tableRef = useRef();
  const { prospect: addActiveCampaign } = useProspectStore();
  const { linkedinAccountDetails } = useAccountStore();
  const [companyLogoMap, setCompanyLogoMap] = useState({});

  const tableHeader =
    addActiveCampaign === "founders-in-sf"
      ? [
          { title: "lead", icon: "arrow", key: "full_name" },
          { title: "company", icon: "arrow", key: "company" },
          { title: "location", icon: "arrow", key: "location" },
          { title: "status", icon: "arrow", key: "status" },
        ]
      : [
          { title: "lead", icon: "arrow", key: "full_name" },
          { title: "company", icon: "arrow", key: "company" },
          { title: "location", icon: "arrow", key: "location" },
        ];

  const sortTableData = (column, order) => {
    if (!column) return;
  };

  const handleSort = (columnKey) => {
    const isAsc = sortBy === columnKey && sortOrder === "asc";
    setSortBy(columnKey);
    setSortOrder(isAsc ? "desc" : "asc");
    setPaginationData({ limit: 20, page: 1 });
  };

  const getCompanyLogo = (companyName) => {
    if (companyLogoMap[companyName]) {
      return companyLogoMap[companyName];
    }
    const randomLogo =
      randomCompanyLogo[Math.floor(Math.random() * randomCompanyLogo.length)];
    setCompanyLogoMap((prevMap) => ({
      ...prevMap,
      [companyName]: randomLogo.src,
    }));

    return randomLogo.src;
  };

  const fetchData = () => {
    setTimeout(() => {
      onRefetch();
    }, 500);
  };

  useEffect(() => {
    sortTableData("lastname", sortOrder);
  }, [sortOrder]);

  useEffect(() => {
    if (tableRef?.current) {
      tableRef.current.scrollTop = 0;
    }
  }, [sortBy, sortOrder]);

  return (
    <div className="font-inter border border-[#E4E7EC] rounded-[11.3px]">
      <div
        className={` ${
          linkedinAccountDetails?.reconnect_status
            ? "max-h-[calc(100vh_-235px)]"
            : "max-h-[calc(100vh_-170px)]"
        } ${data.length > 9 && "overflow-auto"} round-table-scroll`}
        id="scrollableDiv"
        ref={tableRef}
      >
        <InfiniteScroll
          dataLength={data.length || 0}
          next={fetchData}
          hasMore={data?.length < totalItems && hasMore}
          loader={
            totalItems !== data.length && (
              <div className="h-[50px] w-full flex items-center justify-center">
                <div className="w-[20px] h-[20px] border-[3px] border-t-[3px] border-[#7F56D9] border-solid rounded-full animate-spin border-t-[#fff]" />
              </div>
            )
          }
          scrollableTarget="scrollableDiv"
          style={{ height: "100%", overflow: "unset" }}
        >
          <table className="min-w-full h-unset common-table-shadow rounded-xl">
            <thead className="bg-white sticky top-0 !z-[100] overflow-hidden rounded-[12px]">
              <tr className="w-full">
                {tableHeader.map((header, index) => {
                  const isLastIndex = index === tableHeader.length - 1;
                  return (
                    <th
                      key={index}
                      className={`text-[12px] font-[500] py-[12px] px-[22px] text-[#475467] capitalize text-left font-inter ${
                        isLastIndex ? "w-[200px] rounded-tr-[12px]" : ""
                      }
                  ${index === 0 && "rounded-tl-[12px]"}
                  ${header.title === "status" && "w-[140px]"}
                  `}
                      onClick={() => header.key && handleSort(header.key)}
                    >
                      <div className="flex items-center">
                        <span>{header.title}</span>
                        {header.icon === "arrow" && (
                          <img
                            src={arrowDown}
                            alt="arrow"
                            className={`ml-[4px] w-[10px] h-[10px] ${
                              sortBy === header.key && sortOrder === "desc"
                                ? "rotate-180"
                                : ""
                            }`}
                          />
                        )}
                      </div>
                    </th>
                  );
                })}
              </tr>
              <span className="w-full border-b border-[#E4E7EC] h-[1px] absolute top-full z-10 left-0" />
            </thead>
            <tbody>
              {data.length ? (
                data?.map((item, rowIndex) => {
                  const isTdLastIndex = rowIndex === data.length - 1;
                  const logo = getCompanyLogo(item.company);
                  return (
                    <tr
                      className={`group border-b hover:bg-gray-50 ${
                        isTdLastIndex
                          ? "border-transparent"
                          : "border-[#E4E7EC]"
                      } cursor-pointer`}
                      key={rowIndex}
                      onClick={() => {
                        if (item?.sample_data) {
                          return;
                        }
                        onRowClick(item);
                        selectedRow(item, logo);
                      }}
                    >
                      <td
                        className={`${
                          isTdLastIndex && "first:rounded-bl-[12px]"
                        } text-[14px] px-[22px] py-[10px] text-[#101828] text-left  w-[400px] max-[1300px]:w-[unset]`}
                      >
                        <div className="flex items-center">
                          <div className="mx-auto flex items-center">
                            {item?.linkedin_profile_url ? (
                              <>
                                {[
                                  {
                                    src: linkedInImg,
                                    show: item?.linkedin_profile_url,
                                  },
                                  // { src: twitterImg, show: item?.company_twitter },
                                ]
                                  .filter((image) => image.show) // Filter out images that should not be shown
                                  .map((image, index) => (
                                    <div
                                      className="relative inline-block"
                                      key={index}
                                      onClick={(e) => {
                                        e?.stopPropagation();
                                        window?.open(image?.show);
                                      }}
                                    >
                                      <img
                                        src={image.src}
                                        alt="user images"
                                        className={`mr-[5px] w-[20px] h-[20px] cursor-pointer ${
                                          index === 1 ? "w-[22px] h-[22px]" : ""
                                        }`}
                                      />
                                    </div>
                                  ))}
                              </>
                            ) : (
                              <div className="w-[20px] h-[20px]" />
                            )}
                          </div>
                          <div className="w-[40px] min-w-[40px] h-[40px] mr-[12px] relative ml-1">
                            {item?.profile_pic ? (
                              <img
                                src={item?.profile_pic || Avatar}
                                alt="lead"
                                className="w-full h-full rounded-[25px] object-cover object-top"
                              />
                            ) : (
                              <div className="w-[40px] min-w-[40px] h-[40px] rounded-full flex items-center justify-center text-[14px] font-[700] text-[#4409B9] bg-[#EEEDFD]">
                                {getNameInititals(item?.full_name)}
                              </div>
                            )}
                            <img
                              src={verified}
                              alt="verified"
                              className="absolute bottom-0 right-0 w-[14px] h-[14px]"
                            />
                          </div>
                          <div className="flex-1">
                            <p className="font-[500] [text-[#101828] text-[14px] m-0 leading-[20px] ">
                              {item?.full_name}
                            </p>
                            <p className="font-[400] text-[#475467] text-[14px] m-0 leading-[17px] ">
                              {item?.title}
                            </p>
                          </div>
                          <div>
                            <p className="font-[500] text-[#101828] text-[14px] m-0 leading-[17px] ">
                              {item?.name}
                            </p>
                            <p className="font-[400] text-[#475467] text-[14px] m-0 leading-[17px] ">
                              {item?.jobTitle}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="text-[13px] font-normal px-[22px] py-[10px] text-[#252C32] text-left  w-[400px] max-[1300px]:w-[unset]">
                        <div className="flex items-center justify-between">
                          <div className="flex items-center w-[70%]">
                            <div className="w-[40px] h-[40px] mr-[12px]">
                              <img
                                src={item?.company_logo || logo}
                                alt="lead"
                                className="w-full h-full"
                              />
                            </div>
                            <div className="flex-1">
                              <p className="font-[500] text-[#101828] text-[14px] m-0 leading-[20px] ">
                                {item?.company}
                              </p>
                              <p className="font-[400] text-[#475467] text-[14px] m-0 leading-[17px] ">
                                {item?.company_domain}
                              </p>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td
                        className={`px-[22px] py-[10px] text-left  w-[250px] max-[1300px]:w-[unset] text-[#475467] font-normal text-[14px] ${
                          isTdLastIndex && "last:rounded-br-[12px]"
                        }`}
                      >
                        <div className="flex items-center">
                          {/* {item?.location && (
                            <img
                              src={markerImg}
                              alt="locationIcon"
                              className="w-[24px] h-[24px] mr-[12px]"
                            />
                          )} */}
                          {item?.location}
                        </div>
                      </td>
                      {addActiveCampaign === "founders-in-sf" && (
                        <td
                          className={`px-[22px] py-[10px]  w-[100px] max-w-[140px] max-[1300px]:w-[unset] ${
                            isTdLastIndex && "last:rounded-br-[12px]"
                          } `}
                          align="left"
                        >
                          <p className="max-w-[115px] border-[#D0D5DD] border-[1px] rounded-[6px] flex items-center capitalize  font-[500] py-[2px] px-[5px] text-[11.3px] leading-[17px] text-[#344054]">
                            <span className="w-[6px] h-[6px] mr-[5px] rounded-full bg-[#17B26A]" />
                            Campaign Active
                          </p>
                        </td>
                      )}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    className="text-[14px] px-[22px] py-[10px] text-center text-[#474563] h-[200px]"
                    colSpan={4}
                  >
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default SearchResultTable;
