import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axiosInt from "services";

const useAmpersandInstall = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt?.post(
        `/hubspot/crm_routing_install`,
        data
      );
      return response;
    },
  });

const useLifeCycleStageDropdownData = () =>
  useQuery({
    queryKey: ["lifecycle_stages_dropdown"],
    queryFn: async () => {
      const response = await axiosInt?.get(`/hubspot/lifecycle_stages`);
      return response;
    },
  });

const useLifeCycleStagesBlockList = () =>
  useQuery({
    queryKey: ["lifecycle_stages_blocklist"],
    queryFn: async () => {
      const response = await axiosInt?.get(
        `/hubspot/get_lifecycle_stages_blocklist`
      );
      return response;
    },
  });

const useSyncLifecycleStagesToBlockList = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (body) => {
      const response = await axiosInt?.post(
        `/hubspot/sync_lifecycle_stages_blocklist`,
        body
      );
      return response;
    },
    onSuccess: () => {
      queryClient?.invalidateQueries({
        queryKey: ["lifecycle_stages_blocklist"],
      });
    },
  });
};
const useHubspotCollaborators = () =>
  useQuery({
    queryKey: ["hubspot_company_collaborators"],
    queryFn: async () => {
      const response = await axiosInt.get(`/hubspot/company/collaborators`);
      return response;
    },
  });

const useHubspotUsers = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt?.post(`/hubspot/map_hubspot_users`, data);
      return response;
    },
    onSuccess: () => {
      queryClient?.invalidateQueries({
        queryKey: ["hubspot_user_mapping"],
      });
    },
  });
};

const useHubspotOwners = () =>
  useQuery({
    queryKey: ["hubspot_owners"],
    queryFn: async () => {
      const response = await axiosInt.get(`/hubspot/owners`);
      return response;
    },
  });

const useListExistinContactPreferences = () =>
  useQuery({
    queryKey: [`user_contact_preferences`],
    queryFn: async () => {
      const response = await axiosInt.get(`/hubspot/owners`);
      return response;
    },
  });

const useLinkedinStatus = () =>
  useQuery({
    queryKey: ["linkedin_status"],
    queryFn: async () => {
      const response = await axiosInt.get(`/hubspot/get_outreach_status`);
      return response;
    },
  });

const useLeadStatus = () =>
  useQuery({
    queryKey: ["lead_status"],
    queryFn: async () => {
      const response = await axiosInt.get(`/hubspot/lead_statuses`);
      return response;
    },
  });

const useGetHubspotUsers = () =>
  useQuery({
    queryKey: ["hubspot_user_mapping"],
    queryFn: async () => {
      const response = await axiosInt.get(`/hubspot/get_hubspot_user_mapping`);
      return response;
    },
  });

const useContactPreference = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt?.post(
        `/hubspot/set_user_contact_preferences`,
        data
      );
      return response;
    },
  });

const useGetContactPrefrences = () =>
  useQuery({
    queryKey: ["user_contact_preferences"],
    queryFn: async () => {
      const response = await axiosInt?.get(
        `/hubspot/get_user_contact_preferences`
      );
      return response;
    },
  });

const useGetContactProperties = () =>
  useQuery({
    queryKey: ["user_contact_properties"],
    queryFn: async () => {
      const response = await axiosInt?.get(`/hubspot/used_contact_properties`);
      return response;
    },
  });

const useGetContactFields = () =>
  useQuery({
    queryKey: ["user_contact_field"],
    queryFn: async () => {
      const response = await axiosInt?.get(
        `/hubspot/get_contact_field_mappings`
      );
      return response;
    },
  });

const useUpdateContactFieldsMappings = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt?.post(
        `/hubspot/update_contact_field_mappings`,
        data
      );
      return response;
    },
    onSuccess: () => {
      queryClient?.invalidateQueries({
        queryKey: ["user_contact_field"],
      });
    },
  });
};

const useDisconnectHubspot = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt?.put(`hubspot/disconnect_hubspot`, data);
      return response;
    },
  });

export {
  useAmpersandInstall,
  useLifeCycleStageDropdownData,
  useLifeCycleStagesBlockList,
  useSyncLifecycleStagesToBlockList,
  useHubspotCollaborators,
  useHubspotUsers,
  useHubspotOwners,
  useListExistinContactPreferences,
  useLinkedinStatus,
  useLeadStatus,
  useContactPreference,
  useGetHubspotUsers,
  useGetContactPrefrences,
  useGetContactProperties,
  useGetContactFields,
  useUpdateContactFieldsMappings,
  useDisconnectHubspot,
};
