import AddLeadsOptions from "components/addLeadsOptions";
import Layout from "components/layout";
import {
  INITIAL_FIND_LEADS_FORM,
  leadsRightProfile,
  peopleFilters,
} from "constant";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BreadcrumbHeader from "components/breadcrumbHeader";
import AnimatedButton from "components/animatedButton";
import stars from "assets/images/stars2.svg";
import { useProspectSearch } from "services/prospecting-api";
import { toast } from "react-toastify";
import { getDataPrefill, isFormFilled } from "utils/index";
import { useAuthStore } from "stores";
import ReconnectBanner from "components/reconnectBanner";

const FindLeads = () => {
  const { state } = useLocation();
  const { companyId } = useAuthStore();
  const prospectingFilterParent = useRef(null);
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [isLoader, setIsLoading] = useState(false);
  const findLeadsSearch = useProspectSearch();
  const [isValidForm, setIsValidForm] = useState(true);
  const [isEmptyForm, setIsEmptyForm] = useState(true);

  const [formData, setFormData] = useState(
    state?.prospectData?.filters
      ? getDataPrefill(state?.prospectData)
      : INITIAL_FIND_LEADS_FORM
  );
  useEffect(() => {
    if (
      Number(formData?.year_founded_end?.value) <=
        Number(formData?.year_founded_start?.value) &&
      (formData?.year_founded_end?.value !== null ||
        formData?.year_founded_end?.value !== null)
    ) {
      setIsValidForm(false);
    } else {
      setIsValidForm(true);
    }
  }, [formData?.year_founded_start, formData?.year_founded_end]);

  const trimExclude = (string) => {
    return string?.startsWith("Ex:") ? string?.slice(3) : string;
  };

  const handleSearchLeads = async () => {
    const filters = {};

    // Helper function to add non-empty values to filters
    const addFilter = (key, value) => {
      if (value && (Array.isArray(value) ? value.length > 0 : true)) {
        filters[key] = value;
      }
    };

    // Add fields to filters only if they have data

    addFilter(
      "first_name",
      formData?.first_name?.map((i) => i)
    );
    addFilter(
      "last_name",
      formData?.last_name?.map((i) => i)
    );
    addFilter(
      "major",
      formData?.major?.map((i) => i)
    );
    addFilter(
      "school",
      formData?.school?.map((i) => i)
    );

    addFilter(
      "location",
      formData?.location?.map((i) => ({
        v: trimExclude(i)?.split("/")?.[0],
        b: i?.split("/")?.[1]?.toLowerCase() || "country",
        s: i?.startsWith("Ex:") ? "e" : "i",
      }))
    );

    addFilter(
      "job_title",
      formData?.job_title?.map((i) => ({
        v: trimExclude(i),
        s: i?.startsWith("Ex:") ? "e" : "i",
      }))
    );

    addFilter(
      "job_company",
      formData?.job_company?.map((i) => ({
        v: trimExclude(i),
        s: i?.startsWith("Ex:") ? "e" : "i",
      }))
    );

    addFilter(
      "company_location",
      formData?.company_location?.map((i) => ({
        v: trimExclude(i)?.split("/")?.[0],
        b: i?.split("/")?.[1]?.toLowerCase() || "country",
        s: i?.startsWith("Ex:") ? "e" : "i",
      }))
    );

    addFilter("year_founded_start", formData?.year_founded_start?.label);
    addFilter("year_founded_end", formData?.year_founded_end?.label);
    addFilter(
      "job_title_level",
      formData?.job_title_level?.values?.map((i) => i?.label)
    );
    addFilter(
      "job_role",
      formData?.job_role?.values?.map((i) => i?.value)
    );
    addFilter(
      "job_sub_role",
      formData?.job_sub_role?.values?.map((i) => i?.value)
    );
    addFilter(
      "skill",
      formData?.skill?.map((i) => i)
    );
    addFilter(
      "company_size",
      formData?.company_size?.values?.map((i) => i?.label)
    );
    addFilter(
      "company_industry",
      formData?.company_industry?.map((i) => i)
    );

    const prospectData = {
      company_id: companyId,
      filters,
    };

    if (isValidForm) {
      setIsLoading(true);
      try {
        const response = await findLeadsSearch.mutateAsync(prospectData);
        setIsLoading(false);
        console.log({ response });
        navigate("/results", {
          state: {
            leadCount: response?.total,
            prospectData,
            profiles: response?.profiles,
          },
        });
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching leads:", error); // Log the error for debugging
        toast.error(
          error?.data?.detail ||
            "Failed to fetch leads. Please try again later."
        ); // Provide user feedback
      }
    } else {
      if (
        formData?.year_founded_end?.value ===
        formData?.year_founded_start?.value
      ) {
        toast.error("Min and Max year founded should not be the same."); // Use error message for validation
      } else {
        toast.error(
          "Max year founded should be greater than Min year founded."
        ); // Use error message for validation
      }
    }
  };

  useEffect(() => {
    if (isFormFilled(formData)) {
      setIsEmptyForm(false);
    } else {
      setIsEmptyForm(true);
    }
  }, [formData, setIsEmptyForm]);

  console.log({ isEmptyForm });

  return (
    <Layout>
      <div className="h-[100vh] flex flex-col justify-start items-start">
        <BreadcrumbHeader
          breadcrumbItems={[
            { title: "Prospecting", route: "/prospecting" },
            { title: "New Lead List" },
          ]}
        />
        <ReconnectBanner />
        <div
          className="max-w-[100%] w-full px-[22px] py-[22px] overflow-auto h-[calc(100vh_-45px)] flex flex-col justify-between innerScrollBar"
          data-aos="fade-top"
        >
          {isLoader ? (
            <div className="w-full h-full flex flex-col justify-center items-center m-auto">
              <div className="bg-[#7927FF] py-[8px] px-[12px] flex items-center text-[16px] text-white rounded-[7px] font-inter font-[600]">
                {/* spinner */}
                <div className="flex items-center justify-center">
                  <div className="w-[15px] h-[15px] border-4 border-t-4 border-[#9859FF] border-solid rounded-full animate-spin border-t-[#09BF10] mr-[8px]" />
                </div>
                Finding Leads...
              </div>{" "}
            </div>
          ) : (
            <div
              className="mx-auto w-[95%] mt-10"
              ref={prospectingFilterParent}
            >
              <div className="w-full flex flex-col items-center">
                <div className="flex items-center justify-center">
                  <div className="w-12 h-12 rounded-[10px] flex items-center shadow-sm shadow-[#1018280D] justify-center border border-[#EAECF0]">
                    <img src={stars} alt="rocket" className="w-6 h-6" />
                  </div>
                </div>
                <h1 className="text-lg font-semibold font-inter mt-4">
                  Let’s find new leads!
                </h1>
                <p className="font-inter font-normal text-[#667085] text-sm text-center mb-[30px] mt-0.5">
                  Define your ICP to create a lead list by using the filters
                  below.
                </p>
                <div className="w-[70%] flex items-start justify-between mt-[18px] box-border flex-wrap max-[1300px]:w-[90%]">
                  <div className="px-[6px] w-[48%] flex flex-col">
                    <p className="font-inter font-[500] text-[#344054] text-[14px] text-left mb-[12px]">
                      People Filters
                    </p>
                    {peopleFilters?.map((items, index) => {
                      return (
                        <div key={index} className="w-full">
                          <AddLeadsOptions
                            src={items?.src}
                            name={items?.name}
                            leadTitle={items?.label}
                            type={items?.type}
                            options={items?.options}
                            setFormData={setFormData}
                            formData={formData}
                            title={title}
                            setTitle={setTitle}
                            placeholder={items?.placeholder}
                            prospectingFilterParent={prospectingFilterParent}
                          />
                        </div>
                      );
                    })}
                  </div>

                  <div className="px-[6px] mb-[10px] w-[48%] flex flex-col">
                    <p className="font-inter font-[500] text-[#344054] text-[14px] text-left mb-[12px]">
                      Company Filters
                    </p>
                    {leadsRightProfile?.map((items, index) => {
                      return (
                        <div key={index} className="w-full">
                          <AddLeadsOptions
                            src={items?.src}
                            leadTitle={items?.label}
                            type={items?.type}
                            setFormData={setFormData}
                            formData={formData}
                            name={items?.name}
                            options={items?.options}
                            title={title}
                            setTitle={setTitle}
                            placeholder={items?.placeholder}
                            prospectingFilterParent={prospectingFilterParent}
                            setIsValidForm={setIsValidForm}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {!isLoader && (
        <div className="fixed bottom-[15px] right-[80px] z-40">
          <AnimatedButton
            title="Search"
            className="!text-[18px] !w-[116px] !bg-[#7927FF] !border-[#7927FF] !rounded-[8px] !h-[48px]"
            fontSize="!font-inter !text-[18px] !font-[600]"
            onClick={handleSearchLeads}
            disabled={isEmptyForm}
            disabledAnimation={isEmptyForm}
          />
        </div>
      )}
    </Layout>
  );
};

export default FindLeads;
