import { useMutation } from "@tanstack/react-query";
import axiosInt from "services";

const useGetLinkedinActivity = () =>
  useMutation({
    mutationFn: async () => {
      const response = await axiosInt.get(
        `/dashboard/outreach/activity?page=1&limit=100`
      );
      return response;
    },
  });

const useGetDashboardActivity = () =>
  useMutation({
    mutationFn: async ({ timeFrame }) => {
      const response = await axiosInt.get(
        `/dashboard/get_analytics?date_range=${timeFrame}`
      );
      return response;
    },
  });

const useUpdateGuidedTour = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.put(
        `/dashboard/update/guided_tour`,
        data
      );
      return response;
    },
  });

export { useGetLinkedinActivity, useGetDashboardActivity, useUpdateGuidedTour };
