import { IoClose } from "react-icons/io5";
import Button from "components/button";
import upgradeIcon from "assets/images/upgrade-team.svg";
import collaborater1 from "assets/images/callobartor1.svg";
import Logo from "assets/images/logo.svg";
import { useEffect, useState } from "react";

const UpgradeTeamMemberModal = (props) => {
  const {
    isOpen,
    onClose,
    currentClickedUser,
    swapToAdmin,
    handleSwapToAdminRole,
  } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [isOpen]);

  if (!isOpen && !isModalVisible) return null;

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[300ms] ease-in-out ${
        isModalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className="fixed inset-0 bg-black opacity-10"
        onClick={onClose}
      ></div>
      <div
        className={`bg-white p-[24px] rounded-lg modal-shadow z-10 w-full max-w-[400px] relative transition-all ease-in-out duration-[150ms] ${
          isModalVisible ? "scale-100" : "scale-95"
        }`}
      >
        <IoClose
          className="text-[#667085] text-[20px] w-[24px] h-[24px] absolute right-[24px] top-[26px] cursor-pointer"
          onClick={onClose}
        />
        <div className="modal-scroll">
          <div className="modal-scroll">
            <div className="flex flex-col items-start justify-start font-inter">
              <div className="flex items-center justify-center mb-[16px]">
                <img
                  src={upgradeIcon}
                  alt="upgradeIcon"
                  className="h-[48px] w-[48px]"
                />
              </div>
              <h4 className="font-[600] text-[18px] text-[#101828] mb-[11px] font-inter">
                Upgrade Team Member to Admin
              </h4>
              <p className="font-normal text-[14px] text-[#475467] font-inter">
                Are you sure you want to upgrade this teammate to become the
                admin of the workspace.
              </p>
              <div className="flex items-center px-[24px] pt-[22px] pb-[20px]">
                <img
                  src={
                    currentClickedUser?.userImg ||
                    currentClickedUser?.invite_status === "Pending"
                      ? Logo
                      : collaborater1
                  }
                  alt="lead"
                  className="w-[40px] min-w-[40px] h-[40px] mr-[12px]"
                />
                <div>
                  <p className="font-[600] text-[#344054] text-[14px] m-0 leading-[17px]">
                    {currentClickedUser?.name || currentClickedUser?.userName}
                  </p>
                  <p className="font-[400] text-[#475467] text-[14px] m-0 leading-[17px]">
                    {currentClickedUser?.email || currentClickedUser?.userEmail}
                  </p>
                </div>
              </div>
              <p className="font-normal text-[14px] text-[#475467] font-inter mb-[5px]">
                Your account will lose access to billing and administrative
                functions of this workspace.
              </p>
              <p className="font-[700] text-[14px] text-[#475467] font-inter">
                Your downgrade   is effective immediately.
              </p>
            </div>
            <div className="flex items-center justify-center mt-[20px]">
              <Button
                title="Cancel"
                isWhiteBtn={true}
                onClick={onClose}
                className="!text-[16px] !h-[44px] !font-[600] !text-[#344054] y-axis-shadow !rounded-[8px]"
              />
              <Button
                title={
                  swapToAdmin?.isPending ? (
                    <div className="w-[20px] h-[20px] border-[3px] mx-auto border-t-[3px] border-[#fff] border-solid rounded-full animate-spin border-t-[#8A43FF]" />
                  ) : (
                    "Confirm"
                  )
                }
                secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9] ml-[12px]"
                className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow !rounded-[8px]"
                onClick={() => {
                  handleSwapToAdminRole();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradeTeamMemberModal;
