import Button from "components/button";
import { FaRegCircleQuestion } from "react-icons/fa6";
import { useEffect, useState } from "react";
import { MdOutlineAccessTime } from "react-icons/md";
import { toast } from "react-toastify";
import nameCheck from "assets/images/name-check.svg";
import {
  useGetWorkspaceSettings,
  useUpdateWorkspaceSettings,
} from "services/settings-api";
import useSettingStore from "stores/settingStore";
import { getTimezoneObject } from "utils/index";
import { timeZones } from "constant";
import ReactSelect from "components/select";
import MailIcon from "assets/images/mail-01.svg";
import { useAuthStore } from "stores";

const ProfileForm = ({
  setIsUpdatePasswordModal,
  setIsOtpModal,
  setEmailUpdateId,
  isRefetchProfileData = false,
  setEmail,
  email,
}) => {
  const { userData, setUserData } = useSettingStore();
  const updateSettings = useUpdateWorkspaceSettings();
  const getSettings = useGetWorkspaceSettings();
  const { isAdmin } = useAuthStore();

  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [website, setWebsite] = useState("");
  const [timezone, setTimezone] = useState({
    value: "Us/Pacific",
    label: "US/Pacific",
  });
  const [companyError, setCompanyError] = useState("");
  const [updatingKey, setUpdatingKey] = useState(null);
  const handleNameChange = (value) => {
    setName(value);
  };

  const handleEmailChange = (value) => {
    setEmail(value);
  };

  const handleCompanyChange = (value) => {
    setCompany(value);
    // Validate company name
    if (value.length < 3) {
      setCompanyError("Company name must be at least 3 characters long.");
    } else {
      setCompanyError("");
    }
  };

  const handleTimezoneChange = (selectedTimezone) => {
    setTimezone(selectedTimezone);
  };

  useEffect(() => {
    if (company?.trim()?.length === 0) {
      setCompanyError("");
    }
  }, [company]);

  const handleUpdateSettings = async (key, value) => {
    if (companyError) {
      toast.error(companyError);
      return;
    }
    setUpdatingKey(key);
    try {
      const data = {
        ampersand_id: userData?.company?.crm_routing_id,
      };
      const res = await updateSettings?.mutateAsync({ ...data, [key]: value });
      const storeData = { ...userData, company: data };

      setUserData(storeData);
      toast.success("Personal Info Saved", {
        icon: () => <img src={nameCheck} alt="name" />,
      });
      if (res?.verification_required) {
        setIsOtpModal(true);
        setEmailUpdateId(res?.email_update_id);
      } else {
        getUserProfile();
      }
    } catch (error) {
      toast.success(error.data.detail, "Something went wrong");
    } finally {
      setUpdatingKey(null);
    }
  };

  const handleUpdatePassword = async () => {
    setIsUpdatePasswordModal(true);
  };

  useEffect(() => {
    if (isRefetchProfileData) {
      getUserProfile();
    }
  }, [isRefetchProfileData]);

  useEffect(() => {
    const selectedTimezone = getTimezoneObject(userData?.company?.timezone);
    setName(userData?.user?.name || name);
    setEmail(userData?.user?.email || email);
    setCompany(userData?.company?.company_name || company);
    setWebsite(userData?.company?.website || website);
    setTimezone(selectedTimezone || timezone);
  }, [userData]);

  const getUserProfile = async () => {
    try {
      const response = await getSettings.mutateAsync();
      const responseMain = {
        ...response,
        user: {
          ...response?.user,
          profile_pic: userData?.profile_pic,
        },
        company: {
          ...response?.company,
        },
      };
      setUserData(responseMain);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="max-w-[1064px]">
      <div className="border-t-[1px] border-[#E4E7EC] pt-[24px] pb-[20px] flex items-start">
        <h6 className="w-[280px] mr-[32px] font-[600] text-[#344054] text-[14px] mb-0">
          Name<span className="text-[#7F56D9]">*</span>
        </h6>
        <div className="flex items-start !w-[512px]">
          <div
            className="flex !border !border-[#D5D7DA] rounded-[6px] w-full overflow-hidden"
            style={{
              boxShadow:
                "0px 0.89px 1.79px 0px var(--ColorsEffectsShadowsshadow-xs)",
            }}
          >
            <input
              type="text"
              value={name}
              placeholder="Name"
              onChange={(e) => handleNameChange(e?.target?.value)}
              className="y-axis-shadow common-input !border-0 font-inter text-[14px] font-normal text-[#1D1D1D] placeholder:text-[#667085]  outline-none py-[9px] px-[14px] w-full"
            />
            <button
              onClick={() => handleUpdateSettings("name", name)}
              className="text-[#414651] text-[14px] font-[600] px-[12px] border-l border-[#D5D7DA] w-[100px]"
            >
              {updateSettings?.isPending && updatingKey === "name" ? (
                <div className="w-[18px] h-[18px] border-[3px] mx-auto border-t-[3px] border-[#8A43FF] border-solid rounded-full animate-spin border-t-[#fff]" />
              ) : (
                "Update"
              )}
            </button>
          </div>
        </div>
      </div>
      <div className="border-t-[1px] border-[#E4E7EC] py-[20px] flex items-start">
        <h6 className="w-[280px] mr-[32px] font-[600] text-[#344054] text-[14px] mb-0">
          Email address<span className="text-[#7F56D9]">*</span>
        </h6>
        <div className="flex flex-row items-center !w-[685px]">
          <div className="flex items-start relative w-full">
            <div
              className="flex !border !border-[#D5D7DA] rounded-[6px] w-full overflow-hidden relative"
              style={{
                boxShadow:
                  "0px 0.89px 1.79px 0px var(--ColorsEffectsShadowsshadow-xs)",
              }}
            >
              <input
                type="text"
                value={email}
                placeholder="Email"
                onChange={(e) => handleEmailChange(e?.target?.value)}
                className="y-axis-shadow common-input font-inter !border-0 text-[14px] font-normal text-[#1D1D1D] placeholder:text-[#667085]  outline-none pr-[9px] pl-[34px] px-[14px] w-full"
              />
              <button
                onClick={() => handleUpdateSettings("email", email)}
                className="text-[#414651] text-[14px] font-[600] px-[12px] border-l border-[#D5D7DA] w-[100px]"
              >
                {updateSettings?.isPending && updatingKey === "email" ? (
                  <div className="w-[18px] h-[18px] border-[3px] mx-auto border-t-[3px] border-[#8A43FF] border-solid rounded-full animate-spin border-t-[#fff]" />
                ) : (
                  "Update"
                )}
              </button>
              <img
                src={MailIcon}
                alt="mail-icon"
                className="absolute left-2 top-[10px]"
              />
            </div>
          </div>
          <Button
            title="Reset Password"
            gradientBg={true}
            className="!w-[171px] !text-[14px] leading-[20px] !h-[40px] !font-[600] y-axis-shadow !rounded-[7px] !pb-[3px] pt-[1.5px] ml-[41.82px]"
            onClick={handleUpdatePassword}
          />
        </div>
      </div>
      <div className="border-t-[1px] border-[#E4E7EC] py-[20px]">
        <div className="flex items-start">
          <h6 className="w-[280px] mr-[32px] font-[600] text-[#344054] text-[14px] mb-0">
            Company Name<span className="text-[#7F56D9]">*</span>
          </h6>
          <div className="flex items-start !w-[512px]">
            <div
              className="flex !border !border-[#D5D7DA] rounded-[6px] w-full overflow-hidden"
              style={{
                boxShadow:
                  "0px 0.89px 1.79px 0px var(--ColorsEffectsShadowsshadow-xs)",
              }}
            >
              <input
                type="text"
                value={company}
                onChange={(e) => handleCompanyChange(e?.target?.value)}
                disabled={!isAdmin}
                placeholder="Company"
                className="y-axis-shadow common-input font-inter !border-0 text-[14px] font-normal text-[#1D1D1D] placeholder:text-[#667085]  outline-none py-[9px] px-[14px] w-full"
              />
              {isAdmin && (
                <button
                  onClick={() => handleUpdateSettings("company_name", company)}
                  className="text-[#414651] text-[14px] font-[600] px-[12px] border-l border-[#D5D7DA] w-[100px]"
                  disabled={!!companyError} // Disable the button if there's an error
                >
                  {updateSettings?.isPending &&
                  updatingKey === "company_name" ? (
                    <div className="w-[18px] h-[18px] border-[3px] mx-auto border-t-[3px] border-[#8A43FF] border-solid rounded-full animate-spin border-t-[#fff]" />
                  ) : (
                    "Update"
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="border-t-[1px] border-[#E4E7EC] pt-[20px] flex items-start">
        <h6 className="w-[280px] mr-[32px] font-[600] text-[#344054] text-[14px] mb-0">
          Timezone<span className="text-[#7F56D9]">*</span>
          {/* <FaRegCircleQuestion className="text-[16px] text-[#98A2B3] ml-[5px] mt-[-2px] inline" /> */}
        </h6>
        <div className="flex flex-row items-center !w-[735px]">
          <div className="!w-[512px] select-scroll-styling profile-timezone">
            <ReactSelect
              value={timezone}
              onChange={handleTimezoneChange}
              placeholder="Select your timezone"
              icon={MdOutlineAccessTime}
              maxHeight={"150px"}
              options={timeZones}
              isTimeZoneSelect
              readOnly={!isAdmin}
            />
          </div>
          {isAdmin && (
            <Button
              title={
                updateSettings?.isPending && updatingKey === "timezone" ? (
                  <div className="w-[18px] h-[18px] border-[3px] mx-auto border-t-[3px] border-[#8A43FF] border-solid rounded-full animate-spin border-t-[#fff]" />
                ) : (
                  "Update Timezone"
                )
              }
              gradientBg={true}
              className="!w-[151px] !text-[14px] leading-[20px] !h-[40px] !font-[600] y-axis-shadow !rounded-[7px] !pb-[3px] pt-[1.5px] ml-[41.82px]"
              onClick={() => handleUpdateSettings("timezone", timezone?.label)}
            />
          )}
        </div>
      </div>
      <div className="flex items-center justify-end mt-[24px]"></div>
    </div>
  );
};

export default ProfileForm;
