import { useState, useEffect, useRef, useContext } from "react";
import { toast } from "react-toastify";
import { AppContext } from "context/AppContext";
import { useAccountStore, useAuthStore } from "stores";
import { formatNumberWithCommas } from "utils/index";
import { useWorkSpaceCreditLimit } from "services/accounts-api";
import { useCreateCampaignLeads } from "services/campaigns-api";
import { useGetProspectsList } from "services/prospecting-api";
import Button from "components/button";
import useCampaignStore from "stores/campaignStore";
import CommonCheckbox from "components/commonCheckbox";
import OutlinedInput from "components/outlinedInput";
import ReactSelect from "components/select";
import starIcon from "assets/images/light-stars.svg";
import MagicWand from "assets/images/magic-wand-02.svg";
import errorIcon from "assets/images/error.svg";
import sadIcon from "assets/images/sad-emoji.svg";
import userIcon from "assets/images/user.svg";

const CampaignLeadsStep = ({ setTab, tab }) => {
  const containerRef = useRef(null);

  const { companyId } = useAuthStore();
  const { workspaceCreditLimit, setWorkSpaceCreditLimit } = useAccountStore();
  const {
    setCampaignId,
    setTrackerId,
    draftListId,
    setDraftListId,
    setCampaignListId,
  } = useCampaignStore();
  const { setAppState } = useContext(AppContext);

  const getProspectsList = useGetProspectsList();
  const createLeadsMutation = useCreateCampaignLeads();

  const [refetchCredits, setRefetchCredits] = useState(false);
  const [prospectsList, setProspectsList] = useState([]);
  const [prospectsTableData, setProspectsTableData] = useState();
  const [selectedLeadList, setSelectedLeadList] = useState(null);
  const [numberOfLeadsToEnroll, setNumberOfLeadsToEnroll] = useState("");
  const [containerHeight, setContainerHeight] = useState("auto");
  const [expand, setExpand] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [includeDuplicateCount, setIncludeDuplicateCount] = useState(true);

  const { data, refetch } = useWorkSpaceCreditLimit({
    isEnabled: refetchCredits,
  });

  const getTotalLeads = (item) => {
    const validTags = [
      "Find LinkedIn URLS",
      "Upload a CSV of URLs",
      "Upload a CSV of Emails",
      "Manually Enter LinkedIn URLs",
      "Manually Enter Company URLs",
    ];

    return item?.prospected_type === "Imported" && validTags.includes(item?.tag)
      ? item?.get_prospect_and_campaign_leads_count?.total_leads_in_list || 0
      : item?.get_prospect_and_campaign_leads_count?.total_leads_in_list || 0;
  };

  const fetchProspectsList = async () => {
    try {
      const response = await getProspectsList.mutateAsync({
        limit: 100,
        page: 1,
        sort_by: "name",
        sort_order: "desc",
        company_id: companyId,
      });
      setProspectsTableData(response?.data);
      if (response?.data) {
        const data = response?.data
          ?.filter(
            (item) =>
              item?.prospected_type !== "Imported" ||
              item?.total_enriched_leads > 0 ||
              ["RB2B", "Webhook"]?.includes(item?.integration)
          )
          ?.map((item) => {
            return {
              imageUrl: userIcon,
              value: item?.prospect_id,
              label: item?.prospect_name,
              leadsAvailable: getTotalLeads(item),
              enriched_leads: includeDuplicateCount
                ? item?.get_prospect_and_campaign_leads_count
                    ?.unique_leads_enrolled_in_all_campaign || 0
                : item?.get_prospect_and_campaign_leads_count
                    ?.enriched_leads_in_list || 0,
              isRb2b: item?.integration === "RB2B",
              isWebhook: item?.integration === "Webhook",
            };
          });
        setProspectsList(data);
      }
    } catch (error) {
      console.error("Failed to fetch prospects list", error);
    }
  };

  const handleSelectChange = (option) => {
    setSelectedLeadList(option);

    const selectedProspect = prospectsTableData?.find(
      (item) => item?.prospect_id === option?.value
    );

    if (selectedProspect?.is_shared === true) {
      handleToggleExpand();
    } else {
      setExpand(false);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    const sanitizedValue = value.replace(/[^0-9]/g, "");
    setNumberOfLeadsToEnroll(sanitizedValue?.replace(/^0+(?!$)/, ""));

    if (sanitizedValue === "") {
      setAppState((prev) => ({
        ...prev,
        numberOfLeadsToEnroll: "",
      }));
      setErrorMessage("");
      return;
    }

    const formattedValue = sanitizedValue.replace(/^0+(?!$)/, "");
    const numericValue = parseInt(formattedValue, 10);

    if (!isNaN(numericValue) && numericValue >= 0) {
      if (numericValue > Number(workspaceCreditLimit)) {
        // Show "You do not have enough credits." toast for values between 99 and 235
        setErrorMessage("You don't have enough credits.");
        toast.success("You don't have enough credits.", {
          icon: () => <img src={sadIcon} alt="name" />,
        });
      } else if (numericValue > selectedLeadList?.leadsAvailable) {
        // Show "You do not have enough credits." toast for values between 99 and 235
        setErrorMessage("Please enter a smaller amount.");
        toast.success("This lead list does not have enough leads", {
          icon: () => <img src={sadIcon} alt="name" />,
        });
      } else {
        setErrorMessage("");
      }
      setAppState((prev) => ({
        ...prev,
        numberOfLeadsToEnroll: formattedValue,
      }));
    }
  };

  const handleToggleExpand = () => {
    setExpand(true);
  };

  const handleNext = async () => {
    if (draftListId) {
      setTab("Steps");
      return;
    }
    if (selectedLeadList && numberOfLeadsToEnroll) {
      setCampaignListId(selectedLeadList?.value);
      try {
        const payload = {
          prospect_id: selectedLeadList?.value,
          max_profiles: Number(numberOfLeadsToEnroll),
          filter_leads: includeDuplicateCount
            ? "exclude_all_duplicates"
            : "include_leads_from_team_and_my_campaigns",
          tracker_id: null,
        };
        const response = await createLeadsMutation?.mutateAsync(payload);
        if (response?.campaign_id) {
          setAppState((prev) => ({
            ...prev,
            leadsStepCompleted: true,
          }));
          setTab("Steps");
          setRefetchCredits(true);
          setCampaignId(response?.campaign_id);
          setDraftListId(selectedLeadList?.value);
          setTrackerId(response?.tracker_id);
          refetch();
        }
      } catch (error) {
        toast.error(
          error?.data?.detail || "Failed to enroll leads. Please try again."
        );
        console.log(error, "error");
      }
    } else if (selectedLeadList?.isRb2b || selectedLeadList?.isWebhook) {
      setCampaignListId(selectedLeadList?.value);
      try {
        const payload = {
          prospect_id: selectedLeadList?.value,
          max_profiles: Number(numberOfLeadsToEnroll),
          filter_leads: includeDuplicateCount
            ? "exclude_all_duplicates"
            : "include_leads_from_team_and_my_campaigns",
          tracker_id: null,
        };
        const response = await createLeadsMutation?.mutateAsync(payload);
        if (response?.campaign_id) {
          setAppState((prev) => ({
            ...prev,
            leadsStepCompleted: true,
          }));
          setTab("Steps");
          setRefetchCredits(true);
          setCampaignId(response?.campaign_id);
          setDraftListId(selectedLeadList?.value);
          setTrackerId(response?.tracker_id);
          refetch();
        }
      } catch (error) {
        toast.error(
          error?.data?.detail || "Failed to enroll leads. Please try again."
        );
        console.log(error, "error");
      }
    } else if (!selectedLeadList) {
      toast.error("No Lead Lists Found");
    } else {
      toast.error("Please enter a number of leads");
    }
  };

  const onKeyDown = (e) => {
    if (e?.key === "Tab") {
      e?.preventDefault();
    }
  };

  useEffect(() => {
    if (tab === "Leads") {
      fetchProspectsList();
    }
  }, [tab]);

  useEffect(() => {
    if (draftListId && prospectsTableData?.length !== 0) {
      const selectedProspect = prospectsTableData?.find(
        (item) => item?.prospect_id === draftListId
      );
      if (selectedProspect && selectedProspect?.is_shared === true) {
        handleToggleExpand();
      } else {
        setExpand(false);
      }

      setSelectedLeadList({
        imageUrl: userIcon,
        value: selectedProspect?.prospect_id,
        label: selectedProspect?.prospect_name,
        leadsAvailable: getTotalLeads(selectedProspect),
        enriched_leads:
          selectedProspect?.get_prospect_and_campaign_leads_count
            ?.enriched_leads_in_list,
        isRb2b:selectedProspect?.integration === "RB2B",
        isWebhook:selectedProspect?.integration === "Webhook"
      });
    }
  }, [draftListId, prospectsTableData]);

  useEffect(() => {
    if (data?.credit_limit) {
      setWorkSpaceCreditLimit(data?.credit_limit);
    }
  }, [data]);

  useEffect(() => {
    if (containerRef.current) {
      const scrollHeight = containerRef.current.scrollHeight;
      setContainerHeight(expand ? `${scrollHeight}px` : "70px");
    }
  }, [expand]);

  console.log({ prospectsList });

  return (
    <div className="w-[475px] bg-[#fff] rounded-[12px] common-y-shadow font-inter">
      <div className="p-[24px]">
        <div className="flex items-center justify-center border-[1px] border-[#E4E7EC] rounded-[10px] p-[11px] mb-[16px] y-axis-shadow w-[48px] h-[48px] mx-auto">
          <img src={starIcon} alt="info img" className="h-[24px] w-[24px]" />
        </div>
        <h4 className="font-[600] text-[18px] text-[#101828] m-0 font-inter">
          Select a Lead List
        </h4>
        <div
          className={`mb-[20px] mt-3 hubspot-select newCampaign-select ${
            draftListId ? "opacity-50" : "opacity-100"
          }`}
        >
          <ReactSelect
            options={prospectsList}
            placeholder="Select from the dropdown..."
            isNewCampaignSelect={true}
            onChange={handleSelectChange}
            value={selectedLeadList}
            placeholderImage
            readOnly={draftListId}
            isLoading={getProspectsList?.isPending}
            isRb2b={selectedLeadList?.isRb2b}
            isWebhook={selectedLeadList?.isWebhook}
          />
        </div>
        {selectedLeadList?.isRb2b ? (
          <>
            <p className="font-[400] text-[#9C9EA0] text-[13px] leading-[20px] font-inter text-center my-2">
              We’ll automatically enroll leads from RB2B.
            </p>
          </>
        ) : (
          <>
            {selectedLeadList?.isWebhook ? (
              <p className="font-[400] text-[#9C9EA0] text-[13px] leading-[20px] font-inter text-center my-2">
                We’ll automatically enroll leads synced from your webhook.{" "}
              </p>
            ) : (
              <>
                <p className="font-[600] text-[18px] text-[#101828] mt-2 mb-0.5 font-inter">
                  How many leads do you want to enroll?
                </p>
                <p
                  className={`text-[#9C9EA0] font-[400] text-[13px] leading-[20px] mb-3`}
                >
                  Add fresh leads that haven’t been contacted.
                </p>
                <div className="relative flex items-center mb-[7px]">
                  <OutlinedInput
                    disabled={draftListId}
                    placeholder={
                      draftListId
                        ? "You can't update the lead count."
                        : "e.g 50"
                    }
                    className={`!text-[#667085] pr-[20px] y-axis-shadow outline-input-campaign ${
                      errorMessage && "!border-[#FF1707]"
                    }`}
                    value={numberOfLeadsToEnroll}
                    onChange={handleInputChange}
                    onKeyDown={onKeyDown}
                  />
                  {errorMessage && (
                    <img
                      src={errorIcon}
                      alt="error"
                      className="w-[16px] h-[16px] absolute right-[13px] top-[13px]"
                    />
                  )}
                </div>
                {errorMessage && (
                  <p className="text-[#FF1707] text-[10.6px] font-[600]">
                    {errorMessage}
                  </p>
                )}
                {selectedLeadList?.leadType === "Imported" ? (
                  <>
                    <p className="text-[#9C9EA0] font-[400] text-[13px] leading-[20px] mt-[20px]">
                      Lead Count: <span>100</span>
                    </p>
                    <div className="flex">
                      <img src={MagicWand} alt="" className="mt-[-12px]" />
                      <p className="text-[#7F56D9] font-[400] text-[14px] leading-[20px] ml-[5px] mt-[8px] mb-[20px]">
                        All leads are enriched
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    {selectedLeadList && (
                      <p
                        className={`text-[#9C9EA0] font-[400] text-[13px] leading-[20px] mt-[7px]`}
                      >
                        <span className="text-[#7927FF]">
                          {formatNumberWithCommas(
                            selectedLeadList?.enriched_leads
                          ) || 0}
                          /
                          {formatNumberWithCommas(
                            selectedLeadList?.leadsAvailable
                          ) || 0}
                        </span>{" "}
                        Leads Enrolled in Campaigns
                      </p>
                    )}
                  </>
                )}
                <div
                  ref={containerRef}
                  className="toggle-list-container mt-[22px]"
                  style={{
                    maxHeight: containerHeight,
                    overflow: "hidden",
                    transition: "max-height 0.3s ease-out",
                  }}
                >
                  <div className="flex items-start w-full mb-[10px] newcampaign-toggle">
                    <div
                      className={`mr-6 ml-1 mt-0.5 ${
                        draftListId || !selectedLeadList ? "opacity-50" : ""
                      }`}
                    >
                      <CommonCheckbox
                        checked={!includeDuplicateCount}
                        onChange={() => {
                          const nextIncludeDuplicateCount =
                            !includeDuplicateCount;

                          if (selectedLeadList) {
                            const selectedProspect = prospectsTableData?.find(
                              (item) =>
                                item?.prospect_id === selectedLeadList?.value
                            );

                            if (selectedProspect) {
                              const updatedLeads = nextIncludeDuplicateCount
                                ? selectedProspect
                                    ?.get_prospect_and_campaign_leads_count
                                    ?.unique_leads_enrolled_in_all_campaign
                                : selectedProspect
                                    ?.get_prospect_and_campaign_leads_count
                                    ?.enriched_leads_in_list;

                              setSelectedLeadList((prev) => ({
                                ...prev,
                                enriched_leads: updatedLeads,
                              }));
                            }
                          }

                          setIncludeDuplicateCount(nextIncludeDuplicateCount);
                        }}
                        loginCheckBox={true}
                        disabled={draftListId || !selectedLeadList}
                      />
                    </div>
                    <div>
                      <h5 className="font-[500] text-[#344054] text-[14px] leading-[20px] font-inter">
                        Include Duplicate Leads
                      </h5>
                      <p className="font-[400] text-[#9C9EA0] text-[14px] leading-[20px] font-inter">
                        Use leads already enrolled in campaigns by me or my
                        team.
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
      <div className="p-[16px] pt-0">
        <Button
          title={
            <>
              <div className="flex items-center justify-center">
                {createLeadsMutation?.isPending ? (
                  <div className="w-[20px] h-[20px] border-[3px] border-t-[3px] border-[#fff] border-solid rounded-full animate-spin border-t-[#8A43FF]" />
                ) : (
                  "Next"
                )}
              </div>
            </>
          }
          disabled={errorMessage}
          secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9]"
          className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow !rounded-[8px]"
          onClick={() => handleNext()}
        />
      </div>
    </div>
  );
};

export default CampaignLeadsStep;
