import Layout from "components/layout";
import BreadcrumbHeader from "components/breadcrumbHeader";
import DashBoardCards from "components/dashboard/dashBoardCards";
import WelcomeBanner from "components/dashboard/welcomeBanner";
import { dashboardTab, pipelineResources } from "constant";
import { useEffect, useRef, useState } from "react";
import useSettingStore from "stores/settingStore";
import { useAuthStore } from "stores";
import { useGetDashboardActivity } from "services/dashboard-api";
import ReconnectBanner from "components/reconnectBanner";
import Tabs from "components/tabs/new-tabs";
import bandIcon from "assets/images/band.png";
import expandIcon from "assets/images/black-intend.svg";
import useGuidedTourStore from "stores/guidedTourStore";

const Dashboard = () => {
  const scrollRef = useRef();
  const { userData } = useSettingStore();
  const { userDetails } = useAuthStore();
  const { runTour } = useGuidedTourStore();
  const getDashboardActivity = useGetDashboardActivity();

  const [timeFrame, setTimeFrame] = useState("24 hours");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activityData, setActivityData] = useState([]);

  const getActivity = async () => {
    try {
      const response = await getDashboardActivity.mutateAsync({ timeFrame });
      const modifiedArray = [
        { name: "Invites Sent", value: response?.invites_sent || 0 },
        {
          name: "New Connections",
          value: response?.invites_accepted || 0,
        },
        { name: "Follow-ups", value: response?.followup_sent || 0 },
        { name: "Profiles Viewed", value: response?.profile_viewed || 0 },
        { name: "Liked Posts", value: response?.liked_posts || 0 },
        { name: "Liked Comments", value: response?.liked_comments || 0 },
      ];
      setActivityData(modifiedArray);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getActivity();
  }, [timeFrame]);

  // Handle tab selection (time frame)
  const handleTabChange = (tab) => {
    setTimeFrame(tab);
  };

  const handleTimeFrameChange = (newTimeFrame) => {
    setTimeFrame(newTimeFrame);
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    if (runTour && scrollRef?.current) {
      scrollRef.current.scrollTop = 250;
    }
  }, [runTour]);

  return (
    <Layout>
      <div className="h-[100vh] flex flex-col justify-start items-start font-inter relative z-20">
        <BreadcrumbHeader
          breadcrumbItems={[
            ...(userDetails?.company || userData?.company?.company_name
              ? [
                  {
                    title:
                      userDetails?.company || userData?.company?.company_name,
                  },
                ]
              : []),
            { title: userDetails?.name, route: "" },
          ]}
          // blackTheme={true} comment now for removing black theme
        />
        <div className="pl-[8px] pr-[14px] w-full">
          <ReconnectBanner />
        </div>
        <div
          ref={scrollRef}
          className={`max-w-[100%] w-full px-[24px] py-[12px] overflow-auto h-[calc(100vh_-45px)] flex flex-col innerScrollBar`}
          data-aos={!runTour && `fade-left`}
        >
          <div className="w-full mb-[23px]">
            <WelcomeBanner />
          </div>

          <div>
            <div id="dashboard">
              <div className="mb-[21px]">
                <Tabs
                  tabOptions={dashboardTab}
                  tab={timeFrame}
                  setTab={handleTabChange}
                />
              </div>
              {runTour ? (
                <DashBoardCards
                  getActivity={getActivity}
                  handleTimeFrameChange={handleTimeFrameChange}
                  timeFrame={timeFrame}
                  activityData={[
                    { name: "Invites Sent", value: 14 },
                    {
                      name: "New Connections",
                      value: 5,
                    },
                    { name: "Follow-ups", value: 12 },
                    { name: "Profiles Viewed", value: 50 },
                    { name: "Liked Posts", value: 2 },
                    { name: "Liked Comments", value: 3 },
                  ]}
                  isLoading={false}
                  isDropdownOpen={isDropdownOpen}
                  setIsDropdownOpen={setIsDropdownOpen}
                />
              ) : (
                <DashBoardCards
                  getActivity={getActivity}
                  handleTimeFrameChange={handleTimeFrameChange}
                  timeFrame={timeFrame}
                  activityData={activityData}
                  isLoading={getDashboardActivity?.isPending}
                  isDropdownOpen={isDropdownOpen}
                  setIsDropdownOpen={setIsDropdownOpen}
                />
              )}
            </div>
            <div className="w-full mt-[10px]">
              <div className="flex items-center mb-[21px]">
                <img
                  src={bandIcon}
                  alt="bandIcon"
                  className="w-[20px] mr-1.5"
                />
                <h5 className="font-[600] text-[#181D27] text-[19.7px] mb-0 mt-0.5">
                  Pipeline Resources
                </h5>
              </div>
              <div className="mt-[10.2px] flex items-start flex-wrap mb-5 gap-[18px] justify-between">
                {pipelineResources?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="w-[32%] maxTab:w-full mdDesk:w-[48.5%] mb-3"
                    >
                      <div className="w-full rounded-[17.6px] mb-[21px] overflow-hidden">
                        <img
                          src={item?.imgSrc}
                          alt="resourcesIcon"
                          className="h-full w-full object-fill"
                        />
                      </div>
                      <div className="flex items-start  mb-[26px] pr-4">
                        <div className="w-full">
                          <p className="mb-[8.7px] text-[19.5px] text-[#181D27] font-[600]">
                            {item?.title}
                          </p>
                          <p
                            className="mb-0 text-[17.3px] text-[#535862] font-[400]"
                            dangerouslySetInnerHTML={{
                              __html: item.description,
                            }}
                          ></p>
                        </div>
                        <img
                          src={expandIcon}
                          alt="expandIcon"
                          className="w-[10.8px] h-[10.8px] block mt-1"
                        />
                      </div>
                      <div className="flex items-center">
                        {item?.chips?.map((chip, index) => {
                          return (
                            <div
                              key={index}
                              className="mr-[5.7px] px-[8.7px] py-[2px] border border-[#D5D7DA] rounded-[6px] font-inter w-fit flex items-center"
                            >
                              <p
                                className="w-[6.5px] h-[6.5px] min-w-[6.5px] rounded-full mr-[6.5px] inline-block"
                                style={{ background: chip?.color }}
                              />
                              <p className="text-[15.2px] text-[#414651] font-[500]">
                                {chip?.name}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Dashboard;
