const CommonCheckbox = ({
  isRound = false,
  checked = false,
  onChange,
  disabled = false,
  loginCheckBox,
}) => {
  const handleChange = (event) => {
    if (onChange) {
      onChange(event?.target?.checked);
    }
  };

  return (
    <label className={`custom-checkbox ${isRound ? "rounded-checkbox" : ""}`}>
      <input
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
      />
      <span
        className={`${loginCheckBox && "ml-[-2px]"} ${disabled ? "cursor-not-allowed":""} checkmark`}
      ></span>
    </label>
  );
};

export default CommonCheckbox;
