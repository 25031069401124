import SwitchToggle from "components/switchToggle";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import BellIcon from "assets/images/bellIcon.png";
import {
  useGetNotificationSettings,
  useUpdateNotificationSettings,
} from "services/settings-api";
import Loader from "components/loader";

const NotificationsSettings = ({ setIsNotificationModal }) => {
  const getNotificationSettings = useGetNotificationSettings();
  const updateNotificationSettings = useUpdateNotificationSettings();

  // Separate states for each notification type
  const [linkedinNotifications, setLinkedinNotifications] = useState(false);
  const [campaignNotifications, setCampaignNotifications] = useState(false);
  const [emailNotifications, setEmailNotifications] = useState(false);

  const getTheNotificationSettings = async () => {
    try {
      const response = await getNotificationSettings?.mutateAsync();
      console.log({ response });

      // Update state based on response
      setLinkedinNotifications(
        response?.linkedin_notifications?.includes("email") || false
      );
      setCampaignNotifications(
        response?.campaign_notifications?.includes("email") || false
      );
      setEmailNotifications(
        response?.email_notifications?.includes("email") || false
      );
    } catch (error) {
      setLinkedinNotifications(false);
      setCampaignNotifications(false);
      setEmailNotifications(false);
    }
  };

  const handleUpdateNotificationSettings = async (type, value) => {
    // Initialize payload with current state values
    const payload = {
      linkedin_notifications: linkedinNotifications ? ["email"] : [],
      campaign_notifications: campaignNotifications ? ["email"] : [],
      email_notifications: emailNotifications ? ["email"] : [],
    };

    // Update only the relevant notification setting in the payload
    if (type === "linkedin") {
      payload.linkedin_notifications = value ? ["email"] : [];
    } else if (type === "campaign") {
      payload.campaign_notifications = value ? ["email"] : [];
    } else if (type === "email") {
      payload.email_notifications = value ? ["email"] : [];
    }

    try {
      // Make the API call to update settings

      const response = await updateNotificationSettings?.mutateAsync(
        payload
      );
      console.log({ response });
      toast.success("Notification Preference Updated", {
        icon: () => <img src={BellIcon} alt="name" />,
      });
    } catch (error) {
      toast.error(
        error?.data?.detail || "An error occurred while updating settings."
      );
    }
  };

  useEffect(() => {
    getTheNotificationSettings();
  }, []);

  const handleToggle = (type) => {
    // Toggle the specific setting and call the update function
    if (type === "linkedin") {
      const newValue = !linkedinNotifications;
      setLinkedinNotifications(newValue);
      handleUpdateNotificationSettings(type, newValue);
    } else if (type === "campaign") {
      const newValue = !campaignNotifications;
      setCampaignNotifications(newValue);
      handleUpdateNotificationSettings(type, newValue);
    } else if (type === "email") {
      const newValue = !emailNotifications;
      setEmailNotifications(newValue);
      handleUpdateNotificationSettings(type, newValue);
    }
  };

  return (
    <div className="relative">
      {
        getNotificationSettings?.isPending && <div className="w-full flex items-center justify-center h-full absolute left-0 top-0 bg-[#fbfbfba8] z-[99999]">
        <div className="mt-[30px] ml-[-10px]">
          <Loader />
        </div>
      </div>
      }
      <div className="flex items-start border-b-[1px] border-[#E4E7EC] py-[20px]">
        <div className="w-[280px] mr-[27px]">
          <h6 className="font-[600] text-[#344054] text-[14px] leading-[20px] mb-0">
            LinkedIn Notifications
          </h6>
          <p className="text-[#475467] text-[14px] font-[400] leading-[20px]">
            Receive email notifications for LinkedIn updates.
          </p>
        </div>
        <div className="flex flex-col gap-[5px]">
          <div className="flex items-center newcampaign-toggle px-2">
            <SwitchToggle
              checked={linkedinNotifications}
              onChange={() => handleToggle("linkedin")}
            />
            <p className="ml-[8px] text-[#344054] text-[14px] font-[500]">
              Email
            </p>
          </div>

          <div className="flex items-center newcampaign-toggle px-2">
            <SwitchToggle checked={false} disabled />
            <p className="ml-[8px] text-[#344054] text-[14px] font-[500] opacity-60">
              Slack
            </p>
          </div>
        </div>
      </div>

      <div className="flex items-start border-b-[1px] border-[#E4E7EC] py-[20px]">
        <div className="w-[280px] mr-[27px]">
          <h6 className="font-[600] text-[#344054] text-[14px] leading-[20px] mb-0">
            Campaign Notifications
          </h6>
          <p className="text-[#475467] text-[14px] font-[400] leading-[20px]">
            Receive email notifications for campaign updates.
          </p>
        </div>
        <div className="flex flex-col gap-[5px]">
          <div className="flex items-center newcampaign-toggle px-2">
            <SwitchToggle
              checked={campaignNotifications}
              onChange={() => handleToggle("campaign")}
            />
            <p className="ml-[8px] text-[#344054] text-[14px] font-[500]">
              Email
            </p>
          </div>
          <div className="flex items-center newcampaign-toggle px-2">
            <SwitchToggle checked={false} disabled />
            <p className="ml-[8px] text-[#344054] text-[14px] font-[500] opacity-60">
              Slack
            </p>
          </div>
        </div>
      </div>

      <div className="flex items-start border-b-[1px] border-[#E4E7EC] py-[20px]">
        <div className="w-[280px] mr-[27px]">
          <h6 className="font-[600] text-[#344054] text-[14px] leading-[20px] mb-0">
            Email Notifications
          </h6>
          <p className="text-[#475467] text-[14px] font-[400] leading-[20px]">
            Receive email notifications for general updates.
          </p>
        </div>
        <div className="flex items-center newcampaign-toggle px-2">
          <SwitchToggle
            checked={emailNotifications}
            onChange={() => handleToggle("email")}
          />
          <p className="ml-[8px] text-[#344054] text-[14px] font-[500]">
            Email
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotificationsSettings;
