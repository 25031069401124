import { useEffect, useState } from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import { toast } from "react-toastify";
import editIcon from "assets/images/edit.svg";
import archiveIcon from "assets/images/archive.svg";
import deleteIcon from "assets/images/action-delete.svg";
import shareIcon from "assets/images/share.svg";
import unArchiveIcon from "assets/images/unarchive.svg";
import { useAuthStore } from "stores";
import ReactDOM from "react-dom";

const ActionButtons = (props) => {
  const {
    isEditModalOpen,
    setIsEditModalOpen,
    isArchiveModalOpen,
    setIsArchiveModalOpen,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    position,
    setCurrentId,
    item,
    menuIsOpen,
    listType,
    confirmUnarchive,
    isShareModalOpen,
    setIsShareModalOpen,
  } = props;

  const { isAdmin } = useAuthStore();
  const [menuOpen, setMenuOpen] = useState(menuIsOpen);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const [openUpwards, setOpenUpwards] = useState(false);

  useEffect(() => {
    setMenuOpen(menuIsOpen);
  }, [menuIsOpen]);

  const toggleMenu = (e) => {
    e.stopPropagation();
    const buttonRect = e.target.getBoundingClientRect();
    const viewportHeight = window.innerHeight;
    const dropdownHeight = 100; // Approximate height of the dropdown
    const willOverflow =
      (buttonRect?.bottom || 0) + dropdownHeight > viewportHeight;

    setOpenUpwards(willOverflow);

    setDropdownPosition({
      top: willOverflow ? buttonRect.top - dropdownHeight : buttonRect.bottom,
      left: (buttonRect?.right || 0) - 188,
    });

    setMenuOpen((prev) => !prev);
  };

  const closeMenu = (e) => {
    if (e) e.stopPropagation();
    setMenuOpen(false);
  };

  const onUnarchive = () => {
    confirmUnarchive(item?.prospect_id);
    setMenuOpen(false);
    toast.success("Unarchived", {
      icon: () => <img src={unArchiveIcon} alt="Unarchived" />,
    });
  };

  const onDeleteList = () => {
    setCurrentId(item?.prospect_id);
    setIsDeleteModalOpen(!isDeleteModalOpen);
    setMenuOpen(false);
  };

  const dropdownMenu = () => (
    <div
      className={`w-[192px] bg-[#fff] border-[1px] border-[#E6E7E9] rounded-[6px] absolute actionButton-shadow z-[0] ${
        openUpwards ? "bottom-[20px]" : "top-[15px]"
      } pt-[4px] pb-[2px]`}
      style={{
        top: !openUpwards && dropdownPosition.top,
        left: dropdownPosition.left,
      }}
      role="menu"
      aria-labelledby="more-options"
    >
      <ul className="list-none p-0 m-0">
        {listType === "Archive" ? (
          <>
            <li
              className="flex items-center px-[12px] py-[6px] border-b-[1px] border-b-[#F3F4F6] cursor-pointer text-[13px] font-[400] text-[#374151] hover:bg-[#cccccc1c]"
              onClick={onUnarchive}
            >
              <img
                src={archiveIcon}
                alt="Unarchive"
                className="w-[15px] h-[15px] mr-[9px]"
              />
              Unarchive
            </li>
            <li
              className="flex items-center px-[12px] py-[6px] cursor-pointer text-[13px] font-[400] text-[#374151] hover:bg-[#cccccc1c]"
              onClick={onDeleteList}
            >
              <img
                src={deleteIcon}
                alt="Delete"
                className="w-[15px] h-[15px] mr-[9px]"
              />
              Delete List
            </li>
          </>
        ) : (
          <>
            {listType !== "All Lists" && (
              <li
                className="flex items-center px-[12px] py-[6px] cursor-pointer text-[13px] font-[400] text-[#374151] hover:bg-[#cccccc1c] border-b-[1px] border-b-[#F3F4F6]"
                onClick={() => {
                  setIsEditModalOpen(!isEditModalOpen);
                  setCurrentId(item);
                  setMenuOpen(false);
                }}
              >
                <img
                  src={editIcon}
                  alt="Edit"
                  className="w-[15px] h-[15px] mr-[9px]"
                />
                Edit List Name
              </li>
            )}
            {item?.is_archived ? (
              <li
                className="flex items-center px-[12px] py-[6px] border-b-[1px] border-b-[#F3F4F6] cursor-pointer text-[13px] font-[400] text-[#374151] hover:bg-[#cccccc1c]"
                onClick={onUnarchive}
              >
                <img
                  src={archiveIcon}
                  alt="Unarchive"
                  className="w-[15px] h-[15px] mr-[9px]"
                />
                Unarchive
              </li>
            ) : (
              <li
                className="flex items-center px-[12px] py-[6px] border-b-[1px] border-b-[#F3F4F6] cursor-pointer text-[13px] font-[400] text-[#374151] hover:bg-[#cccccc1c]"
                onClick={() => {
                  setCurrentId(item?.prospect_id);
                  setIsArchiveModalOpen(!isArchiveModalOpen);
                  setMenuOpen(false);
                }}
              >
                <img
                  src={archiveIcon}
                  alt="Archive"
                  className="w-[15px] h-[15px] mr-[9px]"
                />
                Archive
              </li>
            )}
            {listType !== "All Lists" && (
              <li
                className="flex items-center px-[12px] py-[6px] cursor-pointer text-[13px] font-[400] text-[#374151] hover:bg-[#cccccc1c]"
                onClick={onDeleteList}
              >
                <img
                  src={deleteIcon}
                  alt="Delete"
                  className="w-[15px] h-[15px] mr-[9px]"
                />
                Delete List
              </li>
            )}
            {listType === "All Lists" && !isAdmin && (
              <li
                className="flex items-center px-[12px] py-[6px] cursor-pointer text-[13px] font-[400] text-[#374151] hover:bg-[#cccccc1c]"
                onClick={() => {
                  setCurrentId(item?.prospect_id);
                  setIsShareModalOpen(!isShareModalOpen);
                  setMenuOpen(false);
                }}
              >
                <img
                  src={shareIcon}
                  alt="Share"
                  className="w-[15px] h-[15px] mr-[9px]"
                />
                Share
              </li>
            )}
          </>
        )}
      </ul>
    </div>
  );

  return (
    <div className="relative w-[25px] menu-container">
      <FiMoreHorizontal
        className="text-[25px] block mx-auto text-[#5B6871] cursor-pointer"
        onClick={toggleMenu}
        aria-label="More options"
      />
      {menuOpen && (
        <>
          <div
            className="fixed top-0 left-0 w-full h-full z-50"
            onClick={closeMenu}
            aria-hidden="true"
          />
          {ReactDOM.createPortal(dropdownMenu(), document.body)}
        </>
      )}
    </div>
  );
};

export default ActionButtons;


