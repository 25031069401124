import { useContext, useEffect, useRef, useState } from "react";
import Button from "components/button";
import uploadIcon from "assets/images/uploader.svg";
import fileIcon from "assets/images/upload-file.svg";
import deleteIcon from "assets/images/delete.svg";
import ProgressBar from "components/progressBar";
import { toast } from "react-toastify";
import { AppContext } from "context/AppContext";
import { useUploadProspectCsv } from "services/prospecting-api";
import { useAuthStore, useProspectStore } from "stores";
import useToastStore from "stores/toastStore";
import downloadIcon from "assets/images/upload-csv-file.svg";
import heartBreakIcon from "assets/images/dil.png";

let fileSize;

const UploadCsv = ({ setStep, setTotalDataCsv, handleTimerClear }) => {
  const { setAppState, appState } = useContext(AppContext) ?? {};
  const { companyId } = useAuthStore() ?? {};
  const { setFileId, setCsvColumns } = useProspectStore() ?? {};
  const [file, setFile] = useState(null);
  const { setToastId } = useToastStore() ?? {};

  const [progress, setProgress] = useState(0);
  const [showCheckedImg, setShowIsCheckedImg] = useState(false);
  const fileInputRef = useRef(null);
  const uploadCsv = useUploadProspectCsv();
  const timerRef = useRef(null);

  useEffect(() => {
    return () => {
      clearInterval(timerRef?.current);
    };
  }, []);

  if (file?.size < 1024) {
    fileSize = `${file?.size} bytes`;
  } else {
    fileSize = `${Math.round(file?.size / 1024)} KB`;
  }

  const handleFileChange = async (e) => {
    if (e?.key) {
      e?.preventDefault();
      return;
    }
    const selectedFile = e?.target?.files ? e.target.files[0] : null;
    // Check if a file is selected
    if (selectedFile) {
      // Check if file type is CSV
      if (selectedFile.type === "text/csv") {
        // Check if file size is less than or equal to 20 MB (20 * 1024 * 1024 bytes)
        const maxFileSize = 2 * 1024 * 1024; // 20 MB in bytes
        if (selectedFile.size <= maxFileSize) {
          setFile(selectedFile);
          uploadFile(selectedFile);
        } else {
          toast.error("File size should not exceed 2 MB.");
        }
      } else {
        toast.error("Please select a valid CSV file.");
      }
    }
  };

  const uploadFile = (file) => {
    const totalSize = file?.size;
    let uploadedSize = 0;
    const chunkSize = totalSize / 80;

    timerRef.current = setInterval(() => {
      if (uploadedSize >= totalSize) {
        clearInterval(timerRef?.current);
        setProgress(100);
        setTimeout(() => {
          setShowIsCheckedImg(true);
        }, 300);
        return;
      }
      uploadedSize += chunkSize;
      setProgress(Math.min(Math.round((uploadedSize / totalSize) * 100), 100));
    }, 80);
  };

  const deleteSelectedFile = () => {
    clearInterval(timerRef?.current);
    setFile(null);
    setProgress(0);
    setShowIsCheckedImg(false);
    if (fileInputRef?.current) {
      fileInputRef.current.value = "";
    }
    setAppState?.((prev) => ({
      ...prev,
      isDeleteCsv: true,
    }));
  };

  const onConfirm = async () => {
    const formData = new FormData();
    formData.append("company_id", companyId);
    formData.append("file", file);
    try {
      const result = await uploadCsv?.mutateAsync(formData);
      if (result?.file_id) {
        setFileId(result?.file_id);
        setCsvColumns(result?.uploaded_csv_columns);
        setTotalDataCsv(result?.total_data_in_csv);
        // const id = toast.success(
        //   <div className="flex items-start">
        //     <img
        //       src={Circle}
        //       alt="Unarchive"
        //       className="w-[38px] h-[38px] mr-[8px]"
        //     />
        //     <div className="mt-[4px]">
        //       <p className="text-[#101828] text-[14px] font-[600]">Heads up!</p>
        //       <p className="font-[400] text-[#344054] text-[14px] leading-[20px]">
        //         {appState?.selectedButtonType === "Peoples"
        //           ? `We'll import and enrich up to ${result?.total_data_in_csv} leads from your imported list.`
        //           : `We'll prospect and enrich leads from the first ${result?.total_data_in_csv} companies in your imported list.`}
        //       </p>
        //     </div>
        //   </div>,
        //   {
        //     position: "top-right",
        //     autoClose: false,
        //   }
        // );
        // setToastId?.(id);
        setStep("csv-form-step");
      }
    } catch (error) {
      setStep("upload-failed");
      toast.success("CSV Upload Failed", {
        icon: () => <img src={heartBreakIcon} alt="heartBreakIcon" />,
      });
    }
  };

  return (
    <>
      <div className="flex  items-start justify-start mb-[24px] font-inter">
        <div className="flex items-start justify-center border-[1px] border-[#E4E7EC] rounded-[10px] p-[11px] y-axis-shadow mr-[10px] w-[48px] h-[48px]">
          <img
            src={downloadIcon}
            alt="csv-icon"
            className="h-[25px] w-[25px]"
          />
        </div>
        <div>
          <h4 className="font-[600] text-[18px] text-[#101828] m-0 font-inter">
            Upload CSV
          </h4>
          <p className="font-normal text-[14px] text-[#475467] font-inter">
            You can upload CSVs with up to 2,500 leads.{" "}
          </p>
        </div>
      </div>
      {!file ? (
        <div className="flex items-center justify-center border-[2px] border-[#E4E7EC] rounded-[12px] p-[16px] mb-[12px] relative min-h-[125px]">
          <input
            ref={fileInputRef}
            type="file"
            accept=".csv"
            className="opacity-0 z-[2] cursor-pointer h-full w-full absolute top-0 left-0"
            onChange={(e) => handleFileChange(e)}
            onKeyDown={(e) => handleFileChange(e)}
          />
          <div className="flex flex-col items-center justify-center absolute top-0 px-[24px] py-[16px] h-full w-full">
            <img
              src={uploadIcon}
              alt="icon"
              className="min-w-[40px] w-[40px] mb-[12px]"
            />
            <p className="font-inter font-normal text-[#475467] text-[14px]">
              <span className="font-[600] text-[#6941C6]">Click to upload</span>{" "}
              or drag and drop
            </p>
            <p className="font-inter font-normal text-[#475467] text-[12px]">
              .csv files only.
            </p>
          </div>
        </div>
      ) : (
        <div className="flex items-center w-full pl-[24px]">
          <div className="flex items-start border-[2px] border-[#7F56D9] rounded-[12px] p-[16px] mb-[12px] relative w-full">
            <img
              src={fileIcon}
              alt="info img"
              className="min-w-[32px] w-[32px] mr-[16px]"
            />
            <div className="w-full">
              <div className="flex items-start justify-between w-[95%]">
                <div className="w-full">
                  <div className="flex items-center">
                    <h6 className="font-inter font-[500] text-[14px] leading-[20px] text-[#344054] m-0">
                      {file?.name}
                    </h6>
                  </div>
                  <p className="font-inter font-normal text-[14px] leading-[20px] text-[#475467] m-0">
                    {fileSize}
                  </p>
                </div>
              </div>
              <ProgressBar percentage={progress} />
            </div>
            {showCheckedImg && (
              <div className="h-[24px] w-[24px] rounded-[6px] overflow-hidden absolute z-[3] top-[-11px] right-[-9px]">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 130.2 130.2"
                  className="w-full h-full bg-[#7F56D9] tranisition-opacity delay-100 ease-in-out"
                >
                  <polyline
                    className="custom-checkmark check"
                    fill="none"
                    stroke="#fff"
                    strokeWidth="12"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    points="92.2,50.2 52.5,88.8 32.8,70.5"
                  />
                </svg>
              </div>
            )}
          </div>
          <img
            src={deleteIcon}
            alt="delete"
            className="ml-[11.9px] min-w-[15px] max-w-[15px] cursor-pointer mt-[-2px]"
            onClick={deleteSelectedFile}
          />
        </div>
      )}
      <div
        className={`flex items-center justify-center ${
          file ? "mt-[18px]" : "mt-[33px]"
        }`}
      >
        {progress === 100 ? (
          <Button
            title={
              <>
                <div className="flex items-center justify-center">
                  {uploadCsv?.isPending ? (
                    <div className="w-[20px] h-[20px] border-[3px] border-t-[3px] border-[#fff] border-solid rounded-full animate-spin border-t-[#8A43FF]" />
                  ) : (
                    "Continue"
                  )}
                </div>
              </>
            }
            secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9]"
            className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow"
            isRounded="6px"
            disabled={progress !== 100}
            onClick={() => {
              onConfirm();
            }}
          />
        ) : (
          <Button
            title="Cancel"
            isWhiteBtn={true}
            isRounded="6px"
            onClick={() => setStep?.("import-options")}
            className="!text-[16px] !h-[44px] !font-[600] !text-[#344054] y-axis-shadow"
          />
        )}
      </div>
    </>
  );
};

export default UploadCsv;
