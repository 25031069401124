import { useMutation, useQuery } from "@tanstack/react-query";
import axiosInt from "services";

const useConnectionList = (data) =>
  useQuery({
    queryFn: async () => {
      const { limit, sort_by, sort_order, page, active_tab, search_query } =
        data;
      const response = await axiosInt.get(
        `/outreach_v2/accepted_connection_list?active_tab=${active_tab}&limit=${limit}&page=${page}&sort_by=${sort_by}&sort_order=${sort_order}&search_query=${search_query}`
      );
      return response;
    },
  });

const useRemoveConnection = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.delete("/outreach_v2/withdraw_invite", {
        data,
      });
      return response;
    },
  });

const useWithdrawRequests = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.post(
        "/outreach_v2/withdraw_requests",
        data
      );
      return response;
    },
  });

export { useConnectionList, useRemoveConnection, useWithdrawRequests };
