import { useEffect, useRef, useCallback } from "react";
import { ArkoseCaptchaIntegration } from "utils/arkose";
import CaptchaIcon from "assets/images/scanner-qr.png";
import { useLinkedinCallback } from "services/auth-api";
import { toast } from "react-toastify";

let captchaSubmitted = false;

const StepCaptcha = ({
  data,
  setStep,
  setLoader,
  accountId,
  setIsPhoneCard,
  setLikedInData,
  startVerificationInterval,
}) => {
  const captchaRef = useRef(null);
  const submitAttemptRef = useRef({
    token: null,
    timestamp: 0,
    isProcessing: false,
  });

  const linkedInCallback = useLinkedinCallback();
  const publicKey = data?.public_key;

  const handleCallback = useCallback(
    async (token) => {
      // Prevent multiple calls within a short time frame
      const currentTime = Date.now();

      // If a token is already being processed or was recently processed
      if (
        submitAttemptRef.current.isProcessing ||
        (submitAttemptRef.current.token === token &&
          currentTime - submitAttemptRef.current.timestamp < 10000)
      ) {
        console.log("Duplicate submission prevented");
        return;
      }

      // Mark as processing
      submitAttemptRef.current = {
        token,
        timestamp: currentTime,
        isProcessing: true,
      };

      try {
        setLoader(true);

        const response = await linkedInCallback.mutateAsync({
          account_id: String(accountId),
          code: token,
        });

        setTimeout(() => {
          captchaSubmitted = false;
        }, 10000);

        if (response?.status === 201) {
          if (response?.message === "AccountCreated") {
            startVerificationInterval(String(accountId));
          }
        }

        if (response?.status === 202) {
          switch (response?.checkpoint?.type) {
            case "OTP":
              setLikedInData(response);
              setLoader(false);
              setStep(1);
              setIsPhoneCard(false);
              break;
            case "2FA":
              setLoader(false);
              setStep(7);
              break;
            case "PHONE_REGISTER":
              setLoader(false);
              setIsPhoneCard(true);
              setStep(1);
              break;
            case "IN_APP_VALIDATION":
              setLoader(false);
              setStep(6);
              break;
            default:
              throw new Error("Unexpected checkpoint type");
          }
        } else if (response?.status === 400) {
          setLoader(false);
          setStep(0);
          toast(response?.message);
        }
      } catch (error) {
        setLoader(false);
        setStep(0);
        toast.error(error.message || "Login failed");
        setTimeout(() => {
          captchaSubmitted = false;
        }, 10000);
      } finally {
        // Reset processing state after a delay
        setTimeout(() => {
          submitAttemptRef.current.isProcessing = false;
        }, 3000);
      }
    },
    [
      accountId,
      linkedInCallback,
      setStep,
      setLoader,
      setIsPhoneCard,
      setLikedInData,
      startVerificationInterval,
    ]
  );

  useEffect(() => {
    if (!publicKey) return;

    if (captchaRef.current) {
      try {
        captchaRef.current.unload();
      } catch (e) {
        console.error("Error unloading previous captcha", e);
      }
    }

    const captcha = new ArkoseCaptchaIntegration(publicKey, data?.data);
    captchaRef.current = captcha;

    captcha.onLoaded = () => {
      console.log("Captcha has been successfully loaded into the DOM!");
    };

    captcha.onSuccess = (token) => {
      console.log("Captcha has been resolved!", { token });

      submitAttemptRef.current = {
        token: null,
        timestamp: 0,
        isProcessing: false,
      };

      captcha.unload();
      if (!captchaSubmitted) {
        captchaSubmitted = true;
        handleCallback(token);
      }
    };

    captcha.onError = () => {
      console.log("Captcha resolution failed!");
    };

    try {
      captcha.load("captcha-frame");
    } catch (error) {
      console.error(error.message);
    }
  }, [publicKey, data, captchaSubmitted]);

  return (
    <div
      className="font-inter !h-full flex flex-col justify-center items-center m-auto max-[767px]:h-auto max-[767px]:p-[20px] max-[767px]:pt-[50px]"
      data-aos="fade-in"
      data-aos-duration="2000"
    >
      <div className="!w-[425px] flex flex-col items-center relative signin-card">
        <div className="w-[60px] h-[60px] flex items-center justify-center rounded-xl border border-[#333741] bg-[#0C111D]">
          <img
            src={CaptchaIcon}
            alt="Logo"
            className="h-[30px] w-[30px] block"
            style={{
              filter: "brightness(1.2)",
            }}
          />
        </div>
        <h1 className="text-[30px] text-center text-[#F5F5F6] font-[600] mt-[20px] mb-[5px]">
          Solve the Captcha
        </h1>
        <p className="text-[16px] font-[400] text-center text-[#94969C] leading-[24px]">
          You'll need to solve the captcha below.
        </p>

        <div className="flex w-full items-center justify-center">
          <div className="h-[290px] w-[89%] border-[2px] rounded-lg border-[#CBCBE84F] overflow-hidden mt-10">
            <div id="captcha-frame"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepCaptcha;
