import Layout from "components/layout";
import SearchResultTable from "components/searchResult/searchResultTable";
import { useNavigate, useParams } from "react-router-dom";
import BreadcrumbHeader from "components/breadcrumbHeader";
import CommonModal from "components/prospecting/modal";
import archieveIcon from "assets/images/boxes.svg";
import exportIcon from "assets/images/list-export.svg";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import SearchResultHeader from "components/searchResult/searchResultHeader";
import { AppContext } from "context/AppContext";
import EditListModal from "components/prospecting/prospectingSettingModals/editListModal";
import ArchiveModal from "components/prospecting/prospectingSettingModals/archiveModal";
import DeleteLeadModal from "components/prospecting/prospectingSettingModals/deleteLeadModal";
import ShareLinkModal from "components/prospecting/prospectingSettingModals/shareLinkModal";
import ExportLeadsModal from "components/prospecting/prospectingSettingModals/exportLeadModal";
import ReviewSidePanel from "components/reviewSidePanel";
import CompanyTable from "components/searchResult/companyTable";
import EmptyPage from "components/emptyPageComponent";
import { toast } from "react-toastify";
import {
  useArchiveProspect,
  useExportProspectLeads,
  useGetProspectLeads,
  useDeleteProspect,
  useProspectFiltersInfo,
} from "services/prospecting-api";
import {
  useAuthStore,
  useCampaignStore,
  useProspectStore,
  useSettingStore,
} from "stores";
import deleteImg from "assets/images/trash.svg";
import {
  downloadCSV,
  getRandomColor,
  getRandomCompanyLogo,
  getRandomProfileLogo,
} from "utils/index";
import { useCheckLeadStatus } from "services/campaigns-api";
import { logout } from "utils/user";
import ReconnectBanner from "components/reconnectBanner";
import ProspectingSearchTableSkeleton from "components/skeletonScreens/prospectingSearchTableSkeleton";

const SearchResult = () => {
  const { id } = useParams();
  const { prospect, setProspectInfo, searchProspect, setSearchPropspect } =
    useProspectStore();
  const { userData } = useSettingStore();
  const { trackerId } = useCampaignStore();
  const campaignParams = {
    qeary: `prospect_id=${id}${trackerId ? `&tracker_id=${trackerId}` : ""}`,
  };
  const {
    data: checkLeadStatus,
    refetch,
    isError,
  } = useCheckLeadStatus(campaignParams, prospect?.is_enriching);
  const deleteProspectList = useDeleteProspect();
  const { companyId } = useAuthStore();
  const useExportProspectLeadsMethod = useExportProspectLeads();
  const getProspectLeadsMutation = useGetProspectLeads();
  const archiveProspectListMutation = useArchiveProspect();
  const useGetProspectFiltersInfo = useProspectFiltersInfo();
  const [loading, setLoading] = useState(true);

  const hasFetchedData = useRef(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [viewData, setViewData] = useState();
  const navigate = useNavigate();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [selectedViewDropdown, setSelectedViewDropdown] = useState({
    label: "Default",
    value: "default",
  });
  const [selectedRowItem, setSelectedRowItem] = useState(null);
  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const { setAppState } = useContext(AppContext);
  const [edit, setEdit] = useState("");
  const [settingsMenuOpen, setSettingsMenuOpen] = useState(false);
  const [isPeopleSelected, setIsPeopleSelected] = useState(true);
  const [sortBy, setSortBy] = useState("full_name");
  const [sortOrder, setSortOrder] = useState("asc");
  const [paginationData, setPaginationData] = useState({
    limit: 20,
    page: 1,
  });
  const [modalRandomGradient, setModalRandomGradient] = useState("");
  const [modalRandomCompanyLogo, setModalRandomCompanyLogo] = useState("");
  const [modalRandomProfileLogo, setModalRandomProfileLogo] = useState("");
  const [tag, setTag] = useState(null);
  const [notEnrichedCount, setNotEnrichedCount] = useState(0);
  const [displayFailedLeads, setDisplayFailedLeads] = useState(false);
  const [actualLeads,setActualLeads] = useState(0)

  const prospectId = id || prospect?.prospects_id || prospect?.prospect_id;

  useEffect(() => {
    if (prospect) {
      setEdit(prospect?.prospect_name);
    }
  }, [prospect]);

  const getProspectingLeadsData = useCallback(
    async (limit, page, sort_by, sortOrder) => {
      if (prospectId && limit && page && sort_by) {
        const data = {
          prospect_id: prospectId,
          company_id: companyId,
          view_by_people: false,
        };
        try {
          const response = await getProspectLeadsMutation.mutateAsync({
            data,
            limit: limit,
            sort_by: sort_by,
            sort_order: sortOrder,
            page: page,
          });
          setTotalItems(response?.total);
          setData((prev) =>
            page === 1 ? response?.data : [...prev, ...response?.data]
          );
          setTag(response?.tag || null);
          setNotEnrichedCount(response?.failed_enriched_leads_count || 0);
          setDisplayFailedLeads(response?.display_failed_leads);
          setActualLeads(response?.actual_total_available_leads);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          if (error?.data && error?.data?.detail) {
            toast.error(error.data.detail);
          } else {
            toast.error("An unknown error occurred");
          }
        }
      }
    },
    [prospect, companyId, getProspectLeadsMutation]
  );

  useEffect(() => {
    if (!hasFetchedData.current) {
      getProspectingLeadsData();
      hasFetchedData.current = true;
    }
  }, [getProspectingLeadsData, prospect, paginationData]);

  useEffect(() => {
    if (checkLeadStatus?.prospected_status || !trackerId) {
      getProspectingLeadsData(
        paginationData?.limit,
        paginationData?.page,
        sortBy,
        sortOrder
      );
    }
  }, [paginationData, sortBy, sortOrder, prospect, checkLeadStatus, trackerId]);

  const handleDropdownSelect = (option) => {
    setSelectedViewDropdown(option);
    setSortBy(option?.value);
    setPaginationData({
      ...paginationData,
      page: 1,
      limit: 20,
    });
  };

  const handleArchiveProspect = async () => {
    try {
      const response = await archiveProspectListMutation.mutateAsync({
        company_id: companyId,
        prospect_id: prospectId,
        archived_status: prospect?.is_archived ? false : true,
      });
      toast.success(
        prospect?.is_archived ? "List Unarchived  " : "List Archived",
        {
          icon: () => <img src={archieveIcon} alt="archieveIcon" />,
        }
      );
      setProspectInfo({ ...prospect, is_archived: !prospect?.is_archived });
      setIsArchiveModalOpen(false);
    } catch (error) {
      setSettingsMenuOpen(false);
      toast.success("Something went wrong");
    }
  };

  const handleExportEnrichedLeads = async () => {
    try {
      const response = await useExportProspectLeadsMethod.mutateAsync({
        company_id: companyId,
        prospect_id: prospectId,
        is_people: isPeopleSelected,
      });
      console.log({ response });
      toast.success("List Exported", {
        icon: () => <img src={exportIcon} alt="exportIcon" />,
      });
      setIsExportModalOpen(false);
      setIsPeopleSelected(true);
      const csvData = response;
      setSettingsMenuOpen(false);
      downloadCSV(
        csvData,
        `${prospect?.prospect_name || "data"}_${
          isPeopleSelected ? "people" : "company"
        }.csv`
      );
    } catch (error) {
      setSettingsMenuOpen(false);
      toast.success(error?.data?.detail);
    }
  };

  const handleDeleteProspect = async () => {
    try {
      const res = await deleteProspectList.mutateAsync(
        {
          company_id: companyId,
          prospect_id: prospectId,
        },
        {
          onSuccess: (response) => {
            setIsDeleteModalOpen(false);
            toast.success(`List Deleted`, {
              icon: () => <img src={deleteImg} alt="delete" />,
            });
            navigate("/prospecting");
            setProspectInfo({
              ...prospect,
              is_archived: !prospect?.is_archived,
            });
          },
        }
      );
    } catch (error) {
      toast.error(error?.data?.detail);
    }
  };

  const handleRowClick = (row, logo) => {
    setSelectedRowItem(row);
    setModalRandomGradient(getRandomColor());
    setModalRandomCompanyLogo(logo);
    setModalRandomProfileLogo(getRandomProfileLogo());
  };
  const [hasMore, setHasMore] = useState(true);

  const onRefetch = () => {
    if (totalItems === data?.length) {
      console.log("totalItemstotalItemstotalItems");
      console.log(totalItems, "totalItems", data.length);
      setHasMore(false);
      return;
    }
    setPaginationData({
      ...paginationData,
      page: paginationData?.page + 1,
      limit: 20,
    });
  };

  useEffect(() => {
    let intervalId;

    if ((prospectId || trackerId) && !isError && prospect?.is_enriching) {
      if (trackerId) {
        setLoading(false);
      }
      intervalId = setInterval(() => {
        refetch();
      }, 20000);
    }

    return () => clearInterval(intervalId);
  }, [prospectId, trackerId, refetch, isError, prospect]);

  const handleDuplicateFilters = async () => {
    if (id) {
      try {
        const response = await useGetProspectFiltersInfo.mutateAsync({
          prospect_id: id,
        });
        const isOwner = response?.user_id === userData?.user_id;
        const isSharedUser = response?.shared_user_ids?.includes(
          userData?.user_id
        );

        if (!userData?.user_id) {
          return logout();
        }

        if (
          !["RB2B", "Webhook"]?.includes(prospect?.integration) &&
          (isOwner || isSharedUser)
        ) {
          setProspectInfo(response);
        }
      } catch (error) {
        console.log(error);
        if (!toast.isActive("no-access-toast")) {
          toast.error("Access to this prospect is restricted.", {
            toastId: "no-access-toast",
          });
          setSearchPropspect(null);
        }
        navigate("/prospecting");
      }
    }
  };

  useEffect(() => {
    handleDuplicateFilters();
  }, []);

  useEffect(() => {
    setEdit(prospect?.prospect_name || "");
  }, [isEditModalOpen, prospect]);

  return (
    <Layout>
      <div className="h-[100vh] flex flex-col justify-start items-start font-inter !overflow-hidden">
        <BreadcrumbHeader
          breadcrumbItems={[
            { title: "Prospecting", route: "/prospecting" },
            {
              title: "Lead Lists",
              route: "/prospecting",
            },
            { title: "Results" },
          ]}
        />
        <ReconnectBanner />
        <div className="max-w-[100%] w-full h-full" data-aos="fade-left">
          <SearchResultHeader
            setIsModalOpen={setIsModalOpen}
            isEditModalOpen={isEditModalOpen}
            setIsEditModalOpen={setIsEditModalOpen}
            isArchiveModalOpen={isArchiveModalOpen}
            setIsArchiveModalOpen={setIsArchiveModalOpen}
            isShareModalOpen={isShareModalOpen}
            setIsShareModalOpen={setIsShareModalOpen}
            isDeleteModalOpen={isDeleteModalOpen}
            setIsDeleteModalOpen={setIsDeleteModalOpen}
            isExportModalOpen={isExportModalOpen}
            setIsExportModalOpen={setIsExportModalOpen}
            selectedViewDropdown={selectedViewDropdown}
            handleDropdownSelect={handleDropdownSelect}
            totalItems={totalItems}
            setIsPeopleSelected={setIsPeopleSelected}
            isPeopleSelected={isPeopleSelected}
            settingsMenuOpen={settingsMenuOpen}
            setSettingsMenuOpen={setSettingsMenuOpen}
            prospectedStatus={checkLeadStatus?.prospected_status || !trackerId}
            isEnriching={
              !loading &&
              !data?.length &&
              checkLeadStatus &&
              !checkLeadStatus?.prospected_status &&
              !isError
            }
            tag={tag}
            notEnrichedCount={notEnrichedCount}
            displayFailedLeads={displayFailedLeads}
            actualLeads={actualLeads}
          />
          {loading ? (
            <div className="px-[29px] w-full h-[calc(100vh_-220px)] ">
              <ProspectingSearchTableSkeleton />
            </div>
          ) : data?.length > 0 &&
            (checkLeadStatus?.prospected_status || !trackerId) ? (
            <div className="mt-1 px-[29px] pb-[18px] modal-scroll mb-[20px]">
              {selectedViewDropdown?.value === "company" ? (
                <CompanyTable
                  onRowClick={() => null}
                  selectedRow={handleRowClick}
                  data={data}
                  setPaginationData={setPaginationData}
                  paginationData={paginationData}
                  totalItems={totalItems}
                  setSortBy={setSortBy}
                  setSortOrder={setSortOrder}
                  sortBy={sortBy}
                  sortOrder={sortOrder}
                  getRandomCompanyLogo={getRandomCompanyLogo}
                  onRefetch={onRefetch}
                  hasMore={hasMore}
                />
              ) : (
                <SearchResultTable
                  state={prospect}
                  isHideWebsite={prospect}
                  selectedRow={handleRowClick}
                  data={data}
                  onRowClick={() => null}
                  setSortBy={setSortBy}
                  setSortOrder={setSortOrder}
                  sortBy={sortBy}
                  sortOrder={sortOrder}
                  setPaginationData={setPaginationData}
                  paginationData={paginationData}
                  totalItems={totalItems}
                  onRefetch={onRefetch}
                  hasMore={hasMore}
                />
              )}
            </div>
          ) : checkLeadStatus &&
            !checkLeadStatus?.prospected_status &&
            !isError ? (
            <div className="flex items-center justify-center h-[82%] w-full">
              <div className="bg-white p-[24px] flex flex-col items-center rounded-lg z-10 w-full max-w-[270px] relative">
                <div className="enrich-animation-img mb-[20px]">
                  {/* enrich animation-image */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    height="54"
                    width="54"
                  >
                    <rect height="24" width="24"></rect>
                    <path
                      stroke-linecap="round"
                      stroke-width="1.5"
                      stroke="#000"
                      d="M19.25 9.25V5.25C19.25 4.42157 18.5784 3.75 17.75 3.75H6.25C5.42157 3.75 4.75 4.42157 4.75 5.25V18.75C4.75 19.5784 5.42157 20.25 6.25 20.25H12.25"
                      className="board"
                    ></path>
                    <path
                      d="M9.18748 11.5066C9.12305 11.3324 8.87677 11.3324 8.81234 11.5066L8.49165 12.3732C8.47139 12.428 8.42823 12.4711 8.37348 12.4914L7.50681 12.8121C7.33269 12.8765 7.33269 13.1228 7.50681 13.1872L8.37348 13.5079C8.42823 13.5282 8.47139 13.5714 8.49165 13.6261L8.81234 14.4928C8.87677 14.6669 9.12305 14.6669 9.18748 14.4928L9.50818 13.6261C9.52844 13.5714 9.5716 13.5282 9.62634 13.5079L10.493 13.1872C10.6671 13.1228 10.6671 12.8765 10.493 12.8121L9.62634 12.4914C9.5716 12.4711 9.52844 12.428 9.50818 12.3732L9.18748 11.5066Z"
                      className="star-2"
                    ></path>
                    <path
                      d="M11.7345 6.63394C11.654 6.41629 11.3461 6.41629 11.2656 6.63394L10.8647 7.71728C10.8394 7.78571 10.7855 7.83966 10.717 7.86498L9.6337 8.26585C9.41605 8.34639 9.41605 8.65424 9.6337 8.73478L10.717 9.13565C10.7855 9.16097 10.8394 9.21493 10.8647 9.28335L11.2656 10.3667C11.3461 10.5843 11.654 10.5843 11.7345 10.3667L12.1354 9.28335C12.1607 9.21493 12.2147 9.16097 12.2831 9.13565L13.3664 8.73478C13.5841 8.65424 13.5841 8.34639 13.3664 8.26585L12.2831 7.86498C12.2147 7.83966 12.1607 7.78571 12.1354 7.71728L11.7345 6.63394Z"
                      className="star-1"
                    ></path>
                    <path
                      className="stick"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      stroke="#000"
                      d="M17 14L21.2929 18.2929C21.6834 18.6834 21.6834 19.3166 21.2929 19.7071L20.7071 20.2929C20.3166 20.6834 19.6834 20.6834 19.2929 20.2929L15 16M17 14L15.7071 12.7071C15.3166 12.3166 14.6834 12.3166 14.2929 12.7071L13.7071 13.2929C13.3166 13.6834 13.3166 14.3166 13.7071 14.7071L15 16M17 14L15 16"
                    ></path>
                  </svg>
                </div>
                <h4 className="font-[600] text-center text-[16.5px] text-[#101828] mb-1.5">
                  Enriching Leads
                </h4>
                <p className="font-normal text-center text-[12.8px] leading-[18px] text-[#475467] ">
                  This may take a few minutes…
                </p>
                {/* <Button
                  title={
                    <div className="flex items-center justify-center font-inter !text-[16px] !font-[600] mt-[-1px]">
                      Got it!
                    </div>
                  }
                  secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9] !rounded-[8px]"
                  className={`!text-[16px] !h-[44px] !font-[600] y-axis-shadow mt-[20px]`}
                  onClick={() => navigate("/prospecting")}
                /> */}
              </div>
            </div>
          ) : (
            <div className="flex items-center justify-center h-[82%] w-full">
              <EmptyPage
                title="No Enriched Leads"
                description="You’ll need to start your first campaign in order to enrich leads!"
                primaryButtonTitle="New Campaign"
                primaryButtonClass="!w-[186px]"
                onClick={() => setIsModalOpen(true)}
                onPrimaryClick={() => navigate("/new-campaign")}
                hideWhiteButton={true}
                textWidth="!max-w-[235px]"
                isRB2B={prospect?.integration === "RB2B"}
                isWebhook={prospect?.integration === "Webhook"}
              />
            </div>
          )}
        </div>
      </div>
      {viewData?.companyName && (
        <div
          className="fixed left-0 top-0 w-full h-full"
          onClick={() => setViewData("")}
        />
      )}

      <div className="modal-scroll">
        <ReviewSidePanel
          rowData={selectedRowItem || searchProspect}
          setRowData={(data) => {
            setSearchPropspect(null);
            setSelectedRowItem(data);
          }}
          isProspecting={true}
          randomGradient={modalRandomGradient}
          profileLogo={modalRandomProfileLogo}
          companyLogo={modalRandomCompanyLogo}
          page={searchProspect ? null : "in-page"}
        />
      </div>
      {/* modal */}
      <CommonModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setAppState((prev) => ({ ...prev, saveListName: "" }));
        }}
        isSaveListModals={true}
      />
      <EditListModal
        isOpen={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false);
        }}
        setEdit={setEdit}
        edit={edit}
        prospectId={prospectId}
      />
      <ArchiveModal
        isOpen={isArchiveModalOpen}
        onClose={() => {
          setIsArchiveModalOpen(false);
        }}
        prospect={prospect}
        confirmArchive={handleArchiveProspect}
      />
      <DeleteLeadModal
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
        }}
        confirmDelete={handleDeleteProspect}
        isLoading={deleteProspectList?.isPending}
      />
      <ShareLinkModal
        isOpen={isShareModalOpen}
        onClose={() => {
          setIsShareModalOpen(false);
        }}
        isTeamAccessModal={true}
        currentId={prospectId}
      />
      <ExportLeadsModal
        isOpen={isExportModalOpen}
        onClose={() => {
          setIsExportModalOpen(false);
        }}
        setIsPeopleSelected={setIsPeopleSelected}
        isPeopleSelected={isPeopleSelected}
        handleExportEnrichedLeads={handleExportEnrichedLeads}
      />
    </Layout>
  );
};

export default SearchResult;
