import { IoClose } from "react-icons/io5";
import Button from "components/button";
import { useEffect, useState } from "react";
import Phone from "assets/images/mobile.svg";

const ReConnectInAppValidation = ({
  isOpen,
  onClose,
  startVerificationInterval,
  accountId,
  setLoader,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [isOpen]);
  if (!isOpen && !isModalVisible) return null;

  return (
    <>
      <div
        className={`fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[300ms] ease-in-out ${
          isModalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
      >
        <div
          className="fixed inset-0 bg-black opacity-10"
          onClick={onClose}
        ></div>
        <div
          className={`p-[24px] bg-white rounded-lg shadow-lg z-10 w-full max-w-[556px] relative transition-all ease-in-out duration-[150ms] ${
            isModalVisible ? "scale-100" : "scale-95"
          }`}
        >
          <IoClose
            className="text-[#667085] text-[20px] w-[24px] h-[24px] absolute right-[24px] top-[27px] cursor-pointer"
            onClick={onClose}
          />
          <div className="modal-scroll mt-3[33.39px]">
            <>
              <div className="w-[48px] h-[48px] rounded-[13.9px] flex items-center justify-center border border-[#E4E7EC] mb-[22px]">
                <img
                  src={Phone}
                  alt="Logo"
                  className="h-[24px] w-[24px] block"
                  style={{
                    filter: "brightness(1.2)",
                  }}
                />
              </div>
              <h1 className="text-[18px] text-[#101828] font-[600] mb-[5px]">
                Open your LinkedIn App
              </h1>
              <p className="text-[14px] font-[400] text-[#475467] leading-[24px]">
                On your mobile device, open the app and verify.{" "}
              </p>
              <Button
                onClick={() => {
                  onClose();
                  setLoader(true);
                  startVerificationInterval(accountId);
                }}
                isFullWidth={false}
                className="!bg-[#7F56D9] !border-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9] hover:!border-[#7F56D9] text-[#fff] !h-[44px] w-full !font-[600] text-[16px] rounded-[8px] !mt-[47px]"
                title={"Confirm"}
              />
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReConnectInAppValidation;
