import Logo from "assets/images/logo.svg";
import { useNavigate } from "react-router-dom";
import { GoPlus } from "react-icons/go";
import AnimatedButton from "components/animatedButton";

const GetStarted = () => {
  const navigate = useNavigate();

  return (
    <>
      <div
        className="!h-[100%] font-inter flex flex-col justify-center items-center max-w-[340px] m-auto max-[767px]:h-auto  max-[767px]:p-[20px]  max-[767px]:pt-[50px]  max-[767px]:max-w-[100%] signin-card"
        data-aos="fade-in"
        data-aos-duration="2000"
      >
        <img src={Logo} alt="Logo" className="h-[60px] w-[60px]" />
        <h1 className="text-[28px] text-center text-white font-[600] mt-[20px] mb-[11px]">
          Welcome to Pipeline!
        </h1>
        <p className="text-[15px] text-center text-[#CBCBE8BF] font-[400]">
          Your outreach, on autopilot.
        </p>
        <div className="mt-[25px] w-full">
          <AnimatedButton
            className="!rounded-[7px] !text-[16px] font-[600] text-[#FFFFFF]"
            title={
              <div className="flex items-center justify-center">
                <GoPlus size={20} className="mr-[2px] mt-[-1px]" />
                Get Started
              </div>
            }
            onClick={() => navigate("/create-workspace")}
          />
        </div>
      </div>
    </>
  );
};

export default GetStarted;
