import { useEffect, useState } from "react";
import Layout from "components/layout";
import Contacts from "./contacts";
import Messages from "./Messages";
import BreadcrumbHeader from "components/breadcrumbHeader";
import { useGetChats } from "services/chat-api";

const Inbox = () => {
  const [selectedUser, setSelectedUser] = useState();

  const { data, refetch, isFetching } = useGetChats();

  console.log(data, "data");

  useEffect(() => {
    const chatBubbleFrame = document.getElementById("pylon-chat");
    if (chatBubbleFrame && window.location.pathname === "/inbox") {
      chatBubbleFrame.style.display = "none";
    }

    return () => {
      if (chatBubbleFrame) {
        chatBubbleFrame.style.display = "block";
      }
    };
  }, []);

  return (
    <Layout>
      <div className="h-[100vh] flex flex-col justify-start items-start font-inter relative z-20">
        <BreadcrumbHeader breadcrumbItems={[{ title: "inbox" }]} />
        <div
          className={`max-w-[100%] w-full overflow-hidden max-h-[calc(100vh_-45px)] flex`}
        >
          <Contacts setSelectedUser={setSelectedUser} />
          <Messages selectedUser={selectedUser} />
        </div>
      </div>
    </Layout>
  );
};
export default Inbox;
