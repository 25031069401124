import Button from "components/button";
import { IoClose } from "react-icons/io5";
import startIcon from "assets/images/stars.svg";
import { useEffect, useState } from "react";
import { useManualBlockDomain } from "services/settings-api";
import { toast } from "react-toastify";

const SettingManualBlocklist = ({
  setStep,
  onClose,
  setBlockListResponse,
  getBlockDomains,
}) => {
  const [domains, setDomains] = useState("");
  const [error, setError] = useState("");
  const [domainArray, setDomainArray] = useState([]);
  const addManualBlockDomain = useManualBlockDomain();
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    setIsModalVisible(true);
    return () => {
      setIsModalVisible(false);
    };
  }, []);

  const domainRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/)?$/;

  const validateDomains = async () => {
    const domainList = domains.split("\n").map((domain) => domain.trim());
    const uniqueDomainList = [...new Set(domainList)];

    const isValid = uniqueDomainList
      ?.filter(Boolean)
      ?.every((domain) => domainRegex.test(domain));
    if (isValid) {
      const newDomains = [...uniqueDomainList];
      const payload = {
        domain: newDomains?.filter(Boolean),
      };
      try {
        const response = await addManualBlockDomain.mutateAsync(payload);
        setBlockListResponse(response);
        setStep(2);
        await getBlockDomains();
      } catch (error) {
        toast.error("Found duplicate domains. Please retry");
      }
    } else {
      setError("Some domains are invalid. Please check the input.");
    }
  };
  const handleInputChange = (e) => {
    let value = e.target.value;
    const lastWord = value.split("\n").pop().trim();
    if (domainRegex.test(lastWord) && value.endsWith(" ")) {
      value = value.trim() + "\n";
    }
    const domains = value.split("\n").filter((d) => d.length > 0);
    setDomainArray(domains);
    setDomains(value);
  };

  useEffect(() => {
    if (domainArray) {
      const isValid = domainArray.every((domain) => domainRegex.test(domain));
      if (!isValid) {
        setError("The data you entered cannot be submitted. Please fix!");
      } else {
        setError("");
      }
    }
  }, [domainArray]);

  return (
    <>
      <div
        className={`fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[300ms] ease-in-out ${
          isModalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
      >
        <div
          className="fixed inset-0 bg-black opacity-10"
          onClick={onClose}
        ></div>
        <div
          className={`bg-white p-[24px] rounded-lg modal-shadow z-10 w-full max-w-[600px] relative transition-all ease-in-out duration-[150ms] ${
            isModalVisible ? "scale-100" : "scale-95"
          }`}
        >
          <IoClose
            className="text-[#667085] text-[20px] w-[24px] h-[24px] absolute right-[24px] top-[26px] cursor-pointer"
            onClick={onClose}
          />
          <div className="flex flex-col items-start justify-start mb-[22px] font-inter">
            <div className="flex items-center justify-center border-[1px] border-[#E4E7EC] rounded-[10px] p-[11px] mb-[16px] y-axis-shadow">
              <img
                src={startIcon}
                alt="info img"
                className="h-[24px] w-[24px]"
              />
            </div>
            <h4 className="font-[600] text-[18px] text-[#101828] mb-[4px] font-inter">
              Manually Enter Blocklist
            </h4>
            <p className="font-normal text-[14px] text-[#475467] font-inter">
              Please enter <b>one domain</b> per line break.
            </p>
          </div>
          <div className="px-[24px]">
            <p className="font-[500] text-[14px] text-[#344054] mb-[6px] font-inter">
              Domains
            </p>
            <div className="relative">
              <textarea
                placeholder={`e.g www.facebook.com`}
                value={domains}
                onChange={handleInputChange}
                className={`overflow-auto p-[14px] text-[16px] y-axis-shadow leading-[24px] w-full h-[377px] border-[#D0D5DD] rounded-[8px] bg-[#FFFFFF] text-[#667085] placeholder:text-[#667085] ${
                  error
                    ? "!border-[#FF1707] !border-[2px]"
                    : "focus:!outline-none resize-none focus:!border-[#D0D5DD]"
                }`}
              ></textarea>
              {error && (
                <p className="text-[#FF1707] text-[10.6px] mt-[9px] font-[600] absolute top-full">
                  {error}
                </p>
              )}
            </div>
          </div>
          <div className="flex items-center justify-center mt-[52px]">
            <Button
              title="Next"
              secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9]"
              className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow"
              isRounded="6px"
              onClick={validateDomains}
              disabled={!error && !domains?.length}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingManualBlocklist;
