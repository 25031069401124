const DashboardSkeleton = () => {
  return (
    <>
      {Array(6)
        ?.fill(1)
        ?.map((_, index) => {
          return (
            <div
              key={index}
              className="ulShadow h-[170px] w-[32.4%] border border-[#E4E7EC] rounded-[11.2px] mb-[23.8px] animate-fadeIn"
            >
              <div className="relative flex flex-col h-full">
                <div className="h-full flex flex-col justify-between p-[24.9px]">
                  <h4 className="animate-bg-pulse rounded-md h-[18px] w-[100px]" />
                  <h3 className="animate-bg-pulse rounded-md h-[30px] w-[50px]"></h3>
                </div>
                <div className="h-[51px] border-t border-[#E4E7EC] py-[10px] px-[24.9px] flex justify-end">
                  <div className="animate-bg-pulse rounded-md h-[25px] w-[120px]"></div>
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
};

export default DashboardSkeleton;
