import { useMutation, useQuery } from "@tanstack/react-query";
import axiosInt from "services";

const useGetChats = () =>
  useQuery({
    queryKey: ["fetch_chats"],
    queryFn: async () => {
      const response = await axiosInt.get("/outreach_v2/chats");
      return response;
    },
  });

const useGetMessages = () =>
  useQuery({
    queryKey: ["fetch_messages"],
    queryFn: async () => {
      const response = await axiosInt.get("/outreach_v2/messages");
      return response;
    },
  });

const usePostMessage = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.put("/outreach_v2/message", data);
      return response;
    },
  });

export { useGetChats, useGetMessages, usePostMessage };
