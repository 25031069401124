import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthCheck, useCreateCompany } from "services/auth-api";
import { setPaymentVerify } from "utils/token-service";
import Loader from "components/loader";
import { toast } from "react-toastify";
import CompanyNameSection from "./company-name";
import InviteTeamSection from "./invite-team";
import Logo from "assets/images/logo.svg";

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const INITIAL_EMAIL_STATE = [""];

const CreateWorkspace = () => {
  const authCheckMutation = useAuthCheck();
  const createCompanyMutation = useCreateCompany();
  const navigate = useNavigate();

  const parentRef = useRef(null);
  const [loader, setLoader] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [companyNameError, setCompanyNameError] = useState(false);
  const [inviteTeamMember, setInviteTeamMember] = useState(false);
  const [emails, setEmails] = useState(INITIAL_EMAIL_STATE);
  const [emailErrors, setEmailErrors] = useState([]);
  const [showTooltip, setShowTooltip] = useState(false);

  const validateEmail = (email) => {
    return EMAIL_REGEX.test(String(email).toLowerCase());
  };

  const handleAuthCheck = async () => {
    try {
      const navigationCheck = await authCheckMutation.mutateAsync({});
      if (navigationCheck?.company_setup) {
        setPaymentVerify(true);
        setTimeout(() => {
          setLoader(false);
          navigate("/dashboard");
        }, 1000);
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.error("Auth check failed:", error);
    }
  };

  const handleLaunchWorkspace = async (e) => {
    e.preventDefault();

    // Validate Company Name
    const isCompanyNameValid = companyName.trim() !== "";
    setCompanyNameError(!isCompanyNameValid);

    // Validate Emails
    const newErrors = emails.map((email) =>
      email ? !validateEmail(email) : false
    );

    const hasErrors = newErrors.some((error) => error);

    if (!isCompanyNameValid || hasErrors) {
      return;
    }

    setLoader(true);
    try {
      const response = await createCompanyMutation.mutateAsync({
        company_name: companyName,
        team_members: emails?.filter(Boolean),
      });

      if (response.success) {
        setPaymentVerify(true);
        navigate("/dashboard");
      }
    } catch (error) {
      toast.error(error?.data?.detail || "An unknown error occurred");
    } finally {
      setLoader(false);
    }
  };

  const handleEmailChange = (index, value) => {
    const newEmails = [...emails];
    newEmails[index] = value;
    setEmails(newEmails);

    const newErrors = [...emailErrors];
    newErrors[index] = value ? !validateEmail(value) : false;
    setEmailErrors(newErrors);
  };

  const handleDeleteEmail = (index) => {
    setEmails(emails.filter((_, i) => i !== index));
  };

  const scrollToView = () => {
    if (parentRef?.current) {
      parentRef.current.scrollTo({
        top: parentRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const handleAddEmail = () => {
    if (emails.every((email) => email && validateEmail(email))) {
      setEmails([...emails, ""]);
      setEmailErrors([...emailErrors, false]);
      setTimeout(scrollToView, 100);
    } else {
      toast.success(
        emails.length <= 1
          ? "Please enter a valid email address before adding another one"
          : "Please enter valid email addresses in all fields"
      );
    }
  };

  useEffect(() => {
    handleAuthCheck();
  }, []);

  useEffect(() => {
    if (emailErrors.length > 0 && emailErrors[emails.length - 1]) {
      setTimeout(scrollToView, 100);
    }
  }, [emailErrors]);

  if (loader) {
    return (
      <div className="!h-full font-inter flex flex-col justify-center items-center max-[767px]:h-auto max-[767px]:p-[20px] max-[767px]:pt-[50px] max-[767px]:max-w-[100%]">
        <img
          src={Logo}
          alt="Logo"
          className="h-[60px] w-[60px] mb-[30px] relative"
        />
        <Loader />
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center">
      {!inviteTeamMember ? (
        <CompanyNameSection
          companyName={companyName}
          companyNameError={companyNameError}
          setCompanyName={setCompanyName}
          setCompanyNameError={setCompanyNameError}
          setInviteTeamMember={setInviteTeamMember}
          setLoader={setLoader}
        />
      ) : (
        <InviteTeamSection
          parentRef={parentRef}
          emails={emails}
          emailErrors={emailErrors}
          showTooltip={showTooltip}
          handleEmailChange={handleEmailChange}
          handleDeleteEmail={handleDeleteEmail}
          handleAddEmail={handleAddEmail}
          handleLaunchWorkspace={handleLaunchWorkspace}
          setShowTooltip={setShowTooltip}
        />
      )}
    </div>
  );
};

export default CreateWorkspace;
