import DashboardSkeleton from "components/skeletonScreens/dashboardSkeleton";
import Loader from "components/loader";
import { TimeFrameOptions } from "constant";
import { useState } from "react";
import useGuidedTourStore from "stores/guidedTourStore";

const DashBoardCards = ({
  handleTimeFrameChange,
  timeFrame,
  activityData,
  isLoading,
  isDropdownOpen,
  setIsDropdownOpen,
}) => {
  const [currentIndexed, setCurrentIndexed] = useState(null);
  const {runTour } = useGuidedTourStore()

  return (
    <div
      className="flex justify-between flex-wrap relative w-full"
    >
      {isDropdownOpen && (
        <div
          className="fixed top-0 left-0 z-30 bg-transparent w-full h-full"
          onClick={() => setIsDropdownOpen(false)}
        />
      )}
      {!isLoading ? (
        activityData?.map((item, index) => {
          return (
            <div
              key={index}
              className="ulShadow min-h-[169px] w-[32.4%] border border-[#E4E7EC] rounded-[11.2px] mb-[23.8px]"
            >
              <div className="p-[24.9px] relative">
                <h4 className="font-[500] text-[#475467] font-inter text-[14.5px] mb-[7.49px] leading-[18.74px]">
                  {item?.name}
                </h4>
                <h3 className="font-[600] text-[#101828] font-inter text-[31.1px] leading-[35.6px]">
                  {item?.value}
                </h3>
              </div>
              <div className="px-[24.5px] py-[16.6px] border-t border-[#E4E7EC] flex items-center justify-end">
                <button
                  className="text-[#6941C6] text-left text-[14.5px] font-[600] font-inter relative cursor-pointer outline-none border-0"
                  onClick={() => {
                    setCurrentIndexed(index);
                    setIsDropdownOpen(!isDropdownOpen);
                  }}
                >
                  <span className="capitalize">
                    {
                      TimeFrameOptions?.find(
                        (item) => item?.value === timeFrame
                      )?.label
                    }
                  </span>
                  {isDropdownOpen && currentIndexed === index && (
                    <ul className="absolute mt-2 top-full z-50 bg-[#f5f5f5] border-[1px] border-[#E4E7EC] rounded-[9.37px] w-[150px] py-[6px]">
                      {TimeFrameOptions.map((option, index) => (
                        <li
                          key={index}
                          onClick={() => handleTimeFrameChange(option?.value)}
                          className={`cursor-pointer text-left text-[#475467] px-[22px] font-[500] text-[13.1px] py-1 ${
                            timeFrame === option?.value && "!text-[#6941C6]"
                          }`}
                        >
                          {option?.label}
                        </li>
                      ))}
                    </ul>
                  )}
                </button>
              </div>
            </div>
          );
        })
      ) : (
        <DashboardSkeleton />
      )}
    </div>
  );
};

export default DashBoardCards;
