// @ts-ignore: Unreachable code error
import AnimatedButton from "components/animatedButton";
import listIcon from "assets/images/is-premium.png";
import { useState } from "react";
import { useLinkedinPremiumCheck } from "services/auth-api";
import { useNavigate } from "react-router-dom";

const UpgradeLinkedIn = ({
  setStep,
  setLoader,
  setInvitedUser,
  verifyLinkedin,
  intervalRef,
  handleVerifyError,
}) => {
  const linkedinPremiumCheck = useLinkedinPremiumCheck();
  const [clickedButton, setClickedButton] = useState(false);
  const navigate = useNavigate();

  const onInvite = () => {
    if (localStorage.getItem("invited") === "true") {
      setInvitedUser(true);
      setLoader(true);
      setTimeout(() => {
        navigate("/dashboard");
        setInvitedUser(false);
        localStorage.removeItem("invited");
      }, 3000);
    } else {
      setStep(3);
    }
  };

  const handleVerification = async (accountId) => {
    try {
      const verify = await verifyLinkedin.mutateAsync({
        account_id: accountId,
      });

      if (verify?.is_premium) {
        clearInterval(intervalRef.current);
        setStep(3);
        setLoader(false);
      } else if (verify?.login_failed) {
        clearInterval(intervalRef.current);
        setClickedButton(false);
        setLoader(false);
        setStep(0);
      } else {
        setClickedButton(false);
        clearInterval(intervalRef.current);
        setLoader(false);
      }
    } catch (error) {
      setClickedButton(false);
      clearInterval(intervalRef.current);
      setLoader(false);
      handleVerifyError(error);
    }
  };

  const startVerificationInterval = (accountId) => {
    intervalRef.current = setInterval(async () => {
      await handleVerification(accountId);
    }, 30000);
  };

  const handleButtonClick = async () => {
    if (clickedButton) {
      setLoader(true);
      setTimeout(async () => {
        try {
          const response = await linkedinPremiumCheck.mutateAsync();
          if (response?.status === 200) {
            startVerificationInterval(response?.account_id);
          }
        } catch (error) {
          console.log(error);
          setLoader(false);
          onInvite();
        }
      }, 2000);
    } else {
      try {
        const response = await linkedinPremiumCheck.mutateAsync();
        if (response?.is_premium) {
          setStep(3);
          setLoader(false);
        } else {
          window.open("https://www.linkedin.com", "_blank");
          setLoader(false);
          setClickedButton(true);
        }
      } catch (error) {
        console.log(error);
        setLoader(false);
        if (error?.status === 404) {
          setStep(2);
          return;
        }
        onInvite();
      }
    }
  };

  return (
    <>
      <div className="!w-[450px] m-auto !h-full  font-inter flex flex-col justify-center items-center max-[767px]:h-auto  max-[767px]:p-[20px]  max-[767px]:pt-[50px]  max-[767px]:max-w-[100%] signin-card">
        <div className="w-[60px] h-[60px] bg-[#0C111D] flex items-center justify-center rounded-lg border-[#333741] border">
          <img
            src={listIcon}
            alt="list"
            className="w-[24px] h-[24px] mx-auto block"
            style={{
              filter: "brightness(1.2)",
            }}
          />
        </div>
        <div className="text-center">
          <p className="text-[#F5F5F6] font-[600] text-[30px] mt-[20px] ">
            Upgrade your LinkedIn
          </p>
          <div className="text-[#C3C3D7E3] mt-[34px] text-[14px]">
            <p className="font-[400] text-[14px] text-center ">
              Your LinkedIn account is on the Free plan.
            </p>
            <br />
            <p className="">
              In order to utilize key features, you’ll need to
              <p className=" text-[14px] text-center ">
                upgrade your LinkedIn account to premium.
              </p>
            </p>
          </div>
        </div>
        <div className="mt-[41px] w-[360px]">
          <AnimatedButton
            title={`${clickedButton ? "Check Again" : "Take me to LinkedIn"}`}
            onClick={handleButtonClick}
            className="!text-[16px] !font-[600] p-[10px] !rounded-[8px] w-[360px] !bg-[#635BFF]"
          />
        </div>
      </div>
    </>
  );
};

export default UpgradeLinkedIn;
