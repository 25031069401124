import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

const useGuidedTourStore = create((set) => ({
  isGuidedTourRunning: false,
  currentStepIndex: 0,
  runTour: false,
  setRunTour: (runTour) => set({ runTour: runTour }),
  clearRunTour: () => set({ runTour: false }),
  setCurrentStepIndex: (currentStepIndex) =>
    set({ currentStepIndex: currentStepIndex }),
  clearCurrentStepIndex: () => set({ currentStepIndex: 0 }),
  setIsGuidedTourRunning: (isGuidedTourRunning) =>
    set({ isGuidedTourRunning: isGuidedTourRunning }),
  clearIsGuidedTourRunning: () => set({ isGuidedTourRunning: false }),
}));

export default useGuidedTourStore;
