import Button from "components/button";
import { MdOutlineAdd } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import searchIcon from "assets/images/darkest-searchIcon.png";
import centerIcon from "assets/images/connection-empty.svg";
const EmptyState = ({ tab, searchList }) => {
  const navigate = useNavigate();
  const renderContentForTab = () => {
    switch (tab) {
      case "All":
        return (
          <>
            <div className="w-[469px] font-inter">
              <div className="flex flex-col justify-center items-center">
                <div
                  className="flex items-start justify-center border-[1px] border-[#E9EAEB] rounded-[10px] p-[10px] y-axis-shadow w-[43.5px] h-[43.5px] mb-[15.5px]"
                  style={{
                    boxShadow:
                      "rgb(193, 193, 193, 0.5) 0px -0.22px 0.55px 0px, rgb(227, 227, 227, 0.5) 0px 1.45px 0px 0px",
                  }}
                >
                  <img
                    src={searchIcon}
                    alt="csv-icon"
                    className="h-[22px] w-[22px]"
                  />
                </div>
                {/* <img src={containerSearch} alt="csv-icon" className="h-[45px] w-[45px] mt-[-15px]" /> */}
                <h4 className="font-[600] !text-center text-[16.4px] text-[#181D27] mb-[7px] font-inter">
                  No Leads Found
                </h4>
                <p
                  className={`font-[400] text-[12.8px] text-center text-[#535862] font-inter ${
                    searchList?.length !== 0 ? "mb-[60px]" : "mb-[25px]"
                  } `}
                >
                  {searchList?.length !== 0
                    ? "Try another search!"
                    : "Launch a campaign to see lead data here!"}
                </p>
                {searchList?.length === 0 && (
                  <Button
                    title={
                      <div className="flex items-center justify-center font-inter !text-[16px] !font-[600] mt-[-1px]">
                        <MdOutlineAdd className="text-[20px] mr-[7px] mt-[1px]" />
                        Create Campaign
                      </div>
                    }
                    secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9] !rounded-[8px]"
                    className={`!text-[16px] !h-[44px] !font-[600] y-axis-shadow !w-[190px] `}
                    onClick={() => navigate("/new-campaign")}
                  />
                )}
              </div>
            </div>
          </>
        );
      case "accepted":
        return (
          <>
            <div className="w-[469px] font-inter">
              <div className="flex flex-col justify-center items-center">
                <img
                  src={centerIcon}
                  alt="csv-icon"
                  className="w-[120px]"
                />
                <h4 className="font-[600] !text-center text-[15.55px] text-[#181D27] mt-[15.55px] font-inter">
                  No Connections Found
                </h4>
                <p
                  className={`font-[400] text-[12.44px] text-center text-[#535862] font-inter mt-[6.22px] leading-[18.66px]`}
                >
                  When a lead from your campaign accepts
                  <br /> your invite, you’ll see them listed here!
                </p>
              </div>
            </div>
          </>
        );
      case "invite_sent":
        return (
          <>
            <div className="w-[469px] font-inter">
              <div className="flex flex-col justify-center items-center">
              <img
                  src={centerIcon}
                  alt="csv-icon"
                  className="w-[120px]"
                />
                <h4 className="font-[600] !text-center text-[16.4px] text-[#181D27] mb-[7px] font-inter mt-[15.55px]">
                  No Invites Sent{" "}
                </h4>
                <p
                  className={`font-[400] text-[12.44px] text-center text-[#535862] font-inter mb-[25px]`}
                >
                  Launch a campaign to your latest
                  <br /> invites sent with Pipeline.
                </p>
                <Button
                  title={
                    <div className="flex items-center justify-center font-inter !text-[16px] !font-[600] mt-[-1px]">
                      <MdOutlineAdd className="text-[20px] mr-[7px] mt-[1px]" />
                      Create Campaign
                    </div>
                  }
                  secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9] !rounded-[8px]"
                  className={`!text-[16px] !h-[44px] !font-[600] y-axis-shadow !w-[190px] `}
                  onClick={() => navigate("/new-campaign")}
                />
              </div>
            </div>
          </>
        );
      default:
        return (
          <>
            <div className="w-[469px] font-inter">
              <div className="flex flex-col justify-center items-center">
              <img
                  src={centerIcon}
                  alt="csv-icon"
                  className="w-[120px]"
                />
                <h4 className="font-[600] !text-center text-[16.4px] text-[#181D27] mt-[20px] font-inter">
                  No Withdrawn Invites
                </h4>
                <p
                  className={`font-[400] text-[12.44px] text-center text-[#535862] font-inter mt-[8px] leading-[18.66px]`}
                >
                  You can configure your settings to automatically
                  <br /> withdraw invites after a certain time period by
                  <br /> clicking “Withdraw” in the top right.
                </p>
              </div>
            </div>
          </>
        );
    }
  };
  return <>{renderContentForTab()}</>;
};

export default EmptyState;
