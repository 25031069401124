import searchIcon from "assets/images/search-lg.svg";
import commandIcon from "assets/images/command-text.svg";
import billingAvatar1 from "assets/images/billingAvatar1.svg";
import billingAvatar2 from "assets/images/billingAvatar2.svg";
import billingAvatar3 from "assets/images/billingAvatar3.svg";
import billingAvatar4 from "assets/images/billingAvatar4.svg";
import billingAvatar5 from "assets/images/billingAvatar5.svg";
import { useEffect, useState } from "react";

const contactList = [
  {
    name: "Phoenix Baker",
    avatar: billingAvatar2,
    position: "Lead Product Designer",
    lastSeen: "5min ago",
    new: true,
    lastMessage:
      "Hey Olivia, Katherine sent me over the latest doc. I just have a quick question about the...",
    messages: [
      {
        id: 1,
        sender: "Phoenix Baker",
        text: "Nice to meet you! Glad to connect. Let's grab coffee soon!",
        time: "2:20 PM",
        isSender: false,
        isLiked: true,
      },
      {
        id: 2,
        sender: "You",
        text: "Let's do it - text you the details and we can schedule.",
        time: "Just now",
        isSender: true,
        isLiked: true,
      },
    ],
  },
  {
    name: "Mollie Hall",
    avatar: billingAvatar1,
    position: "CEO of Rockstar",
    lastSeen: "1hr ago",
    new: true,
    lastMessage:
      "I’ve just published the site again. Looks like it fixed it. How weird! I’ll keep an eye on it...",
    messages: [],
  },
  {
    name: "Katherine Moss",
    avatar: billingAvatar3,
    position: "@kathy",
    lastSeen: "20min ago",
    new: false,
    lastMessage:
      "You: Sure thing, I’ll have a look today. They’re looking great!",
    messages: [],
  },
  {
    name: "Rosalee Melvin",
    avatar: billingAvatar4,
    position: "@rosalee",
    lastSeen: "2hr ago",
    new: false,
    lastMessage:
      "Hey Liv — just wanted to say thanks for chasing up the release for me. Really...",
    messages: [],
  },
  {
    name: "Anaiah Whitten",
    avatar: billingAvatar5,
    position: "@analah",
    lastSeen: "1hr ago",
    new: false,
    lastMessage:
      "Good news!! Jack accepted the offer. I’ve sent over a contract for him to review but...",
    messages: [],
  },
  {
    name: "Katherine Moss",
    avatar: billingAvatar3,
    position: "@kathy",
    lastSeen: "20min ago",
    new: false,
    lastMessage:
      "You: Sure thing, I’ll have a look today. They’re looking great!",
    messages: [],
  },
  {
    name: "Rosalee Melvin",
    avatar: billingAvatar4,
    position: "@rosalee",
    lastSeen: "2hr ago",
    new: false,
    lastMessage:
      "Hey Liv — just wanted to say thanks for chasing up the release for me. Really...",
    messages: [],
  },
  {
    name: "Anaiah Whitten",
    avatar: billingAvatar5,
    position: "@analah",
    lastSeen: "1hr ago",
    new: false,
    lastMessage:
      "Good news!! Jack accepted the offer. I’ve sent over a contract for him to review but...",
    messages: [],
  },
];

const Contacts = ({ setSelectedUser }) => {
  const [usersData, setUsersData] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    setSelectedUser(contactList?.[0]);
  }, []);

  useEffect(() => {
    if (searchValue) {
      setUsersData([]);
      setSelectedUser();
    } else {
      setUsersData(contactList);
    }
  }, [searchValue]);

  return (
    <div className="border-r border-[#E9EAEB] w-[30%] h-full relative">
      <div className="pb-0.5">
        <div className="flex items-center px-[24px] py-[20px] font-[600] text-[#181D27] text-lg">
          <p>Your Messages</p>
          <div className="flex items-center h-[22px] pl-[5px] border border-[#D5D9EB] rounded-sm ml-2">
            <div className="w-3 h-3 bg-[#7F56D924] flex items-center justify-center rounded-full">
              <div className="bg-[#7F56D9] h-1.5 w-1.5 rounded-full" />
            </div>
            <p className="text-xs font-medium text-[#535862] ml-1 mr-1.5">
              2 unread
            </p>
          </div>
        </div>

        <div className="border border-[#D5D7DA] mx-[16px] mb-[12px] rounded-[8px]">
          <div className="modal-scroll w-full">
            <div className="relative flex items-center justify-start w-full">
              <img
                src={searchIcon}
                className="h-[20px] w-[20px] left-[12px] absolute"
                alt="Search"
              />
              <input
                name="search"
                type="text"
                placeholder="Search"
                onChange={(e) => setSearchValue(e?.target?.value)}
                className="w-full h-[40px] py-[18px] pl-[40px] pr-[50px] px-[16px] font-[400] rounded-[8px] text-[#667085] bg-[#fff] text-[16px] !outline-none !border-none"
              />
              <div className="right-[12px] absolute flex items-center justify-center border border-[#E4E7EC] bg-[#F9FAFB] py-0.5 px-1 rounded-sm">
                <img
                  src={commandIcon}
                  className="h-[20px] w-[20px]"
                  alt="command"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col h-[calc(100vh_-170px)] overflow-y-scroll modal-scroll">
        {usersData?.length ? (
          usersData?.map((user, index) => (
            <div
              className={`h-[128px] pl-4 pr-5 py-5 border-b border-[#E9EAEB] cursor-pointer ${
                user?.new ? "bg-[#F6F6F6]" : "opacity-70"
              }`}
              key={index}
              onClick={() => setSelectedUser(user)}
            >
              <div className="flex">
                <img
                  src={user?.avatar}
                  alt="import"
                  className="mr-[6px] w-10 h-10 rounded-full"
                />
                <div>
                  <h1 className="text-sm font-semibold text-[#181D27]">
                    {user?.name}
                  </h1>
                  <p className="text-sm text-[#535862]">{user?.position}</p>
                </div>
                <div className="ml-auto flex">
                  <p
                    className={`text-sm text-[#535862] ${
                      user?.new ? "font-medium" : "font-normal"
                    }`}
                  >
                    {user?.lastSeen}
                  </p>
                  {user?.new && (
                    <div className="flex items-center  h-[22px] w-[52px] pl-[5px]  border border-[#D5D9EB] rounded-sm ml-2">
                      <div className="w-3 h-3 bg-[#7F56D924] flex items-center justify-center rounded-full">
                        <div className="bg-[#7F56D9] h-1.5 w-1.5 rounded-full" />
                      </div>
                      <p className="text-xs font-medium text-[#363F72] ml-[3px]">
                        New
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="text-[#535862] text-sm font-medium px-4 py-3">
                {user?.lastMessage}
              </div>
            </div>
          ))
        ) : (
          <div className="h-full flex justify-center pt-[55%]">
            <p className="text-[#BBBBBB] font-[500] leading-[18.66px] text-[12.44px]">
              No Messages Found
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Contacts;
