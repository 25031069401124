import star from "assets/images/blink-star.svg";
import smStar from "assets/images/small-blink-star.svg";
import rightStar from "assets/images/right-blink-star.svg";
import VideoWrapper from "routes/video-wrapper";

const WelcomeBanner = () => {
  return (
    <div className="w-full h-[226px] rounded-[18px] overflow-hidden">
      <VideoWrapper className={"!h-[226px] w-full"}>
        <div
          className="flex flex-col justify-between py-[28.38px] px-[30.27px] font-inter absolute z-20">
          <h2 className="font-[700] text-[26.7px] text-[#fff] leading-[34.35px] mb-[40px]">
            Goodbye Manual,
            <br />
            Hello Automation.
          </h2>
          <div className="">
            <p className="font-[500] text-[#FFFFFFCC] text-[12.4px] mb-[16.1px]">
              Need help getting started?
            </p>
            <a
              href="https://pipeline-knowledge-base.help.usepylon.com"
              target="_blank"
              rel="noreferrer"
              className="cursor-pointer no-underline transition duration-75 ease-in-out hover:bg-[#5F00E4] rounded-[9.46px] bg-[#5F00E4] w-[136.3px] h-[40.7px] text-[#fff] flex items-center justify-center font-[700] text-[14.2px] leading-[16.5px]"
            >
              Help Center
            </a>
          </div>
        </div>
        {/* left star */}
        <div class="absolute left-star-glowing left-[290px] top-[15%]">
          <img src={rightStar} alt="star" className="w-[146px] h-full" />
        </div>
        {/* right star */}
        <div class="absolute big-star-glowing top-0 right-2">
          <img src={star} alt="star" className="w-[204px] h-full" />
        </div>
        {/* small star */}
        <div class="small-star-glowing absolute bottom-[36px] right-[165px]">
          <img src={smStar} alt="star" className="w-[89px] h-[85px]" />
        </div>
      </VideoWrapper>
    </div>
  );
};
export default WelcomeBanner;
