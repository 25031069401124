import ArrowRightUp from "assets/images/arrow-right-up.svg";
import linkedInIcon from "assets/images/colored-linkedin.svg";
import { useEffect, useState } from "react";
import {
  useGetLeadInfo,
  useGetLeadHistoryInfo,
} from "services/prospecting-api";
import { toast } from "react-toastify";
import { useAuthStore, useCampaignStore } from "stores";
import Loader from "components/loader";
import { getNameInititals, getRandomColor } from "utils/index";
import { COUNTRIES, getCountryFromLocation } from "constant/countryFlag";
import { getRandomCompanyLogo } from "utils";
import dummyFlag from "assets/images/dummyFlag.png";
import successLinkedin from "assets/images/green-linkedin-icon.svg";
import premiumLinkedin from "assets/images/premium-linkedin-logo.svg";
import moment from "moment";
import emptyStep from "assets/images/empty-white-step.svg";
import acceptIcon from "assets/images/accept-icon.svg";
import { renderHistoryStatus } from "utils/render-status";
import { useLocation, useNavigate } from "react-router-dom";

const ReviewSidePanel = ({
  rowData,
  setRowData,
  isProspecting,
  randomGradient,
  companyLogo,
  page = false,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { companyId } = useAuthStore();
  const {
    setCampaignId,
    setOpenLeadsTabDirectly,
    setCampaign,
    setDraftListId,
  } = useCampaignStore();
  const leadInfoData = useGetLeadInfo();
  const leadsHistoryinfo = useGetLeadHistoryInfo();

  const [modalRandomGradient, setModalRandomGradient] = useState("");
  const [data, setData] = useState(null);
  const [historyData, setHistoryData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const getProspectingLeadsData = async () => {
    if (rowData?.prospect_lead_id) {
      try {
        const response = await leadInfoData.mutateAsync({
          prospect_lead_id: rowData?.prospect_lead_id,
        });
        response?.prospects_details &&
          setDraftListId(response?.prospects_details?.prospects_id);
        response?.campaign_details && setCampaign(response?.campaign_details);
        setData(response);
      } catch (error) {
        if (error?.data && error?.data?.detail) {
          toast.error(error.data.detail);
        } else {
          toast.error("An unknown error occurred");
        }
      }
    }
  };

  const getProspectingLeadsHistoryData = async () => {
    const fetchHistoryData = async (id, field) => {
      try {
        const response = await leadsHistoryinfo.mutateAsync({ [field]: id });
        setHistoryData(response);
      } catch (error) {
        console.log(error);
      }
    };

    // if (location?.pathname === "/connections" && rowData?.lead_id) {
    //   fetchHistoryData(rowData?.lead_id, "lead_id");
    // } else if (
    //   location?.pathname === "/default-activity" &&
    //   rowData?.prospect_lead_id
    // ) {
    fetchHistoryData(rowData?.prospect_lead_id, "prospect_lead_id");
    // }
  };

  useEffect(() => {
    setModalRandomGradient(getRandomColor());
  }, [randomGradient]);

  useEffect(() => {
    getProspectingLeadsData();
    getProspectingLeadsHistoryData();
  }, [rowData, companyId]);

  const flag = COUNTRIES?.find((item) =>
    getCountryFromLocation(data?.location?.toLocaleLowerCase())?.includes(
      item?.country?.toLocaleLowerCase()
    )
  )?.flag;

  const handleErrorImage = (e) => (e.target.src = getRandomCompanyLogo().src);

  useEffect(() => {
    if (rowData) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
      setHistoryData(null);
    }
  }, [rowData]);

  const handleCloseModal = () => {
    if (page === "in-page") {
      setIsModalVisible(false);
    } else if (
      Object?.values(data?.campaign_details || {})?.length > 0 &&
      data?.campaign_details?.id
    ) {
      navigate("/default-activity");
      setCampaignId(data?.campaign_details?.id);
      setOpenLeadsTabDirectly(true);
    } else if (data?.prospects_details) {
      navigate(`/prospecting/${data?.prospects_details?.prospects_id}`);
    }
    setRowData(null);
  };

  if (!rowData && !isModalVisible) return null;

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[300ms] ease-in-out ${
        isModalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className={`fixed inset-0 bg-black opacity-10`}
        onClick={handleCloseModal}
      />

      <div
        className={`bg-white p-[26px] rounded-lg modal-shadow z-10 w-full max-w-[1170px] h-[90vh] relative transition-all ease-in-out duration-[150ms] ${
          isModalVisible ? "scale-100" : "scale-95"
        }`}
      >
        {leadInfoData?.isPending ? (
          <div className="h-[90vh] flex justify-center items-center">
            <Loader />
          </div>
        ) : (
          <>
            <div className="relative flex items-center mb-[31px]">
              <div className="border-[2px] relative border-white rounded-[50%] h-[91px] min-w-[91px] w-[91px] avatarWrap mr-[21.8px]">
                <img
                  src={linkedInIcon}
                  alt="linkedInIcon"
                  className="absolute z-[99999] bottom-0 right-0 w-[20.1px] h-[20.1px]"
                />
                {data?.profile_pic ? (
                  <img
                    src={data?.profile_pic}
                    alt="Avatar"
                    className="w-[100%] h-[100%] object-cover rounded-[50%]"
                  />
                ) : (
                  <div className="w-full h-full rounded-full flex items-center justify-center text-[24px] font-[700] text-[#4409B9] bg-[#EEEDFD]">
                    {getNameInititals(data?.full_name)}
                  </div>
                )}
              </div>
              <div className="w-[85%]">
                <p className="text-[18.2px] font-[600] text-[#181D27] leading-[24.3px]">
                  {data?.full_name}
                </p>

                <p className="text-[17.2px] font-[400] text-[#535862] leading-[25.9px] mb-[3px]">
                  {data?.sub_title || "Current Job Title"}
                </p>
                <div className="flex items-center">
                  <img
                    onError={handleErrorImage}
                    src={data?.company_logo || companyLogo?.src || companyLogo}
                    alt="CompanyLogoMark"
                    className={`w-[20px] h-[20px] mr-[8px] y-axis-shadow object-cover ${
                      data?.company_logo && "rounded-full"
                    }`}
                  />
                  <p className="text-[16px] font-[500] text-[#414651] leading-[24px]">
                    {data?.company}
                  </p>
                </div>
              </div>
              <a
                href={data?.linkedin_profile_url}
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <img
                  src={linkedInIcon}
                  alt="LinkedinButton"
                  className="h-[29.8px] w-[29.8px] cursor-pointer absolute top-0 right-0"
                />
              </a>
            </div>
            <div className="max-h-[calc(90vh_-200px)] overflow-auto modal-scroll flex items-start justify-between pl-[12px] pr-2">
              <div className="w-[280px] min-w-[280px] mr-[32px]">
                <div className="mb-[24px]">
                  <p className="text-[14px] font-[500] text-[#535862] mb-[8px]">
                    Location
                  </p>
                  <div className="flex items-center">
                    <img
                      src={flag || dummyFlag}
                      alt="USAFlag"
                      className="rounded-full w-[20px] h-[20px] min-w-[20px] mr-[8px] object-cover"
                    />
                    {data?.location && (
                      <p className="text-[16px] font-[500] text-[#414651]">
                        {data?.location}
                      </p>
                    )}
                  </div>
                </div>
                {/* industry */}
                {data?.person_industry && (
                  <div className="mb-[24px]">
                    <p className="text-[14px] font-[500] text-[#535862] mb-[8px]">
                      Industry
                    </p>
                    <p className="text-[16px] font-[500] text-[#414651] leading-[24px]">
                      {data?.person_industry}
                    </p>
                  </div>
                )}
                {/* current company */}
                <div className="mb-[24px]">
                  <p className="text-[14px] font-[500] text-[#535862] mb-[8px]">
                    Current Company
                  </p>
                  <div className="flex items-start mb-[8px]">
                    <img
                      onError={handleErrorImage}
                      src={
                        data?.company_logo || companyLogo?.src || companyLogo
                      }
                      alt="CompanyLogoMark"
                      className={`w-[20px] h-[20px] mr-[8px] mt-[4px] y-axis-shadow object-cover ${
                        data?.company_logo && "rounded-full"
                      }`}
                    />
                    <p className="text-[16px] font-[500] text-[#414651] leading-[24px]">
                      {data?.company}
                    </p>
                  </div>
                  <div
                    className="flex items-center cursor-pointer"
                    onClick={() => window.open(data?.company_linkedin)}
                  >
                    <img
                      src={linkedInIcon}
                      alt="LinkedinButton"
                      className="h-[20px] w-[20px] mr-[8px] min-w-[20px]"
                    />
                    <p className="text-[#6941C6] font-[600] text-[16px]">
                      View Company LinkedIn
                    </p>

                    <span className="ml-[8px] cursor-pointer">
                      <a
                        href={data?.company_linkedin}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={ArrowRightUp}
                          alt="ArrowRightUp"
                          className="w-[10px] h-[10px]"
                        />
                      </a>
                    </span>
                  </div>
                </div>
                {/* languages */}
                {data?.language && (
                  <div className="mb-[24px]">
                    <p className="text-[14px] font-[500] text-[#535862] mb-[8px]">
                      Language
                    </p>
                    <p className="text-[16px] font-[500] text-[#414651] leading-[24px]">
                      {data?.languages &&
                        JSON.parse(data?.languages)
                          ?.map((languages, index) => languages?.name)
                          ?.join(", ")}
                    </p>
                  </div>
                )}
                {/* skills */}
                {data?.skills && (
                  <div className="mb-[24px]">
                    <p className="text-[14px] font-[500] text-[#535862] mb-[8px]">
                      Skills
                    </p>
                    <div className="flex flex-wrap">
                      {data?.skills &&
                        JSON.parse(data?.skills).map((tag, index) => (
                          <p
                            key={index}
                            className="text-[#344054] border border-[#D0D5DD] rounded-[6px] font-[500] font-inter text-[12px] px-[6px] py-[2px] m-[2px] mb-[8px]"
                          >
                            {tag}
                          </p>
                        ))}
                    </div>
                  </div>
                )}
                {/* badge */}
                {data?.open_to_work || data?.is_premium ? (
                  <div className="mb-[24px]">
                    <p className="text-[14px] font-[500] text-[#535862] mb-[8px]">
                      Badges
                    </p>
                    {data?.is_premium && (
                      <div className="flex items-center mb-[8px]">
                        <img
                          src={premiumLinkedin}
                          alt="premiumLinkedin"
                          className="h-[16px] w-[16px] mr-[8px] min-w-[16px]"
                        />
                        <p className="text-[#414651] font-[500] text-[16px]">
                          Premium Member
                        </p>
                      </div>
                    )}
                    {data?.open_to_work && (
                      <div className="flex items-center mb-[8px]">
                        <img
                          src={successLinkedin}
                          alt="successLinkedin"
                          className="h-[16px] w-[16px] mr-[8px] min-w-[16px]"
                        />
                        <p className="text-[#414651] font-[500] text-[16px]">
                          Open to Work
                        </p>
                      </div>
                    )}
                  </div>
                ) : null}

                {historyData?.length > 0 && (
                  <div className="relative">
                    <p className="text-[14px] font-[500] text-[#535862] mb-4">
                      Pipeline History
                    </p>

                    {historyData?.map((step, index) => (
                      <div key={index} className="flex items-start">
                        <div className="relative">
                          <div
                            className={`relative rounded-full h-[27px] w-[27px]`}
                          >
                            {step?.sequence_name === "Invite Accepted" ? (
                              <img
                                src={acceptIcon}
                                alt="acceptIcon"
                                className="w-[27px] h-[27px] opacity-100"
                              />
                            ) : (
                              <img
                                src={emptyStep}
                                alt="emptyStep"
                                className="w-[27px] h-[27px] opacity-100"
                              />
                            )}
                          </div>
                          {/* Vertical line between steps */}
                          {index < historyData.length - 1 && (
                            <div className="relative flex items-center top-[5px] left-[49%] w-[1.5px] h-[43.7px]">
                              <div className="absolute top-[1px] -left-[1px] bg-[#E4E7EC] w-[2px] h-full top origin-top"></div>
                            </div>
                          )}
                        </div>
                        <div className="ml-[13px] mb-[32px]">
                          {step?.sequence_name && (
                            <p className="px-[6px] py-[2px] border border-[#D0D5DD] text-[11.37px] text-[#344054] font-[500]  rounded-[6px] font-inter inline-flex items-center">
                              {renderHistoryStatus(step?.sequence_name)}
                            </p>
                          )}
                          <p
                            className={`text-[12.33px] text-[#475467] leading-[18.5px] pt-2`}
                          >
                            {step?.action_timestamp
                              ? moment(step?.action_timestamp).format(
                                  "MM/DD/YY [at] hh:mm A"
                                )
                              : ""}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {/* right side content */}
              <div className="w-full">
                <div className="w-[85%] mb-[31px]">
                  <h5 className="text-[16px] font-[500] text-[#181D27] leading-[24px] mb-[8px]">
                    About me
                  </h5>
                  <p className="text-[16px] font-[400] text-[#535862] leading-[24px]">
                    {data?.summary}
                  </p>
                </div>
                <div className="mb-[31px]">
                  <h5 className="text-[16px] font-[500] text-[#181D27] leading-[24px] mb-[18px]">
                    Work History
                  </h5>
                  {data?.work_history && (
                    <>
                      {data?.work_history
                        ? JSON.parse(data?.work_history)?.map(
                            (work, index, arr) => {
                              return (
                                <div
                                  className={`mb-[32px] border border-[#E9EAEB] p-[20px] rounded-[12px]`}
                                >
                                  <div
                                    key={index}
                                    className="w-full flex items-center"
                                  >
                                    <img
                                      onError={handleErrorImage}
                                      src={
                                        work?.company?.logo ||
                                        companyLogo?.src ||
                                        companyLogo
                                      }
                                      alt="CompanyLogoMark"
                                      className="w-[48px] h-[48px] ml-[-2px] rounded-lg"
                                    />
                                    <div className="ml-[12px]">
                                      <div>
                                        <p className="text-[#181D27] font-[600] text-[16px] m-0 leading-[24px]">
                                          {work?.company?.name}
                                        </p>
                                      </div>
                                      <div>
                                        <p className="text-[#535862] font-[400] text-[14px]">
                                          @ {work?.profile_positions[0]?.title}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <p className="text-[#535862] font-[400] text-[14px] mt-[10px]">
                                    {moment({
                                      year: work?.date?.start?.year,
                                      month:
                                        (work?.date?.start?.month || 1) - 1,
                                      day: work?.date?.start?.day || 1,
                                    }).format("LL")}
                                    {work?.profile_positions[0]
                                      .employment_type && (
                                      <span className="pl-[5px] text-[#aeaeae]">
                                        (
                                        {
                                          work?.profile_positions[0]
                                            .employment_type
                                        }
                                        )
                                      </span>
                                    )}
                                  </p>
                                  {work?.profile_positions[0].description && (
                                    <p className="mt-[10px] text-[14px] text-[#535862] leading-[20px] font-[400]">
                                      {work?.profile_positions[0]?.description}
                                    </p>
                                  )}
                                </div>
                              );
                            }
                          )
                        : []}
                    </>
                  )}
                </div>
                {/* education */}
                <h5 className="text-[16px] font-[500] text-[#181D27] leading-[24px] mb-[14px]">
                  Education
                </h5>
                {data?.education && (
                  <>
                    {data?.education &&
                      JSON.parse(data?.education)?.map((edu, index) => {
                        return (
                          <div
                            key={index}
                            className={`mb-[32px] border border-[#E9EAEB] p-[20px] rounded-[12px]`}
                          >
                            <div className="w-full flex justify-start items-center">
                              <img
                                onError={handleErrorImage}
                                src={edu?.school?.logo || companyLogo}
                                alt="CompanyLogoMark"
                                className={`w-[48px] h-[48px] mt-[4px] rounded-lg`}
                              />
                              <div className="ml-[12px]">
                                <div>
                                  <p className="text-[14px] font-[400] text-[#181D27] leading-[24px] m-0">
                                    {edu?.school?.name}
                                  </p>
                                </div>
                                <div>
                                  <p className="text-[14px] font-[400] text-[#535862] leading-[24px] m-0">
                                    {edu?.field_of_study}
                                  </p>
                                </div>
                              </div>
                            </div>
                            {edu?.date?.start?.year && edu?.date?.end?.year ? (
                              <p className="text-[14px] font-[400] mt-[10px] text-[#535862] leading-[24px]">
                                {moment({
                                  year: edu?.date?.start?.year,
                                  month: edu?.date?.start?.month
                                    ? edu?.date?.start?.month - 1
                                    : 0,
                                  day: edu?.date?.start?.day || 1,
                                }).format(
                                  edu?.date?.start?.month
                                    ? edu?.date?.start?.day
                                      ? "LL"
                                      : "MMMM YYYY"
                                    : "YYYY"
                                )}{" "}
                                -{" "}
                                {moment({
                                  year: edu?.date?.end?.year,
                                  month: edu?.date?.end?.month
                                    ? edu?.date?.end?.month - 1
                                    : 0,
                                  day: edu?.date?.end?.day || 1,
                                }).format(
                                  edu?.date?.end?.month
                                    ? edu?.date?.end?.day
                                      ? "LL"
                                      : "MMMM YYYY"
                                    : "YYYY"
                                )}
                              </p>
                            ) : null}
                          </div>
                        );
                      })}
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ReviewSidePanel;
