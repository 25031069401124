import { useEffect, useState } from "react";
import connectIcon from "assets/images/connect-rb2g.svg";
import Button from "components/button";
import CommonCheckbox from "components/commonCheckbox";
import { toast } from "react-toastify";
import celebrateIcon from "assets/images/celebrated-Icon.png";
import {
  useGetCollaborators,
  useShareProspectList,
} from "services/prospecting-api";
import { useAuthStore, useSettingStore } from "stores";
import {
  getNameInititals,
  getRandomProfileLogo,
  getRandomProfileLogoWithRandom,
} from "utils/index";
import WorkspaceLogo from "assets/images/workspace.svg";
import teamImg from "assets/images/activity-avatar3.svg";
import Loader from "components/loader";

const RB2BAddTeamMemberModal = ({
  isOpen,
  onClose,
  switchToggle,
  prospectId,
}) => {
  const { userData } = useSettingStore();
  const { isAdmin } = useAuthStore();
  const { userDetails } = useAuthStore();

  const shareProspectList = useShareProspectList();
  const getCollaborators = useGetCollaborators();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedCheckboxIds, setSelectedCheckboxIds] = useState([]);
  const [teamMembersData, setTeamMembersData] = useState([]);
  const [adminIds, setAdminIds] = useState([]);
  const [loginUserId, setLoginUserId] = useState(null);
  const [profileImages, setProfileImages] = useState([]);

  const isUsers =
    teamMembersData?.filter((item) => item?.role === "team_members")?.length >
    0;

  const allTeamMembersSelected = teamMembersData
    ?.filter((i) => (!isUsers ? i?.company : !i?.company))
    .every((member) => selectedCheckboxIds?.includes(member.user_id));

  const handleGetCollaborators = async () => {
    try {
      const resolve = await getCollaborators.mutateAsync();
      const combinedTeam = [
        ...resolve?.admin,
        {
          ...userData?.company,
          company: true,
          name: resolve?.admin?.[0]?.workspace || "",
        },
        ...resolve?.team_members?.filter((item) => item?.user_id),
      ];
      const currentUser = resolve?.team_members?.find(
        (item) => item?.user_id === userData?.user_id
      );
      const fetchedAdminIds =
        (isAdmin
          ? resolve?.admin?.map((admin) => admin.user_id)
          : [currentUser?.user_id]) || [];
      setAdminIds(fetchedAdminIds); // Store admin IDs
      if (!isAdmin) {
        setLoginUserId(...fetchedAdminIds);
      }
      const updatedSelectedCheckboxIds = [
        ...selectedCheckboxIds,
        ...fetchedAdminIds,
      ];

      const sortedData = [
        {
          ...resolve?.team_members?.find(
            (item) => item?.user_id === userData?.user_id
          ),
          isLoginUser: true,
        },
        {
          ...userData?.company,
          company: true,
          user_id: "company_id",
          name: resolve?.admin?.[0]?.workspace || "",
        },
        ...resolve?.admin,
        ...resolve?.team_members
          ?.filter((item) => item?.user_id !== userData?.user_id)
          .filter((item) => item?.user_id),
      ];

      const filterData = isAdmin ? combinedTeam : sortedData;
      setSelectedCheckboxIds(updatedSelectedCheckboxIds);
      setTeamMembersData(filterData);
    } catch (error) {
      console.log(error);
    }
  };

  // Handle checkbox change
  const handleCheckboxChange = (userId, isCompany) => {
    if (isCompany) {
      const isCompanySelected = selectedCheckboxIds.includes(userId);
      if (isCompanySelected) {
        // If the company checkbox is already selected, unselect all team members and remove company ID
        if (isAdmin) {
          setSelectedCheckboxIds([...adminIds]);
        } else if (loginUserId) {
          setSelectedCheckboxIds([loginUserId]);
        } else {
          setSelectedCheckboxIds([]);
        }
      } else {
        // If the company checkbox is selected, select all team members (excluding admins)
        const newSelection = teamMembersData
          .filter((item) => !item.company && !adminIds.includes(item.user_id))
          .map((item) => item.user_id);
        if (isAdmin) {
          setSelectedCheckboxIds([...newSelection, userId, ...adminIds]); // Add company ID and admin ID
        } else if (loginUserId) {
          setSelectedCheckboxIds([...newSelection, userId, loginUserId]); // Add company ID and admin ID
        } else {
          setSelectedCheckboxIds([...newSelection, userId]); // Add company ID
        }
      }
    } else {
      // For individual user checkboxes
      let newSelection;

      if (selectedCheckboxIds.includes(userId)) {
        // If user is already selected, unselect them
        newSelection = selectedCheckboxIds.filter((id) => id !== userId);
      } else {
        // Otherwise, add them to the selection
        newSelection = [...selectedCheckboxIds, userId];
      }

      // Check if all team members (except company and admins) are selected
      const allMembersSelected = teamMembersData
        .filter((item) => !item.company && !adminIds.includes(item.user_id))
        .every((member) => newSelection.includes(member.user_id));

      if (allMembersSelected) {
        // If all individual team members are selected, include the company ID
        newSelection.push("company_id"); // Replace with your actual company ID
      } else {
        // If any individual member is unselected, remove the company ID
        newSelection = newSelection.filter((id) => id !== "company_id"); // Replace with your actual company ID
      }

      setSelectedCheckboxIds(newSelection);
    }
  };

  const handleShareList = async () => {
    try {
      await shareProspectList.mutateAsync({
        prospect_id: prospectId,
        shared_user_ids: selectedCheckboxIds
          ?.filter((id) => !adminIds.includes(id))
          ?.filter(Boolean)
          ?.filter((item) => item !== "company_id"),
      });
      onClose();
      toast.success("RB2B Configured", {
        icon: () => <img src={celebrateIcon} alt="celebrate" />,
      });
      switchToggle();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetCollaborators();
  }, []);

  useEffect(() => {
    if (isOpen) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [isOpen]);

  useEffect(() => {
    const images = teamMembersData.map((item, index) => {
      if (item?.role === "admin") {
        return index === 0
          ? userData?.user?.profile_pic ||
              getRandomProfileLogoWithRandom(index)?.src
          : getRandomProfileLogo()?.src;
      } else if (item?.company) {
        return WorkspaceLogo;
      } else {
        return null; // or some default image
      }
    });
    setProfileImages(images);
  }, [teamMembersData, userData]);

  if (!isOpen && !isModalVisible) return null;

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[400ms] ease-in-out ${
        isModalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className="fixed inset-0 bg-black opacity-10"
        onClick={onClose}
      ></div>
      <div
        className={`bg-white pt-[24px] rounded-lg shadow-lg z-10 w-full max-w-[400px] relative transition-all ease-in-out duration-[150ms] ${
          isModalVisible ? "scale-100" : "scale-95"
        }`}
      >
        <img src={connectIcon} alt="connect rb2b" className="mx-auto" />
        <div className="modal-scroll px-[24px] pb-[20px]">
          <div className="mt-[16px] px-[10px]">
            <h4 className="font-[600] text-[18px] text-[#101828] text-center mb-[4px] font-inter">
              Add your team members
            </h4>
            <p className="font-normal text-[14px] text-[#475467] text-center font-inter mb-[29px]">
              Invite others to collaborate on this RB2B lead list.
            </p>
          </div>
          <div className="max-h-[320px] modal-scroll overflow-auto w-full">
            {teamMembersData?.length > 0 ? (
              teamMembersData?.map((item, index) => {
                return (
                  <div
                    key={item?.user_id}
                    className="flex items-center w-full mb-[12px]"
                  >
                    <div
                      className={`w-[16px] h-[16px] team-checkbox mr-[11px] ${
                        (item?.role === "admin" && isAdmin) || item?.isLoginUser
                          ? "team-fade-checkbox"
                          : ""
                      }`}
                    >
                      <CommonCheckbox
                        checked={
                          item?.isLoginUser
                            ? true
                            : item?.company
                            ? allTeamMembersSelected
                            : (item?.role === "admin" && isAdmin) ||
                              selectedCheckboxIds.includes(item?.user_id)
                        }
                        onChange={() =>
                          handleCheckboxChange(item?.user_id, item?.company)
                        }
                        disabled={
                          (item?.role === "admin" && isAdmin) ||
                          item?.isLoginUser
                        }
                      />
                    </div>
                    <div className="flex items-center justify-between w-full pr-2">
                      <div className="flex items-center w-[80%]">
                        {item?.role === "admin" || item?.company ? (
                          <img
                            src={
                              item?.icons
                                ? item?.icon
                                : item?.role === "admin"
                                ? index === 0
                                  ? userData?.user?.profile_pic ||
                                    userDetails?.profile_pic ||
                                    getRandomProfileLogoWithRandom(index)?.src
                                  : profileImages[index]
                                : item?.company
                                ? WorkspaceLogo
                                : teamImg
                            }
                            alt="team member icon"
                            className={`w-[40px] min-w-[40px] h-[40px] mr-[12px] object-center  ${
                              !item?.company && "rounded-full"
                            } `}
                          />
                        ) : (
                          <div className="w-[40px] min-w-[40px] h-[40px] rounded-full flex items-center justify-center text-[14px] font-[700] text-[#4409B9] bg-[#EEEDFD] mr-[12px]">
                            {getNameInititals(item?.name || item?.email)}
                          </div>
                        )}

                        <div>
                          <h6
                            className={`font-inter leading-[20px] text-[#475467] m-0 ${
                              (item?.role === "admin" && isAdmin) ||
                              item?.isLoginUser
                                ? "text-[#AAA8AF]"
                                : ""
                            } ${
                              item?.company_name
                                ? "font-[400] text-[13px]"
                                : "font-[600] text-[14px]"
                            } ${item?.company && "-mt-[5px]"}`}
                          >
                            {item?.company_name || item?.name || item?.email}
                          </h6>

                          <p
                            className={`font-inter font-normal text-[14px] leading-[20px] text-[#475467] m-0 ${
                              (item?.role === "admin" && isAdmin) ||
                              item?.isLoginUser
                                ? "text-[#AAA8AF]"
                                : ""
                            }`}
                          >
                            {item?.company && item?.name
                              ? "Your Workspace"
                              : item?.email}
                          </p>
                        </div>
                      </div>
                      {!item?.company && (
                        <p
                          className={`w-[20%] text-right font-inter font-[500] text-[12px] leading-[18px] ${
                            selectedCheckboxIds.includes(item?.user_id)
                              ? "text-[#475467]"
                              : "text-[#475467]"
                          }`}
                        >
                          {item?.isLoginUser
                            ? "You"
                            : selectedCheckboxIds.includes(item?.user_id) &&
                              item?.role !== "admin"
                            ? "Editor"
                            : item?.role === "admin"
                            ? index === 0
                              ? isAdmin
                                ? "You (Admin)"
                                : "Admin"
                              : "Admin"
                            : ""}
                        </p>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="w-full h-[200px] flex items-center justify-center">
                <Loader />
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center justify-center border-t-[1.5px] p-[24px] border-[#E4E7EC]">
          <Button
            title={
              <div className="flex items-center justify-center">
                {shareProspectList?.isPending ? (
                  <div className="w-[20px] h-[20px] border-[3px] border-t-[3px] border-[#fff] border-solid rounded-full animate-spin border-t-[#8A43FF]" />
                ) : (
                  "Done"
                )}
              </div>
            }
            secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9]"
            className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow"
            isRounded="6px"
            onClick={() => {
              handleShareList();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default RB2BAddTeamMemberModal;
