import searchIcon from "assets/images/viaLinkedInSearch.svg";
import Button from "components/button";
import { AppContext } from "context/AppContext";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSearchViaLinkedInPost } from "services/prospecting-api";

const ViaProfilePicture = ({
  setStep,
  onClose,
  setTotalDataCsv,
  profileURL,
  setProfileURL,
  setEnrichType,
}) => {
  const enrichViaProfileUrl = useSearchViaLinkedInPost();

  const habndleEnrichCopyPasteLeads = async () => {
    try {
      const payload = {
        post_url: profileURL,
      };
      const response = await enrichViaProfileUrl?.mutateAsync(payload);
      setTotalDataCsv(response?.total_count);
      setEnrichType("Peoples");
      setStep("enrich-leads-step");
    } catch (error) {
      if (error?.data?.detail === "Not a valid post url"){
        setStep("failedViaLinkedin");
      }else{
        toast.success(error?.data?.detail);
      }
    }
  };
  return (
    <>
      <div className="tems-center justify-center font-inter">
        <div className="modal-scroll">
          <div className="modal-scroll">
            <div className="flex flex-col items-start justify-start font-inter">
              <div className="flex items-center justify-center mb-[16px]">
                <img
                  src={searchIcon}
                  alt="info img"
                  className="h-[48px] w-[48px]"
                />
              </div>
              <h4 className="font-[600] text-[18px] text-[#101828] m-[4px] mb-0 ml-0 font-inter">
                Enter a LinkedIn Post URL{" "}
              </h4>
              <p className="text-[14px] text-[#475467] font-inter mt-[9px]">
                Copy the URL from your browser for post to get profiles who
                reacted to the post.
              </p>
              <p className="text-[14px] text-[#475467] font-inter mt-[20px]">
                LinkedIn Post URL{" "}
                <sup className="text-[#7F56D9] font-[500] text-[14px] bottom-4 top-[-2px]">
                  *
                </sup>
              </p>
              <textarea
                placeholder="e.g https://www.linkedin.com/posts/sudarshan-s..."
                value={profileURL}
                onChange={(e) => setProfileURL(e?.target?.value)}
                className="focus:border-[2px] border-[#D0D5DD] resize-none rounded-md focus:border-[#9E77ED] w-full max-h-[100px] h-[100px] mt-[8px] text-[#181D27] placeholder:text-[#B1BACB] placeholder:text-[14px] text-[16px] modal-scroll"
              ></textarea>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center mt-[32px]">
          <Button
            title="Back"
            isWhiteBtn={true}
            isRounded="6px"
            onClick={() => {
              setProfileURL("");
              setStep("import-options");
            }}
            className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow !text-[#344054]"
          />
          {profileURL?.length !== 0 && (
            <Button
              title={
                enrichViaProfileUrl?.isPending ? (
                  <div className="flex items-center w-full justify-center">
                    <div className="w-[20px] h-[20px] border-[3px] border-t-[3px] text-center border-[#fff] border-solid rounded-full animate-spin border-t-[#8A43FF]" />
                  </div>
                ) : (
                  "Next"
                )
              }
              secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9] "
              className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow !ml-[12px]"
              isRounded="6px"
              onClick={habndleEnrichCopyPasteLeads}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ViaProfilePicture;
