import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthCheck } from "services/auth-api";
import { setPaymentVerify } from "utils/token-service";
import Input from "components/textInput";
import AnimatedButton from "components/animatedButton";
import RocketIcon from "assets/images/white-rocket.png";

const CreateWorkSpace = ({
  setLoader,
  setIsLaunchButtonClicked,
  handleGetLinkedInAccountDetails,
  setStep,
  setCompanyNameError,
  companyName,
  companyNameError,
  setCompanyName,
}) => {
  const authCheckMutation = useAuthCheck();
  const parentRef = useRef(null);
  const navigate = useNavigate();

  const handleAuthCheck = async () => {
    try {
      const navigationCheck = await authCheckMutation.mutateAsync({});
      if (navigationCheck?.company_setup) {
        handleGetLinkedInAccountDetails();
        setIsLaunchButtonClicked(false);
        setPaymentVerify(true);
        setTimeout(() => {
          setLoader(false);
          navigate("/dashboard");
        }, 1000);
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  useEffect(() => {
    handleAuthCheck();
  }, []);

  return (
    <div
      className="font-inter !h-full  flex flex-col justify-center items-center  m-auto max-[767px]:h-auto  max-[767px]:p-[20px]  max-[767px]:pt-[50px] "
      data-aos="fade-in"
      data-aos-duration="2000"
    >
      <div className="!w-[425px] relative signin-card">
        <div className="mx-auto w-[60px] h-[60px] flex items-center justify-center rounded-xl border border-[#333741] bg-[#0C111D]">
          <img
            src={RocketIcon}
            alt="Logo"
            className="h-[36px] w-[36px] mx-auto block"
            style={{
              filter: "brightness(1.2)",
            }}
          />
        </div>
        <h1 className="text-[30px] text-center text-[#F5F5F6] font-[600] mt-[20px] mb-[1px]">
          Create your Workspace
        </h1>

        <div className="m-auto mt-[31px] w-[340px]">
          <div className="w-full mb-[32px]" ref={parentRef}>
            <p className="text-[13px] font-[500] text-[#F6F6F6] mb-[6px] ">
              Company Name
            </p>
            <Input
              isSigninInput
              type="text"
              placeholder={"Enter your company name"}
              className="w-full h-[40px] rounded-[7.5px] border border-[#D0D5DD] !mb-[4px] !bg-[#0d0515] text-[#9e9ba0]"
              value={companyName}
              onChange={(e) => {
                setCompanyName(e.target.value);
                setCompanyNameError("");
              }}
            />
            <p className="text-red-500 text-xs">
              {companyNameError ? "Company Name is Required." : ""}
            </p>
          </div>
          <AnimatedButton
            title="Confirm"
            className="!text-[15px] !font-[600] !h-[42px] !rounded-[7px] !bg-[#635BFF]"
            fontSize="!text-[15px]"
            onClick={() => {
              if (companyName?.trim()?.length === 0) {
                setCompanyNameError(true);
              } else {
                setStep(8);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateWorkSpace;
