import Phone from "assets/images/otp-verification-heart-icon.svg";
import { IoClose } from "react-icons/io5";
import OTPInput from "otp-input-react";
import Button from "components/button";
import LetterEmoji from "assets/images/loveletter.png";
import { toast } from "react-toastify";
import { useGetResendVerifyCode } from "services/settings-api";
import { useEffect, useState } from "react";

const UpdateSettingsOTPConfirmationModal = ({
  OTP,
  setOTP,
  onClose,
  isModalVisible,
  handleVerifyEmail,
  isLoading,
  emailUpdateId,
}) => {
  const resendCodeMutation = useGetResendVerifyCode();
  const [timer, setTimer] = useState(30);
  const [canResend, setCanResend] = useState(false);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else {
      setCanResend(true);
    }

    return () => clearInterval(interval);
  }, [timer]);

  const handleEmailResend = async () => {
    if (canResend) {
      setCanResend(false);
      setTimer(30);
      try {
        const response = await resendCodeMutation.mutateAsync({
          email_update_id: emailUpdateId,
        });
        if (response?.status === 200) {
          toast.success("Code Re-sent", {
            icon: () => (
              <img
                src={LetterEmoji}
                alt="name"
                className="!w-[46px] !h-[16px]"
              />
            ),
          });
        } else {
          toast.error("Code Re-send Failed", {
            icon: () => (
              <img
                src={LetterEmoji}
                alt="name"
                className="!w-[46px] !h-[16px]"
              />
            ),
          });
        }
      } catch (error) {
        toast.error("Code Re-send Failed", {
          icon: () => (
            <img src={LetterEmoji} alt="name" className="!w-[46px] !h-[16px]" />
          ),
        });
      }
    }
  };

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[300ms] ease-in-out ${
        isModalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className="fixed inset-0 bg-black opacity-10"
        onClick={onClose}
      ></div>
      <div
        className={`p-[24px] bg-white rounded-lg shadow-lg z-10 w-full max-w-[556px] relative transition-all ease-in-out duration-[150ms] ${
          isModalVisible ? "scale-100" : "scale-95"
        }`}
      >
        <IoClose
          className="text-[#667085] text-[20px] w-[24px] h-[24px] absolute right-[24px] top-[27px] cursor-pointer"
          onClick={onClose}
        />
        <div className="modal-scroll">
          <>
            <div className="w-[48px] h-[48px] rounded-[13.9px] flex items-center justify-center border border-[#E4E7EC]">
              <img
                src={Phone}
                alt="Logo"
                className="h-[24px] w-[24px] block"
                style={{
                  filter: "brightness(1.2)",
                }}
              />
            </div>
            <h1 className="text-[18.14px] text-[#101828] font-[600] mt-[22px] mb-[5px] font-inter leading-[28.21px]">
              Enter the Code
            </h1>
            <p className="text-[14px] font-[400] text-[#475467] leading-[24px]">
              Enter the code sent to your email from Pipeline.{" "}
            </p>
            <form
              className="mt-[22px] w-full"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="mb-[16px] flex items-center justify-center mr-[-20px]">
                <OTPInput
                  value={OTP}
                  onChange={setOTP}
                  autoFocus
                  OTPLength={6}
                  otpType="number"
                  inputClassName="otpInput custom-shadow !h-[56px] !px-[2px] !w-[40px] text-[28px] border border-[#E4E7EC] rounded-[6px] bg-transparent text-[#8A43FF] !outline-none focus:!border-[#8A43FF] focus:!border-[2px]"
                />
              </div>
              <p className="text-[14px] m-0 font-[400] text-center text-[#475467] leading-[24px]">
                Didn’t receive the code?
              </p>
              {canResend ? (
                <p
                  className="mt-[11.12px] text-[#6941C6] font-[600] text-[14px] text-center cursor-pointer"
                  onClick={() => handleEmailResend()}
                >
                  Click to resend
                </p>
              ) : (
                <p className="text-center font-[400] text-[14px] text-[#94969C]">
                  <span
                    className="font-[600] text-[13px] text-[#CECFD2] ml-[4px]"
                    onClick={handleEmailResend}
                  >
                    {`Please Wait ${timer}s to resend verification code`}
                  </span>
                </p>
              )}
              <div className="flex items-center justify-center mt-[20.85px] w-full">
                {OTP?.length >= 6 ? (
                  <div className="w-full flex ">
                    <Button
                      isWhiteBtn={true}
                      title="Cancel"
                      secondaryColor=""
                      className="!text-[16px] text-[#344054] !h-[44px] w-full !font-[600] y-axis-shadow !rounded-[8px]"
                      onClick={() => {
                        onClose();
                      }}
                    />
                    <Button
                      title={
                        isLoading ? (
                          <div className="w-[18px] h-[18px] border-[3px] mx-auto border-t-[3px] border-[#8A43FF] border-solid rounded-full animate-spin border-t-[#fff]" />
                        ) : (
                          "Continue"
                        )
                      }
                      secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9] select-none"
                      className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow !rounded-[8px] !ml-[12px]"
                      onClick={() => {
                        handleVerifyEmail();
                      }}
                    />
                  </div>
                ) : (
                  <Button
                    isWhiteBtn={true}
                    title="Cancel"
                    secondaryColor=""
                    className="!text-[16px] text-[#344054] !h-[44px] w-full !font-[600] y-axis-shadow !rounded-[8px]"
                    onClick={() => {
                      onClose();
                    }}
                  />
                )}
              </div>
            </form>
          </>
        </div>
      </div>
    </div>
  );
};

export default UpdateSettingsOTPConfirmationModal;
