import { IoClose } from "react-icons/io5";
import Button from "components/button";
import rb2bLogo from "assets/images/RB2B.png";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import thumbIcon from "assets/images/thumb.svg";
import { useRb2bUnInstall } from "services/rb2b-api";
import Webhooks from "assets/images/webhooks.svg";
import Delete from "assets/images/delete-webhook.svg"

const DeleteWebhook = ({
  isOpen,
  onClose,
  handleDisconnectWebhook,
  isPending,
}) => {
  const Rb2bUnInstall = useRb2bUnInstall();
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [isOpen]);

  if (!isOpen && !isModalVisible) return null;

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[300ms] ease-in-out ${
        isModalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className="fixed inset-0 bg-black opacity-10"
        onClick={onClose}
      ></div>
      <div
        className={`bg-white p-[24px] rounded-lg modal-shadow z-10 w-full max-w-[400px] relative transition-all ease-in-out duration-[150ms] ${
          isModalVisible ? "scale-100" : "scale-95"
        }`}
      >
        {" "}
        <div className="flex items-start justify-start mb-[16px]">
          <img src={Delete} alt="info img" className="h-[48px] w-[48px]" />
        </div>
        <IoClose
          className="text-[#667085] text-[20px] w-[24px] h-[24px] absolute right-[24px] top-[26px] cursor-pointer"
          onClick={onClose}
        />
        <div className="modal-scroll">
          <div className="flex flex-col items-start justify-center mb-[20px] font-inter">
            <h4 className="font-[600] text-[18px] text-[#101828] mb-[4px] font-inter">
              Delete Webhook{" "}
            </h4>
            <p className="font-normal text-[14px] text-[#475467] font-inter mb-[16px] text-start">
              Are you sure you want to delete this Webhook?
            </p>
            <p className="font-[700] text-[14px] text-[#475467] font-inter mb-[8px] text-start">
              This action cannot be undone and the lead list will no longer
              update.{" "}
            </p>
          </div>
          <div className="flex items-center justify-center mt-[20px]">
            <Button
              title={
                isPending ? (
                  <div className="w-[20px] h-[20px] border-[3px] mx-auto border-t-[3px] border-[#fff] border-solid rounded-full animate-spin border-t-[#D92D20]" />
                ) : (
                  "Delete"
                )
              }
              secondaryColor="!border-[#D92D20] !bg-[#D92D20] hover:!bg-transparent hover:!text-[#D92D20]"
              className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow !rounded-[8px]"
              onClick={handleDisconnectWebhook}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteWebhook;
