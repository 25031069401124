import { useMutation, useQuery } from "@tanstack/react-query";
import axiosInt from "services";

const useWebhookInstall = () =>
  useMutation({
    mutationFn: async () => {
      const response = await axiosInt?.post(`/integration/install`, {
        platform: "webhook",
      });
      return response;
    },
  });

const useGetWebhookConfig = () =>
  useMutation({
    mutationFn: async () => {
      const response = await axiosInt?.get(
        `/integration/config?platform=webhook`
      );
      return response;
    },
  });

const useEnrichWebhookLeads = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt?.post(
        `/prospect/import/webhook_enrich_leads`,
        data
      );
    },
  });

const useDeleteWebhookIntegration = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt?.delete(`/integration/uninstall`, {
        data,
      });
      return response;
    },
  });

export {
  useWebhookInstall,
  useGetWebhookConfig,
  useDeleteWebhookIntegration,
  useEnrichWebhookLeads,
};
