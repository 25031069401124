import Loader from "components/loader";

const OutlinedInput = (props) => {
  const {
    type,
    onChange,
    className,
    placeholder,
    value,
    readOnly = false,
    onKeyDown = () => null,
    disabled = false,
    disabledInput,
    isLoading = false,
  } = props;
  return (
    <>
      {isLoading ? (
        <div className="w-full h-11 flex items-center justify-center border rounded-[8px]">
          <Loader />
        </div>
      ) : (
        <input
          type={type}
          value={value}
          readOnly={readOnly}
          className={`common-input font-inter border-[1px] !border-[#D0D5DD] text-[16px] font-normal text-[#1C1C1C] placeholder:text-[#667085] outline-none rounded-[8px] py-[9px] px-[14px] w-full y-axis-shadow
          ${className}`}
          onChange={onChange}
          placeholder={placeholder}
          onKeyDown={onKeyDown}
          disabled={disabled || disabledInput}
        />
      )}
    </>
  );
};

export default OutlinedInput;
