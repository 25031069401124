import { useState, useRef, useEffect } from "react";
import threeDots from "assets/images/dots-horizontal.svg";
import emoji from "assets/images/smile-face-Icon.svg";
import tick from "assets/images/Verified tick.svg";
import noMessages from "assets/images/no-messages.png";
import linkedIn from "assets/images/linkedin-inbox.svg";
import Resend from "assets/images/resend.svg";
import liked from "assets/images/message-like.svg"

const Messages = ({ selectedUser }) => {
  const messagesContainerRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");

  const handleSendMessage = () => {
    if (newMessage.trim() === "") return;
    if (newMessage.trim() === "This is Failed Message") {
      setMessages([
        {
          id: Date.now(),
          sender: "You",
          text: newMessage,
          time: "Just now",
          isSender: true,
          isFailed: true,
        },
        ...messages,
      ]);
    } else {
      setMessages([
        {
          id: Date.now(),
          sender: "You",
          text: newMessage,
          time: "Just now",
          isSender: true,
        },
        ...messages,
      ]);
    }

    setNewMessage("");
  };

  const handleResend = (resendMessageId) => {
    const updated = [...messages]?.map((msg) => {
      if (msg?.id === resendMessageId) {
        return {
          ...msg,
          isFailed: false,
        };
      }
      return msg;
    });
    setMessages(updated);
  };

  useEffect(() => {
    setMessages(selectedUser?.messages);
  }, [selectedUser]);

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [messages, selectedUser]);

  return (
    <div className="w-[70%]">
      {selectedUser ? (
        <>
          <div className="h-[84px] w-full border-b border-[#E9EAEB] flex px-6 py-3">
            <div className="flex w-full">
              <div className="relative">
                <img
                  src={selectedUser?.avatar}
                  alt="import"
                  className="mr-[6px] w-[56px] h-[56px]"
                />
                <img
                  src={tick}
                  alt="import"
                  className="w-[18px] h-[18px] absolute right-2 bottom-1.5"
                />
              </div>
              <div>
                <h1 className="text-lg font-semibold text-[#181D27]">
                  {selectedUser?.name}
                </h1>
                <p className="text-sm text-[#535862]">
                  {selectedUser?.position} at{" "}
                  <span className="font-bold">REFIT Inc</span>.
                </p>
              </div>
              <div className="ml-auto flex items-center">
                <button
                  className={`h-10 text-sm font-semibold text-[#6941C6] border border-[#D6BBFB] text-center px-[14px] rounded-md`}
                >
                  Lead History
                </button>
                <div className="h-10 w-10 flex items-center justify-center rounded-md border border-[#D6BBFB] ml-3">
                  <img
                    src={linkedIn}
                    alt="import"
                    className="w-[19px] h-[19px]"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="h-[calc(100vh_-130px)] px-6 pb-4">
            <div className="flex justify-center items-center">
              <div className="w-full">
                <div
                  className="py-4 h-[calc(100vh_-260px)] overflow-y-auto overflow-x-hidden flex flex-col-reverse modal-scroll pr-2"
                  ref={messagesContainerRef}
                >
                  {messages?.length ? (
                    messages?.map((message) => (
                      <div key={message.id} className="">
                        <div
                          className={`mb-4 ${
                            message.isSender ? "text-right" : "text-left"
                          }`}
                        >
                          <div
                            className={`inline-block max-w-[60%] break-words ${
                              !message.isSender ? "flex items-start" : ""
                            }`}
                          >
                            {!message.isSender && (
                              <>
                                <img
                                  src={selectedUser?.avatar}
                                  alt="import"
                                  className="mr-[6px] w-[40px] h-[40px]"
                                />
                                <div>
                                  <div className="flex mb-1">
                                    <p className="text-[#414651] text-sm font-medium">
                                      Katherine Moss
                                    </p>
                                    <div className="text-xs ml-auto text-[#535862] font-normal mt-1">
                                      {message.time}
                                    </div>
                                  </div>
                                  <div className="relative">
                                    <p
                                      className="inline-block p-3 rounded-lg border bg-[#FAFAFA] text-gray-800 rounded-tl-none border-[#E9EAEB]"
                                      style={{
                                        wordBreak: "break-word",
                                        overflowWrap: "break-word",
                                      }}
                                    >
                                      {message.text}
                                    </p>
                                    {message?.isLiked && (
                                      <img
                                        src={liked}
                                        alt="isLiked"
                                        className="absolute bottom-[-10px] right-[4px]"
                                      />
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                            {message.isSender && (
                              <div className="flex items-start">
                                <p
                                  className={`inline-block p-3 rounded-lg border text-left ${
                                    message?.isFailed
                                      ? "bg-[#D95656] text-white rounded-tr-none border-[#D95656]"
                                      : "bg-[#7F56D9] text-white rounded-tr-none border-[#7F56D9]"
                                  }`}
                                  style={{
                                    wordBreak: "break-word",
                                    overflowWrap: "break-word",
                                  }}
                                >
                                  {message.text}
                                </p>
                                {message?.isFailed && (
                                  <div
                                    className="bg-[#FEE4E2] rounded-full h-8 w-8 flex justify-center items-center ml-3 cursor-pointer"
                                    onClick={() => handleResend(message?.id)}
                                  >
                                    <img src={Resend} alt="resend" />
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                          {message.isSender && message?.isFailed ? (
                            <div className="text-xs text-[#535862] mt-1 mr-[44px]">
                              Failed to Send. Please retry!
                            </div>
                          ) : (
                            <>
                              {message?.isSender && (
                                <div className="text-xs text-[#535862] mt-1">
                                  {message.time}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="flex flex-col items-center justify-center h-full">
                      <img
                        src={noMessages}
                        alt="no messages"
                        className="mr-[6px] w-[56px] h-[56px] mb-2"
                      />
                      <div className="w-full text-center text-[#181D27] text-[15.55px] leading-[23.32px] font-[600] mb-[8px]">
                        Messages
                      </div>
                      <div className="text-[#535862] text-[12.44px] font-[400] leading-[18.68px] w-[224px] text-center">
                        Click on a thread to view your LinkedIn Messages and
                        reply directly.
                      </div>
                    </div>
                  )}
                  {messages?.length ? (
                    <div className="flex items-center mb-6">
                      <div className="w-full h-[1px] bg-[#E9EAEB]" />
                      <p className="px-2 text-sm font-medium text-[#535862]">
                        Today
                      </p>
                      <div className="w-full h-[1px] bg-[#E9EAEB]" />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="h-[130px] w-full py-4 relative">
                  <textarea
                    rows="3"
                    className="resize-none border border-[#D5D7DA] rounded-lg p-2 focus:outline-none focus:border-[#D5D7DA] focus:ring-0 w-full placeholder-[#BCBDBF] pr-40"
                    placeholder="Send Katherine a reply..."
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault();
                        handleSendMessage();
                      }
                    }}
                  />
                  <div className="absolute bottom-[0px] right-[0px] flex items-center">
                    <div className="absolute bottom-[34px] right-[10px] flex items-center">
                      <button className="w-9 h-9">
                        <img
                          src={emoji}
                          alt="import"
                          className="w-[20px] h-[20px]"
                        />
                      </button>
                      <button className="w-9 h-9">
                        <img
                          src={threeDots}
                          alt="import"
                          className="w-[20px] h-[20px]"
                        />
                      </button>
                      <button
                        className="bg-[#7F56D9] h-10 text-white px-[14px] py-[10px] text-sm font-semibold rounded-lg"
                        onClick={handleSendMessage}
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="flex flex-col items-center justify-center h-full">
          <img
            src={noMessages}
            alt="no messages"
            className="mr-[6px] w-[56px] h-[56px] mb-2"
          />
          <div className="w-full text-center text-[#181D27] text-[15.55px] leading-[23.32px] font-[600] mb-[8px]">
            Messages
          </div>
          <div className="text-[#535862] text-[12.44px] font-[400] leading-[18.68px] w-[224px] text-center">
            Click on a thread to view your LinkedIn Messages and reply directly.
          </div>
        </div>
      )}
    </div>
  );
};

export default Messages;
