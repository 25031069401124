import { useEffect, useState } from "react";
import Button from "components/button";
import leftIcon from "assets/images/addMember3.svg";
import centerIcon from "assets/images/addMember1.svg";
import rightIcon from "assets/images/addMember2.svg";
import CommonCheckbox from "components/commonCheckbox";
import {
  useGetCollaborators,
  useShareProspectList,
} from "services/prospecting-api";
import { useAuthStore, useSettingStore } from "stores";
import teamImg from "assets/images/activity-avatar3.svg";
import { toast } from "react-toastify";
import thumb from "assets/images/thumb.svg";
import Loader from "components/loader";
import {
  getNameInititals,
  getRandomProfileLogo,
  getRandomProfileLogoWithRandom,
} from "utils/index";
import WorkspaceLogo from "assets/images/workspace.svg";

const AddTeamMemberStep = ({ setStep, prospectId }) => {
  const getCollaborators = useGetCollaborators();
  const { userData } = useSettingStore();
  const { userDetails } = useAuthStore();

  const { isAdmin } = useAuthStore();
  const shareProspectList = useShareProspectList();
  const [teamMembers, setTeamMembers] = useState([]);
  const [adminIds, setAdminIds] = useState([]);
  const [selectedCheckboxIds, setSelectedCheckboxIds] = useState([]);
  const [loginUserId, setLoginUserId] = useState(null);
  const handleCheckboxChange = (userId, isCompany) => {
    if (isCompany) {
      const isCompanySelected = selectedCheckboxIds.includes(userId);

      if (isCompanySelected) {
        // If the company checkbox is already selected, unselect all team members and remove company ID
        if (isAdmin) {
          setSelectedCheckboxIds([...adminIds]);
        } else if (loginUserId) {
          setSelectedCheckboxIds([loginUserId]);
        } else {
          setSelectedCheckboxIds([]);
        }
      } else {
        // If the company checkbox is selected, select all team members (excluding admins)
        const newSelection = teamMembers
          .filter((item) => !item.company && !adminIds.includes(item.user_id))
          .map((item) => item.user_id);
        if (isAdmin) {
          setSelectedCheckboxIds([...newSelection, userId, ...adminIds]); // Add company ID and admin ID
        } else if (loginUserId) {
          setSelectedCheckboxIds([...newSelection, userId, loginUserId]); // Add company ID and admin ID
        } else {
          setSelectedCheckboxIds([...newSelection, userId]); // Add company ID
        }
      }
    } else {
      // For individual user checkboxes
      let newSelection;

      if (selectedCheckboxIds.includes(userId)) {
        // If user is already selected, unselect them
        newSelection = selectedCheckboxIds.filter((id) => id !== userId);
      } else {
        // Otherwise, add them to the selection
        newSelection = [...selectedCheckboxIds, userId];
      }

      // Check if all team members (except company and admins) are selected
      const allMembersSelected = teamMembers
        .filter((item) => !item.company && !adminIds.includes(item.user_id))
        .every((member) => newSelection.includes(member.user_id));

      if (allMembersSelected) {
        // If all individual team members are selected, include the company ID
        newSelection.push("company_id"); // Replace with your actual company ID
      } else {
        // If any individual member is unselected, remove the company ID
        newSelection = newSelection.filter((id) => id !== "company_id"); // Replace with your actual company ID
      }

      setSelectedCheckboxIds(newSelection);
    }
  };

  const handleConfirmClick = async () => {
    try {
      const payload = {
        prospect_id: prospectId,
        shared_user_ids: selectedCheckboxIds
          ?.filter((id) => !adminIds.includes(id))
          ?.filter(Boolean)
          ?.filter((item) => item !== "company_id"),
      };
      await shareProspectList?.mutateAsync(payload);
      if (selectedCheckboxIds?.length > 1) {
        toast.success("List Shared", {
          icon: () => <img src={thumb} alt="name" />,
        });
      }
      setStep("succes-modal");
    } catch (error) {
      setStep("succes-modal");
      toast.error(error?.data?.detail);
    }
  };

  const isUsers =
    teamMembers?.filter((item) => item?.role === "team_members")?.length > 0;

  const allTeamMembersSelected = teamMembers
    ?.filter((i) => (!isUsers ? i?.company : !i?.company))
    .every((member) => selectedCheckboxIds?.includes(member.user_id));

  const handleGetCollaborators = async () => {
    try {
      const resolve = await getCollaborators.mutateAsync();
      const combinedTeam = [
        ...resolve?.admin,
        {
          ...userData?.company,
          company: true,
          name: resolve?.admin?.[0]?.workspace || "",
        },
        ...resolve?.team_members?.filter((item) => item?.user_id),
      ];

      const currentUser = resolve?.team_members?.find(
        (item) => item?.user_id === userData?.user_id
      );

      const fetchedAdminIds =
        (isAdmin
          ? resolve?.admin?.map((admin) => admin.user_id)
          : [currentUser?.user_id]) || [];
      setAdminIds(fetchedAdminIds); // Store admin IDs
      if (!isAdmin) {
        setLoginUserId(...fetchedAdminIds);
      }

      const updatedSelectedCheckboxIds = [
        ...selectedCheckboxIds,
        ...fetchedAdminIds,
      ];

      const sortedData = [
        {
          ...resolve?.team_members?.find(
            (item) => item?.user_id === userData?.user_id
          ),
          isLoginUser: true,
        },
        {
          ...userData?.company,
          company: true,
          user_id: "company_id",
          name: resolve?.admin?.[0]?.workspace || "",
        },
        ...resolve?.admin,
        ...resolve?.team_members
          ?.filter((item) => item?.user_id !== userData?.user_id)
          .filter((item) => item?.user_id),
      ];

      const filterData = isAdmin ? combinedTeam : sortedData;
      setSelectedCheckboxIds(updatedSelectedCheckboxIds);
      setTeamMembers(filterData);
    } catch (error) {
      console.log(error);
    }
  };

  const [profileImages, setProfileImages] = useState([]);

  useEffect(() => {
    const images = teamMembers.map((item, index) => {
      if (item?.role === "admin") {
        return index === 0
          ? userData?.user?.profile_pic ||
              getRandomProfileLogoWithRandom(index)?.src
          : getRandomProfileLogo()?.src;
      } else if (item?.company) {
        return WorkspaceLogo;
      } else {
        return null; // or some default image
      }
    });
    setProfileImages(images);
  }, [teamMembers, userData]);

  useEffect(() => {
    handleGetCollaborators();
  }, []);

  return (
    <div className="modal-scroll">
      <div className="flex items-center justify-center mb-[20px] font-inter relative mt-[6px]">
        <img
          src={leftIcon}
          alt="left img"
          className="w-[48px] h-[48px] rounded-full mr-2"
        />
        <img
          src={centerIcon}
          alt="center img"
          className="w-[56px] h-[56px] rounded-full absolute top-[-8px]"
        />
        <img
          src={rightIcon}
          alt="right img"
          className="w-[48px] h-[48px] rounded-full ml-2"
        />
      </div>
      <div className="mt-[24px] mb-[20px]">
        <h4 className="font-[600] text-[18px] text-[#101828] text-center m-0 font-inter">
          Add your team members
        </h4>
        <p className="font-normal text-[14px] text-[#475467] text-center font-inter">
          Invite colleagues to collaborate on this lead list.
        </p>
      </div>
      <div className="max-h-[320px] modal-scroll overflow-auto">
        {teamMembers?.length > 0 ? (
          teamMembers?.map((item, index) => (
            <div
              key={item?.user_id}
              className="flex items-center w-full mb-[12px]"
            >
              <div
                className={`w-[16px] h-[16px] team-checkbox mr-[11px] ${
                  (item?.role === "admin" && isAdmin) || item?.isLoginUser
                    ? "team-fade-checkbox"
                    : ""
                }`}
              >
                <CommonCheckbox
                  checked={
                    item?.isLoginUser
                      ? true
                      : item?.company
                      ? allTeamMembersSelected
                      : (item?.role === "admin" && isAdmin) ||
                        selectedCheckboxIds.includes(item?.user_id)
                  }
                  onChange={() =>
                    handleCheckboxChange(item?.user_id, item?.company)
                  }
                  disabled={
                    (item?.role === "admin" && isAdmin) || item?.isLoginUser
                  }
                />
              </div>
              <div className="flex items-center justify-between w-full pr-2">
                <div className="flex items-center w-[80%]">
                  {item?.role === "admin" || item?.company ? (
                    <img
                      src={
                        item?.icons
                          ? item?.icon
                          : item?.role === "admin"
                          ? index === 0
                            ? userData?.user?.profile_pic ||
                              userDetails?.profile_pic ||
                              getRandomProfileLogoWithRandom(index)?.src
                            : profileImages[index]
                          : item?.company
                          ? WorkspaceLogo
                          : teamImg
                      }
                      alt="team member icon"
                      className={`w-[40px] min-w-[40px] h-[40px] mr-[12px] object-center  ${
                        !item?.company && "rounded-full"
                      } `}
                    />
                  ) : (
                    <div className="w-[40px] min-w-[40px] h-[40px] rounded-full flex items-center justify-center text-[14px] font-[700] text-[#4409B9] bg-[#EEEDFD] mr-[12px]">
                      {getNameInititals(item?.name || item?.email)}
                    </div>
                  )}

                  <div>
                    <h6
                      className={`font-inter leading-[20px] text-[#475467] m-0 ${
                        (item?.role === "admin" && isAdmin) || item?.isLoginUser
                          ? "text-[#AAA8AF]"
                          : ""
                      } ${
                        item?.company_name
                          ? "font-[400] text-[13px]"
                          : "font-[600] text-[14px]"
                      } ${item?.company && "-mt-[5px]"}`}
                    >
                      {item?.company_name || item?.name || item?.email}
                    </h6>

                    <p
                      className={`font-inter font-normal text-[14px] leading-[20px] text-[#475467] m-0 ${
                        (item?.role === "admin" && isAdmin) || item?.isLoginUser
                          ? "text-[#AAA8AF]"
                          : ""
                      }`}
                    >
                      {item?.company && item?.name
                        ? "Your Workspace"
                        : item?.email}
                    </p>
                  </div>
                </div>
                {!item?.company && (
                  <p
                    className={`w-[20%] text-right font-inter font-[500] text-[12px] leading-[18px] ${
                      selectedCheckboxIds.includes(item?.user_id)
                        ? "text-[#475467]"
                        : "text-[#475467]"
                    }`}
                  >
                    {item?.isLoginUser
                      ? "You"
                      : selectedCheckboxIds.includes(item?.user_id) &&
                        item?.role !== "admin"
                      ? "Editor"
                      : item?.role === "admin"
                      ? index === 0
                        ? isAdmin
                          ? "You (Admin)"
                          : "Admin"
                        : "Admin"
                      : ""}
                  </p>
                )}
              </div>
            </div>
          ))
        ) : (
          <div className="w-full h-[200px] flex items-center justify-center">
            <Loader />
          </div>
        )}
      </div>
      <div className="flex items-center justify-center pt-[24px] border-t-[1.5px] mt-[32px] border-[#E4E7EC]">
        {/* <Button
          title="Back"
          isWhiteBtn={true}
          isRounded="6px"
          onClick={handleBackClick}
          className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow"
        /> */}
        <Button
          title={
            shareProspectList?.isPending ? (
              <div className="w-[20px] h-[20px] border-[3px] border-t-[3px] mx-auto border-[#fff] border-solid rounded-full animate-spin border-t-[#8A43FF]" />
            ) : (
              <span>Share</span>
            )
          }
          secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9]"
          className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow"
          isRounded="6px"
          onClick={handleConfirmClick}
        />
      </div>
    </div>
  );
};

export default AddTeamMemberStep;
