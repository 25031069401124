import { useEffect, useState } from "react";
import UploadCsv from "components/prospecting/modalSteps/uploadCsv";
import ImportOptionsStep from "components/prospecting/modalSteps/importOptionsStep";
import { IoClose } from "react-icons/io5";
import FormStep from "components/prospecting/modalSteps/formStep";
import ChooseEnrichmentStep from "components/prospecting/modalSteps/chooseEnrichmentStep";
import SuccessModalStep from "components/prospecting/modalSteps/sucessModalStep";
import JobSelectionStep from "components/prospecting/modalSteps/jobSelectionStep";
import AddTeamMemberStep from "components/prospecting/modalSteps/addTeamStep";
import ManuallyDefaultStep from "components/prospecting/modalSteps/manuallyDefalutStep";
import ManuallyEnterStep from "components/prospecting/modalSteps/manuallyEnterStep";
import HubspotStep from "components/prospecting/modalSteps/hubspotStep";
import NameListStep from "components/prospecting/modalSteps/nameListStep";
import SaveListModalStep from "components/prospecting/modalSteps/saveListModal";
import EnrichLeadsStep from "../modalSteps/enrichLeads";
import EnrichLeadsLoadingStep from "../modalSteps/enrichLeadsLoading";
import { AppContextProvider } from "context/AppContext";
import NotEnrichImportLeads from "../modalSteps/notEnrichImportLeads";
import LookingForLeads from "../modalSteps/lookingforleads";
import ImportingYourLeads from "../modalSteps/ImportingYourLeads";
import UploadFailed from "../modalSteps/uploadfailed";
import DataMappingFailed from "../modalSteps/datamappingfailed";
import CouldNotEnrichLeads from "../modalSteps/couldnotencrichleads";
import ViaLinkedin from "../modalSteps/viaLinkedin";
import FailedViaLinkedin from "../modalSteps/failedViaLinkedin";
import ViaProfilePicture from "../modalSteps/viaProfilePicture";
const CommonModal = (props) => {
  const { isOpen, onClose, isSaveListModals = false } = props;
  const [step, setStep] = useState("import-options");

  const [enrichType, setEnrichType] = useState("");
  const [saveListStep, setSaveListStep] = useState(1);
  const [totalDataCsv, setTotalDataCsv] = useState("");
  const [maxProfile, setMaxProfile] = useState();
  const [prospectId, setProspectId] = useState();
  const [leadsValue, setLeadsValue] = useState("");
  const [links, setLinks] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [profileURL, setProfileURL] = useState("");

  useEffect(() => {
    if (isOpen) {
      setIsModalVisible(true);
      localStorage.setItem(
        "isSaveListModals",
        JSON.stringify(isSaveListModals)
      );
    } else {
      setIsModalVisible(false);
    }
  }, [isOpen, isSaveListModals]);

  if (!isOpen && !isModalVisible) return null;
  const renderModalContent = () => {
    return (
      <AppContextProvider>
        {(() => {
          switch (step) {
            case "import-options":
              return <ImportOptionsStep setStep={setStep} onClose={onClose} />;
            case "uploadPeopleCsv":
            case "uploadCsvUrl":
            case "uploadCsvEmail":
              return (
                <UploadCsv
                  setStep={setStep}
                  setTotalDataCsv={setTotalDataCsv}
                />
              );
            case "csv-form-step":
              return (
                <FormStep setStep={setStep} setEnrichType={setEnrichType} />
              );
            case "enrich-leads-step":
              return (
                <EnrichLeadsStep
                  setStep={setStep}
                  setProspectId={setProspectId}
                  enrichType={enrichType}
                  totalDataCsv={totalDataCsv}
                  setMaxProfile={setMaxProfile}
                  setLeadsValue={setLeadsValue}
                  leadsValue={leadsValue}
                  prospectId={prospectId}
                  step={step}
                  links={links}
                  profileURL={profileURL}
                />
              );
            case "enrich-leads-loading-step":
              return (
                <EnrichLeadsLoadingStep
                  setStep={setStep}
                  enrichType={enrichType}
                />
              );
            case "not-enrich-leads-step":
              return (
                <NotEnrichImportLeads
                  setStep={setStep}
                  leadsValue={leadsValue}
                />
              );
            case "choose-enrichment":
              return <ChooseEnrichmentStep setStep={setStep} />;
            case "succes-modal":
              return (
                <SuccessModalStep
                  onClose={onClose}
                  setStep={setStep}
                  prospectId={prospectId}
                  setLeadsValue={setLeadsValue}
                />
              );
            case "job-selection":
              return (
                <JobSelectionStep
                  onClose={onClose}
                  setStep={setStep}
                  setTotalDataCsv={setTotalDataCsv}
                />
              );
            case "add-team":
              return (
                <AddTeamMemberStep
                  prospectId={prospectId}
                  onClose={onClose}
                  setStep={setStep}
                />
              );
            case "manually-default":
            case "manuallyCompanyUrl":
              return (
                <ManuallyDefaultStep
                  onClose={onClose}
                  setStep={setStep}
                  setEnrichType={setEnrichType}
                  enrichType={enrichType}
                />
              );
            case "manuallyLink":
            case "manually-enter":
              return (
                <ManuallyEnterStep
                  onClose={onClose}
                  setStep={setStep}
                  setProspectId={setProspectId}
                  setEnrichType={setEnrichType}
                  setTotalDataCsv={setTotalDataCsv}
                  setLinks={setLinks}
                />
              );
            case "import-hubspot":
              return <HubspotStep onClose={onClose} setStep={setStep} />;
            case "name-list":
              return <NameListStep onClose={onClose} setStep={setStep} />;
            case "looking-for-leads":
              return <LookingForLeads onClose={onClose} setStep={setStep} />;
            case "importing-your-leads":
              return <ImportingYourLeads onClose={onClose} setStep={setStep} />;
            case "save-list-step":
              return (
                <SaveListModalStep
                  setStep={setStep}
                  onClose={onClose}
                  prospectId={prospectId}
                />
              );
            case "add-team-members":
              return (
                <AddTeamMemberStep
                  prospectId={prospectId}
                  onClose={onClose}
                  setStep={setStep}
                />
              );
            case "upload-failed":
              return <UploadFailed onClose={onClose} setStep={setStep} />;
            case "data-mapping-failed":
              return <DataMappingFailed onClose={onClose} setStep={setStep} />;
            case "could-not-enrich-leads":
              return (
                <CouldNotEnrichLeads
                  onClose={() => {
                    onClose();
                    localStorage.removeItem("isSaveListModals");
                    localStorage.removeItem("selectedButtonType");
                    if (isSaveListModals) {
                      setSaveListStep(1);
                    } else {
                      setStep("import-options");
                    }
                  }}
                  setStep={setStep}
                  maxProfile={maxProfile}
                />
              );
            case "viaLinkedin":
              return (
                <ViaLinkedin
                  setStep={setStep}
                  setProspectId={setProspectId}
                  setTotalDataCsv={setTotalDataCsv}
                />
              );
              case "viaProfileUrl":
                return (
                  <ViaProfilePicture
                    setStep={setStep}
                    setTotalDataCsv={setTotalDataCsv}
                    profileURL={profileURL}
                    setProfileURL={setProfileURL}
                    setEnrichType={setEnrichType}
                  />
                );
            case "failedViaLinkedin":
              return <FailedViaLinkedin setStep={setStep} />;
            default:
              return null;
          }
        })()}
      </AppContextProvider>
    );
  };

  const renderSaveListModals = () => {
    switch (saveListStep) {
      case 1:
        return (
          <SaveListModalStep
            setStep={setSaveListStep}
            onClose={onClose}
            prospectId={prospectId}
          />
        );
      case 2:
        return (
          <AddTeamMemberStep
            onClose={onClose}
            setSaveListStep={setSaveListStep}
            setStep={setStep}
            prospectId={prospectId}
          />
        );
      case 3:
        return (
          <SuccessModalStep
            onClose={onClose}
            setStep={setStep}
            prospectId={prospectId}
          />
        );
      default:
        return (
          <SaveListModalStep
            setStep={setSaveListStep}
            onClose={onClose}
            prospectId={prospectId}
          />
        );
    }
  };

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[400ms] ease-in-out ${
        isModalVisible ? "opacity-100" : "opacity-0 pointer-event-none"
      }`}
    >
      <div
        className="fixed inset-0 bg-black opacity-10"
        onClick={() => {
          onClose();
          setProfileURL("");
          setLeadsValue("");
          localStorage.removeItem("isSaveListModals");
          localStorage.removeItem("selectedButtonType");
          if (isSaveListModals) {
            setSaveListStep(1);
          } else {
            setStep("");
            setTimeout(() => {
              setStep("import-options");
            }, 500);
          }
        }}
      ></div>
      <div
        className={`${
          step === "enrich-leads-loading-step" && "!max-w-[400px]"
        } ${
          step === "import-options"
            ? "max-w-[900px] xlg:max-w-[750px]"
            : "max-w-[695px]"
        } bg-white p-[24px] rounded-lg modal-shadow z-10 w-full relative  ${
          isModalVisible ? "scale-100" : "scale-95"
        } ${
          step === "add-team" || saveListStep === 2 ? "px-0 py-[24px] " : ""
        }`}
      >
        {step === "add-team" ||
        saveListStep === 2 ||
        step === "enrich-leads-loading-step" ||
        step === "add-team-members" ||
        step === "looking-for-leads" ? null : (
          <IoClose
            className="text-[#A4A7AE] text-[20px] w-[24px] h-[24px] absolute right-[24px] top-[26px] cursor-pointer"
            onClick={() => {
              onClose();
              setProfileURL("");
              setLeadsValue("");
              localStorage.removeItem("isSaveListModals");
              localStorage.removeItem("selectedButtonType");
              if (isSaveListModals) {
                setSaveListStep(1);
              } else {
                setStep("");
                setTimeout(() => {
                  setStep("import-options");
                }, 500);
              }
            }}
          />
        )}
        <div className="modal-scroll">
          {isSaveListModals ? (
            <>{renderSaveListModals()}</>
          ) : (
            <>{renderModalContent()}</>
          )}
        </div>
      </div>
    </div>
  );
};

export default CommonModal;
