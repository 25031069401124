import Button from "components/button";
import { IoClose } from "react-icons/io5";
import linkIcon from "assets/images/link-broken.svg";
import { useEffect, useState } from "react";

const RequestIntegrationModal = ({ isOpen, setStep, onClose }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [requestText, setRequestText] = useState("");

  useEffect(() => {
    if (isOpen) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [isOpen]);

  if (!isOpen && !isModalVisible) return null;

  return (
    <>
      <div
        className={`fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[300ms] ease-in-out ${
          isModalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
      >
        <div
          className="fixed inset-0 bg-black opacity-10"
          onClick={() => {
            setRequestText("");
            onClose();
          }}
        ></div>
        <div
          className={`bg-white p-[24px] rounded-lg modal-shadow z-10 w-full max-w-[400px] relative transition-all ease-in-out duration-[150ms] ${
            isModalVisible ? "scale-100" : "scale-95"
          }`}
        >
          <IoClose
            className="text-[#667085] text-[20px] w-[24px] h-[24px] absolute right-[24px] top-[26px] cursor-pointer"
            onClick={() => {
              setRequestText("");
              onClose();
            }}
          />
          <div className="flex flex-col items-center justify-center mb-[26px] font-inter">
            <div className="flex items-center justify-center border-[1px] border-[#E4E7EC] rounded-[10px] p-[11px] mb-[16px] y-axis-shadow">
              <img
                src={linkIcon}
                alt="info img"
                className="h-[24px] w-[24px]"
              />
            </div>
            <h4 className="font-[600] text-[18px] text-[#101828] mb-[20px] font-inter">
              Request Integration
            </h4>
            <p className="font-normal text-center text-[14px] text-[#344054] font-inter">
              Let us know what service you would like Pipeline to integrate with
              and your use case!
            </p>
          </div>
          <p className="font-[500] text-[14px] text-[#344054] mb-[9px] font-inter">
            Enter a Message
          </p>
          <div className="relative mb-[12px]">
            <textarea
              value={requestText}
              onChange={(e) => setRequestText(e?.target?.value)}
              placeholder="I would love to see a..."
              className="px-[14px] py-[14px] text-[16px] y-axis-shadow leading-[24px] w-full min-h-[193px] border-[#D0D5DD] rounded-[8px] bg-[#FFFFFF] text-[#667085] placeholder:text-[#667085] focus:!outline-none resize-none focus:!border-[#D0D5DD]"
            ></textarea>
          </div>
          <div className="flex items-center justify-center mt-[24px]">
            <Button
              title="Submit"
              secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9]"
              className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow"
              isRounded="6px"
              onClick={() => {
                onClose();
                setRequestText("");
                setStep(true);
              }}
              disabled={requestText?.length < 3}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestIntegrationModal;
