const Tabs = ({
  tab,
  setTab,
  tabOptions,
  settingTab = false,
  isNewCampaignTabs = false,
  completedTabs = null,
}) => {
  const handleCampaignTabNavigation = (targetTab) => {
    if (completedTabs?.includes(targetTab)) {
      setTab(targetTab);
    }
  };

  return (
    <div className="text-sm font-medium text-center text-[#6B7280]">
      <ul className="flex flex-wrap w-fit h-10 border border-[#D0D5DD] rounded-[8px] overflow-hidden">
        {tabOptions?.map((item, index) => {
          const isActive = item?.value === tab;
          const isCompleted = completedTabs?.includes(item?.label);
          const isDisabled = !isCompleted && isNewCampaignTabs;
          const isLastItem = tabOptions.length - 1;
          return (
            <li
              className={` ${
                settingTab && "mr-[0px]"
              } font-inter cursor-pointer`}
              key={index}
            >
              <div
                onClick={() => {
                  if (isDisabled) return;
                  if (isNewCampaignTabs) {
                    handleCampaignTabNavigation(item.label);
                  } else {
                    setTab?.(item.value);
                  }
                }}
                className={`flex w-[124px] h-10 text-center items-center justify-center text-sm text-[#344054] font-semibold px-[10px] text-[13.5px] font-inter
                    ${isLastItem !== index && "border-r border-[#D0D5DD]"}
                    ${
                      isActive || (isActive && isDisabled)
                        ? "!text-[#182230] bg-[#F9FAFB]"
                        : ""
                    } 
               `}
              >
                {isActive && (
                  <div className="bg-[#17B26A] w-2.5 h-2.5 rounded-full mr-2" />
                )}{" "}
                <p>{item?.label}</p>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Tabs;
