import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { IoClose } from "react-icons/io5";
import { useLinkedinPremiumCheck, useVerifyLinkedin } from "services/auth-api";
import Phone from "assets/images/mobile.svg";
import AnimatedButton from "components/animatedButton";

const AccountUpgradeModal = ({
  isOpen,
  onClose,
  setLoader,
  setOpenConnectModalSteps,
  intervalRef,
}) => {
  const linkedinPremiumCheck = useLinkedinPremiumCheck();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const verifyLinkedin = useVerifyLinkedin();

  const [clickedButton, setClickedButton] = useState(false);

  const handleVerification = async (accountId) => {
    try {
      const verify = await verifyLinkedin.mutateAsync({
        account_id: accountId,
      });

      if (verify?.is_premium) {
        clearInterval(intervalRef.current);
        setOpenConnectModalSteps(4);
        setLoader(false);
      } else if (verify?.login_failed) {
        clearInterval(intervalRef.current);
        setLoader(false);
        toast.error("Login failed");
        return;
      } else {
        setClickedButton(false);
        clearInterval(intervalRef.current);
        setLoader(false);
      }
    } catch (error) {
      setClickedButton(false);
      clearInterval(intervalRef.current);
      setLoader(false);
      if (error?.data && error?.data?.detail) {
        toast.error(error?.data?.detail);
      } else {
        toast.error("An error occurred during LinkedIn verification");
      }
    }
  };

  const startVerificationInterval = (accountId) => {
    intervalRef.current = setInterval(async () => {
      await handleVerification(accountId);
    }, 30000);
  };

  const handleButtonClick = async () => {
    if (clickedButton) {
      setLoader(true);
      setTimeout(async () => {
        try {
          const response = await linkedinPremiumCheck.mutateAsync();
          if (response?.status === 200) {
            startVerificationInterval(response?.account_id);
          }
        } catch (error) {
          console.log(error);
          setLoader(false);
        }
      }, 2000);
    } else {
      setClickedButton(true);
      window.open("https://www.linkedin.com", "_blank");
    }
  };

  useEffect(() => {
    if (isOpen) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [isOpen]);

  if (!isOpen && !isModalVisible) return null;

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[300ms] ease-in-out ${
        isModalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className="fixed inset-0 bg-black opacity-10"
        onClick={onClose}
      ></div>
      <div
        className={`bg-white p-[24px] rounded-lg modal-shadow z-10 w-full max-w-[400px] relative transition-all ease-in-out duration-[150ms] ${
          isModalVisible ? "scale-100" : "scale-95"
        }`}
      >
        <div
          className="flex justify-center items-center border-[#E4E7EC] border w-[48px] h-[48px] rounded-[10px] mb-[16px]"
          style={{
            boxShadow:
              "0px 4px 8px 0px rgba(16, 24, 40, 0.05), 0px 2px 0px 0px rgba(16, 24, 40, 0.05), 0px 0px 1px 0px rgba(16, 24, 40, 0.18)",
          }}
        >
          <img src={Phone} alt="phone icon" className="h-[22px] w-[22px]" />
        </div>
        <IoClose
          className="text-[#667085] text-[20px] w-[24px] h-[24px] absolute right-[24px] top-[26px] cursor-pointer"
          onClick={onClose}
          aria-label="Close modal"
        />
        <div className="modal-scroll">
          <div className="flex flex-col items-center justify-center font-inter mt-[16px]">
            <h4 className="font-[600] text-[18px] text-[#101828] w-full !text-left mb-[4px]">
              Upgrade your LinkedIn Account
            </h4>
            <p className="text-[#475467] font-[400] text-[14px] w-full !text-left mb-[20px]">
              Your LinkedIn account is on the{" "}
              <span className="font-[700]">Free</span> plan.
            </p>
          </div>
          <p className="text-[#475467] font-[400] text-[14px] w-full !text-center">
            In order to utilize key features, you'll need to{" "}
            <span className="font-[700]">
              upgrade your LinkedIn account to premium
            </span>
          </p>
          <div className="flex items-center justify-center mt-[15px]">
            <AnimatedButton
              title={`${clickedButton ? "Check for Premium" : "Upgrade"}`}
              onClick={handleButtonClick}
              className="!text-[16px] !font-[600] !rounded-[8px] !text-[#FFFFFF] !bg-[#7F56D9] border !border-[#7F56D9]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountUpgradeModal;
