import Logo from "assets/images/logo.svg";
import GoogleIcon from "assets/images/googleIcon.svg";
import Avatar from "assets/images/avatar.svg";
import { FaAngleDown } from "react-icons/fa6";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AnimatedButton from "components/animatedButton";
import checkIcon from "assets/images/login-check.svg";
import CommonCheckbox from "components/commonCheckbox";
import { toast } from "react-toastify";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useForm } from "react-hook-form";
import { useAuthCheck, useGoogleSignUp, useSignup } from "services/auth-api";
import { useGoogleLogin } from "@react-oauth/google";
import { AppContext } from "context/AppContext";
import { useAccountStore, useAuthStore, useSettingStore } from "stores";
import { useCreatePaymentLink } from "services/payments-api";
import { useGetLinkedinAccount } from "services/accounts-api";
import { setPaymentVerify } from "utils/token-service";
import Loader from "components/loader";

const inputClasses = `w-full h-[42px] font-[400] bg-[#00000014] border border-1 border-[#333741] rounded-[7px] outline-none py-[9px] px-[13px] relative text-[16px] text-[#fff] placeholder:!text-[#85888E] focus-visible:border-[#fff] focus-visible:text-[#fff] focus-visible:placeholder:text-[#fff] transition-all`;

const SignUp = () => {
  const signUpMutation = useSignup();
  const { setLinkedinAccount } = useAccountStore();
  const navigate = useNavigate();
  const googleSignUp = useGoogleSignUp();
  const { setCompanyId, setIsAdmin, setUserDetails, setAuthCheck } =
    useAuthStore();
  const { setUserData } = useSettingStore();
  const { setAppState } = useContext(AppContext);
  const getLinkedInAccountDetails = useGetLinkedinAccount();
  const authCkeckMutation = useAuthCheck();
  const createPaymentLink = useCreatePaymentLink();

  const [signedIn, setSignedIn] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isMinLength, setIsMinLength] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasLowercase, setHasLowercase] = useState(false);
  const [hasUppercase, setHasUppercase] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loader, setLoader] = useState(false);
  const [searchparams] = useSearchParams();
  const paramsEmail = searchparams.get("email");
  const paramsCode = searchparams.get("code");
  const [fadeIn, setFadeIn] = useState(true);

  const {
    register,
    handleSubmit,
    watch,
    setFocus,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      password: "",
    },
  });

  const { email, password } = watch();

  useEffect(() => {
    if (paramsEmail) {
      setValue("email", paramsEmail);
    }
  }, [paramsEmail]);

  useEffect(() => {
    setIsMinLength(password.length >= 8);
    setHasSpecialChar(/[^A-Za-z0-9]/.test(password));
    setHasNumber(/[0-9]/.test(password));
    setHasLowercase(/[a-z]/.test(password));
    setHasUppercase(/[A-Z]/.test(password));
  }, [password]);

  const onSubmit = async (data) => {
    if (
      !hasSpecialChar ||
      !isMinLength ||
      !hasLowercase ||
      !hasUppercase ||
      !hasNumber
    ) {
      return setFocus("password");
    }

    if (!isChecked) {
      toast.error(
        "You must agree to the Terms of Use and Privacy Policy before proceeding."
      );
      return;
    }

    try {
      const response = await signUpMutation.mutateAsync({
        ...data,
        company_name: "Acme Corp",
        code: paramsCode || undefined,
      });

      if (response.status === 200) {
        navigate("/email-verification", { state: { email: email } });
      }
    } catch (error) {
      if (
        (error?.data && error?.data?.detail && error?.data?.detail[0]) ||
        (error?.data && error?.data?.detail)
      ) {
        toast.error(error?.data?.detail?.[0]?.msg || error?.data?.detail);
      } else {
        toast.error("An unknown error occurred");
      }
    }
  };

  const handleSignUp = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      setLoader(true);
      try {
        const payload = {
          token: tokenResponse.access_token,
          invite_code: paramsCode || undefined,
        };

        const response = await googleSignUp.mutateAsync(payload);

        if (response?.token) {
          toast.success("Account created successfully!");
          onNavigate(response, response);
        }
      } catch (error) {
        toast.error(
          error?.message ||
            error?.data?.detail ||
            error?.data?.response?.detail ||
            "An unknown error occurred",
          {
            position: "bottom-right",
          }
        );
        console.log("Error in Sign up Process", error);
      } finally {
        setLoader(false);
        setFadeIn(false);
      }
    },
    onError: () => {
      toast.error("Google Sign up failed");
    },
  });

  const onNavigate = async (response, data) => {
    if (response.token) {
      if (response?.role_name === "admin") {
        setAppState((prev) => ({ ...prev, isAdmin: true }));
        setIsAdmin(true);
      } else {
        setAppState((prev) => ({ ...prev, isAdmin: false }));
        setIsAdmin(false);
      }
      try {
        setLoader(true);
        const navigationCheck = await authCkeckMutation.mutateAsync({});
        setUserDetails(response);
        setCompanyId(response?.company_id);
        setAuthCheck(navigationCheck);
        const userId = { user_id: response?.userid };
        if (!navigationCheck?.payment_setup) {
          setUserData(userId);
          const link = await createPaymentLink.mutateAsync({
            price_id: "price_1PvLvZICbyBbd0D4UCEVVHfb",
            customer_mail_id: data.email,
          });
          if (link.url) {
            setLoader(false);
            window.open(link.url, "_self");
          }
        } else {
          if (
            !navigationCheck?.linkedin_setup ||
            !navigationCheck?.company_setup
          ) {
            setUserData(userId);
            navigate("/started");
            localStorage.removeItem("isNewUser");
            setLoader(false);
          } else {
            setUserData(userId);
            handleGetLinkedInAccountDetails();
            setPaymentVerify(true);
            navigate("/dashboard");
            setLoader(false);
          }
        }
      } catch (error) {
        setLoader(false);
        toast.error(error?.data?.detail);
      }
    }
  };

  const handleGetLinkedInAccountDetails = async () => {
    try {
      const response = await getLinkedInAccountDetails.mutateAsync();
      setLinkedinAccount(response);
    } catch (error) {
      setLinkedinAccount({});
      console.log(error);
    }
  };

  if (loader) {
    return (
      <div className="h-[100%] flex flex-col justify-center items-center max-w-[340px] m-auto max-[767px]:h-auto  max-[767px]:p-[20px]  max-[767px]:pt-[50px]  max-[767px]:max-w-[100%]">
        <img src={Logo} alt="Logo" className="h-[60px] w-[60px] z-50" />
        <div className="mt-[30px] ml-[-10px]">
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <div
      className="relative !pt-[15px] !pb-[20px] h-[100vh] font-inter flex flex-col justify-center items-center max-w-[340px] m-auto max-[767px]:h-auto max-[767px]:p-[20px] max-[767px]:pt-[50px] max-[767px]:max-w-[100%] signin-card"
      data-aos={fadeIn && "fade-in"}
      data-aos-duration={fadeIn && "2000"}
    >
      <img src={Logo} alt="Logo" className="h-[60px] w-[60px]" />
      <h1 className="text-[32px] text-center text-[#F5F5F6] font-[600] mt-[20px] mb-[5px]">
        Create an account
      </h1>
      <p className="text-[15px] text-center text-[#CBCBE8BF] font-[400]">
        Your outreach, on autopilot.
      </p>
      <form className="mt-[10px] w-full" onSubmit={handleSubmit(onSubmit)}>
        <p className="text-[14px] font-[500] text-[#CECFD2] mb-[6px]">Name</p>
        <input
          type="text"
          placeholder="Enter your name"
          className={`${inputClasses} !bg-[#0d0515] text-[#9e9ba0] focus-visible:!border-[#9e9ba0] ${
            errors.name ? "mb-1" : "mb-[10px]"
          } `}
          {...register("name", { required: "Name is required" })}
          onDrop={(e) => e.preventDefault()}
          onDragOver={(e) => e.preventDefault()}
        />
        {errors.name && (
          <p className="text-red-500 text-xs mb-1.5">{errors.name.message}</p>
        )}
        <p className="text-[14px] font-[500] text-[#CECFD2] mb-[6px]">Email</p>
        <input
          type="email"
          placeholder="Enter your email"
          className={`${inputClasses} !bg-[#0d0515] text-[#9e9ba0] focus-visible:!border-[#9e9ba0] ${
            errors.email ? "mb-1" : "mb-[10px]"
          } `}
          {...register("email", {
            required: "Email is required",
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: "Invalid email address",
            },
          })}
          onDrop={(e) => e.preventDefault()}
          onDragOver={(e) => e.preventDefault()}
        />
        {errors.email && (
          <p className="text-red-500 text-xs mb-1.5">{errors.email.message}</p>
        )}
        <p className="text-[14px] font-[500] text-[#CECFD2] mb-[6px]">
          Password
        </p>
        <div className="relative">
          <input
            type={passwordVisible ? "text" : "password"}
            placeholder="Create a password"
            className={`${inputClasses} !bg-[#0d0515] text-[#9e9ba0] focus-visible:!border-[#9e9ba0] ${
              errors.email ? "mb-1" : "mb-[10px]"
            } `}
            {...register("password", { required: "Password is required" })}
            // onPaste={(e) => e.preventDefault()}
            onDrop={(e) => e.preventDefault()}
            onDragOver={(e) => e.preventDefault()}
          />
          <div onClick={() => setPasswordVisible(!passwordVisible)}>
            {passwordVisible ? (
              <FiEye
                className="absolute right-[15px] z-50 top-[14px] cursor-pointer"
                color="#CBCBE866"
              />
            ) : (
              <FiEyeOff
                className="absolute right-[15px] z-50 top-[14px] cursor-pointer"
                color="#CBCBE866"
              />
            )}
          </div>
          {errors.password && (
            <p className="text-red-500 text-xs mb-1.5">
              {errors.password.message}
            </p>
          )}
        </div>
        <div className="mb-[17px]">
          <div className="flex items-center mb-[8px]">
            <div>
              {isMinLength ? (
                <img
                  src={checkIcon}
                  alt="check"
                  className="w-[14px] h-[14px] mr-[6px]"
                />
              ) : (
                <div className="h-[14px] w-[14px] rounded-full border border-[#3A1F66] mr-[6px]" />
              )}
            </div>
            <p
              className={`font-[400] text-[10px] leading-[14px] ${
                isMinLength || password.length === 0
                  ? "text-[#FFFFFF]"
                  : "text-red-500"
              }`}
            >
              Must be at least 8 characters
            </p>
          </div>
          <div className="flex items-center mb-[8px]">
            <div>
              {hasSpecialChar ? (
                <img
                  src={checkIcon}
                  alt="check"
                  className="w-[14px] h-[14px] mr-[6px]"
                />
              ) : (
                <div className="h-[14px] w-[14px] rounded-full border border-[#3A1F66] mr-[6px]" />
              )}
            </div>
            <p
              className={`font-[400] text-[10px] leading-[14px] ${
                hasSpecialChar || password.length === 0
                  ? "text-[#FFFFFF]"
                  : "text-red-500"
              }`}
            >
              Must contain one special character
            </p>
          </div>
          <div className="flex items-center mb-[8px]">
            <div>
              {hasLowercase ? (
                <img
                  src={checkIcon}
                  alt="check"
                  className="w-[14px] h-[14px] mr-[6px]"
                />
              ) : (
                <div className="h-[14px] w-[14px] rounded-full border border-[#3A1F66] mr-[6px]" />
              )}
            </div>
            <p
              className={`font-[400] text-[10px] leading-[14px] ${
                hasLowercase || password.length === 0
                  ? "text-[#FFFFFF]"
                  : "text-red-500"
              }`}
            >
              Must contain one lowercase character
            </p>
          </div>
          <div className="flex items-center mb-[8px]">
            <div>
              {hasUppercase ? (
                <img
                  src={checkIcon}
                  alt="check"
                  className="w-[14px] h-[14px] mr-[6px]"
                />
              ) : (
                <div className="h-[14px] w-[14px] rounded-full border border-[#3A1F66] mr-[6px]" />
              )}
            </div>
            <p
              className={`font-[400] text-[10px] leading-[14px] ${
                hasUppercase || password.length === 0
                  ? "text-[#FFFFFF]"
                  : "text-red-500"
              }`}
            >
              Must contain one uppercase character
            </p>
          </div>
          <div className="flex items-center">
            <div>
              {hasNumber ? (
                <img
                  src={checkIcon}
                  alt="check"
                  className="w-[14px] h-[14px] mr-[6px]"
                />
              ) : (
                <div className="h-[14px] w-[14px] rounded-full border border-[#3A1F66] mr-[6px]" />
              )}
            </div>
            <p
              className={`font-[400] text-[10px] leading-[14px] ${
                hasNumber || password.length === 0
                  ? "text-[#FFFFFF]"
                  : "text-red-500"
              }`}
            >
              Must contain one number
            </p>
          </div>
        </div>
        <div className="flex mb-[17px]">
          <div className="w-[16px] h-[16px] mr-[14px] loginCheckbox">
            <CommonCheckbox
              checked={isChecked}
              onChange={() => setIsChecked(!isChecked)}
              loginCheckBox={true}
            />
          </div>
          <p className="text-[#CECFD2] font-[500] text-[12px] -mt-[1px]">
            By clicking submit, you agree to be bound by our{" "}
            <a
              href="https://withpipeline.com/tos"
              target="_blank"
              rel="noreferrer"
              className="underline cursor-pointer"
            >
              Terms of Service
            </a>{" "}
            and{" "}
            <a
              href="https://withpipeline.com/privacy"
              target="_blank"
              rel="noreferrer"
              className="underline cursor-pointer"
            >
              Privacy Policy
            </a>
            .
          </p>
        </div>
        <AnimatedButton
          title={
            <div className="flex items-center justify-center">
              {signUpMutation?.isPending || loader ? (
                <div className="w-[20px] h-[20px] border-[3px] border-t-[3px] border-[#fff] border-solid rounded-full animate-spin border-t-[#8A43FF]" />
              ) : (
                "Get Started"
              )}
            </div>
          }
          className="!text-[15px] !font-[600] !h-[42px] !rounded-[7px] !mb-[14px] !bg-[#635BFF]"
          fontSize="!text-[15px]"
        />
        {!signedIn ? (
          <button
            type="button"
            className="bg-white w-full h-[42px] rounded-[7px] outline-none text-[#232323] text-[13px] font-[600] border-[2px] border-white transition-all flex items-center justify-center hover:bg-transparent hover:text-white"
            onClick={() => {
              if (isChecked) {
                handleSignUp();
              } else {
                toast.error(
                  "You must agree to the Terms of Use and Privacy Policy before proceeding."
                );
              }
            }}
          >
            Sign up with Google
            <img
              src={GoogleIcon}
              alt="GoogleIcon"
              className="w-[20px] h-[20px] ml-[10px]"
            />
          </button>
        ) : (
          <button
            className="bg-[#232323] border-[2px] border-white w-full h-[42px] rounded-[7px] outline-none text-white text-[13px] font-[600] transition-all flex items-center justify-center"
            onClick={handleSignUp}
          >
            <div className="relative flex items-center">
              <img
                src={Avatar}
                alt="Avatar"
                className="w-[24px] h-[24px] mr-[8px]"
              />
              <div className="text-left">
                <span className="text-[12px]">Signed in as</span>
                <span className="text-[12px] ml-[5px] flex items-center">
                  thomaskeevan@gmail.com
                  <FaAngleDown color="#5F6368" className="ml-[5px] mt-[2px]" />
                </span>
              </div>
            </div>
            <img
              src={GoogleIcon}
              alt="GoogleIcon"
              className="w-[35px] h-[35px] mr-[-10px]"
            />
          </button>
        )}
      </form>

      <p className="text-center font-[400] text-[14px] text-[#eeeeee] absolute top-full mt-3 left-1/2 -translate-x-1/2 w-full">
        Already have an account?{" "}
        <span
          className="font-[600] text-[13px] text-[#fff] cursor-pointer"
          onClick={() => navigate("/")}
        >
          Log in
        </span>
      </p>
    </div>
  );
};

export default SignUp;
