import deleteImg from "assets/images/thumbs-down.svg";
import Button from "components/button";
import { useEffect } from "react";

const CouldNotEnrichLeads = ({ setStep, maxProfile, onClose }) => {
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setStep("succes-modal");
  //   }, 3000);

  //   return () => clearTimeout(timer);
  // }, [setStep]);

  return (
    <>
      <div className="tems-center justify-center font-inter">
        <div className="modal-scroll">
          <div className="modal-scroll">
            <div className="flex flex-col items-start justify-start font-inter">
              <div className="flex items-center justify-center mb-[16px]">
                <img
                  src={deleteImg}
                  alt="info img"
                  className="h-[48px] w-[48px]"
                />
              </div>
              <h4 className="font-[600] text-[18px] text-[#101828] m-[4px] mb-0 ml-0 font-inter">
                Could not enrich all imported leads.
              </h4>
              <p className="text-[14px] text-[#475467] font-inter mt-[9px]">
                <span className="font-[700]">{maxProfile}</span> leads could not be
                enriched.{" "}
              </p>
              <p className="text-[14px] text-[#475467] font-inter mt-[9px]">
              You will not be charged the {maxProfile} credits.
              </p>
            </div>
          </div>
        </div>
        <Button
          title="Back to Import Data"
          isWhiteBtn={true}
          isRounded="6px"
          onClick={() => {
            setStep("import-options");
          }}
          className="!text-[16px] !h-[44px] !font-[600] !mb-[0px] !mt-[32px] y-axis-shadow mr-[12px] !text-[#344054]"
        />
      </div>
    </>
  );
};

export default CouldNotEnrichLeads;
