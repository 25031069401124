import BillingFailure from "components/subscriptionModals/billingFailure";
import Sidebar from "components/sidebar";
import BillingFailureTeamMember from "components/subscriptionModals/billingFailureTeamMember";
import { useAccountStore, useAuthStore, useSettingStore } from "stores";
import { useEffect, useState } from "react";
import {
  useCompanySubscriptionStatus,
  usePaymentStatus,
} from "services/payments-api";
import SubscriptionWasCancelled from "components/subscriptionModals/canceled";
import { useLocation } from "react-router-dom";
import { PYLON_APP_ID } from "constant";
import GuidedTour from "components/dashboard/GuidedTour";
import Joyride, { ACTIONS, EVENTS, ORIGIN, STATUS } from "react-joyride";
import useGuidedTourStore from "stores/guidedTourStore";
import { useNavigate } from "react-router-dom";
import { useGetWorkspaceSettings } from "services/settings-api";
import Bulb from "assets/GuidedTour/images/bright-bulb.svg";
import Hands from "assets/GuidedTour/images/hands.svg";
import PeopleGroup from "assets/GuidedTour/images/people-group.svg";
import Money from "assets/GuidedTour/images/money.svg";
import Rocket from "assets/GuidedTour/images/rocket.svg";
import World from "assets/GuidedTour/images/world.svg";
import Settings from "assets/GuidedTour/images/settings.svg";
import ChatBubble from "assets/GuidedTour/images/chat-bubble.svg";
import Tada from "assets/images/tada-colorful.svg";
import Horn from "assets/GuidedTour/images/horn.svg";
import { useUpdateGuidedTour } from "services/dashboard-api";
import { toast } from "react-toastify";

const Layout = (props) => {
  const { children } = props;
  const navigate = useNavigate();

  const { isSubscriptionCancelled, setIsSubscriptionCancelled } =
    useAuthStore();
  const { setSubscriptionEndDate } = useSettingStore();
  const {
    isGuidedTourRunning,
    setIsGuidedTourRunning,
    currentStepIndex,
    setCurrentStepIndex,
    setRunTour,
    runTour,
  } = useGuidedTourStore();
  const { linkedinAccountDetails } = useAccountStore();

  const location = useLocation();
  const { pathname, search } = location;
  const { isAdmin, userDetails, setUserDetails, setIsAdmin } = useAuthStore();
  const [shouldShowSubscriptionModal, setShouldShowSubscriptionModal] =
    useState(false);

  const [isFailed, setIsFailed] = useState(false);
  const [isSubscriptionCanceled, setIsSubscriptionCanceled] = useState(false);
  const getSettings = useGetWorkspaceSettings();
  const updateTour = useUpdateGuidedTour();

  const { data: paymentMethodStatus } = usePaymentStatus({
    user_id: userDetails?.userid,
  });

  const { data: companySubscriptionStatus } = useCompanySubscriptionStatus({
    user_id: userDetails?.userid,
  });

  useEffect(() => {
    // Update payment failure status
    const failed = paymentMethodStatus?.status === "failed";
    setIsFailed(failed);

    // Update subscription cancellation status
    const canceled = companySubscriptionStatus?.status === "canceled";
    setIsSubscriptionCanceled(canceled);

    if (canceled) {
      setSubscriptionEndDate(companySubscriptionStatus?.subscription_end_date);
      setIsSubscriptionCancelled(true);
    } else {
      setIsSubscriptionCancelled(false);
    }
  }, [paymentMethodStatus, companySubscriptionStatus]);

  useEffect(() => {
    if (isSubscriptionCancelled) {
      const isNotBillingTab = search !== "?tab=Billing";
      const isNotSettingsPath = pathname !== "/settings";
      const isSettingsWithoutBillingTab =
        pathname === "/settings" && search !== "?tab=Billing";
      setShouldShowSubscriptionModal(
        isNotBillingTab && (isNotSettingsPath || isSettingsWithoutBillingTab)
      );
    }
  }, [location, companySubscriptionStatus, isSubscriptionCancelled]);

  useEffect(() => {
    if (userDetails?.userid) {
      window.pylon = {
        chat_settings: {
          app_id: PYLON_APP_ID,
          email: userDetails?.email,
          name: userDetails?.name,
          // avatar_url: user.profileImgUrl,
        },
      };
    }
  }, [userDetails]);

  const handleUpdateTour = async () => {
    if (!userDetails?.guided_tour) {
      return;
    }
    try {
      await updateTour?.mutateAsync();
    } catch (error) {
      toast.success("Something Went wrong");
    }
  };

  // Joyride steps (guiding the user through the landing page)
  const steps = [
    {
      target: "#this-is-a-fake-id-for-guided-tour",
      content: (
        <div className="font-inter">
          DO NOT DELETE THIS , IF YOU DELETE THIS , THERE WILL BE ISSUE WHILE
          ENDING TOUR AT FIRST STEP
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: "#dashboard",
      content: (
        <div className="font-inter">
          <div className="flex items-center mb-2">
            <img src={Bulb} alt="" className="mr-2 w-[14px]" />
            <h3 className="m-0 text-lg text-[#181D27] font-[600]">
              Your Dashboard
            </h3>
          </div>
          <p className="text-[14px] text-[#535862] leading-[20px] font-[400]">
            Get a quick overview of your LinkedIn’s account performance—toggle
            between the last <span className="font-[700]">24 hours</span> and{" "}
            <span className="font-[700]">all-time</span> stats.
          </p>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: "#linkedin",
      content: (
        <div className="font-inter">
          <div className="flex items-center mb-2">
            <img src={Hands} alt="" className="mr-2 w-[24px]" />
            <h3 className="m-0 text-lg text-[#181D27] font-[600]">
              {Object.values(linkedinAccountDetails || {})?.length > 0
                ? "Re-Connect"
                : "Connect your LinkedIn"}
            </h3>
          </div>
          <p className="text-[14px] text-[#535862] leading-[20px] font-[400]">
            {Object.values(linkedinAccountDetails || {})?.length > 0
              ? "Lost connection? Reconnect your account in just a few clicks at anytime!"
              : "Sign-in with your LinkedIn account to start running campaigns and utilizing all of Pipeline’s features!"}
          </p>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: "#prospecting",
      content: (
        <div className="font-inter">
          <div className="flex items-center mb-2">
            <img src={PeopleGroup} alt="" className="mr-2 w-[22px]" />
            <h3 className="m-0 text-lg text-[#181D27] font-[600]">
              Lead Lists{" "}
            </h3>
          </div>
          <p className="text-[14px] text-[#535862] leading-[20px] font-[400]">
            Build lists by importing data or prospecting by clicking{" "}
            <span className="font-[700]">Find Leads</span>. View all your lead
            lists here in one place.
          </p>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: "#createCampaign",
      content: (
        <div className="font-inter">
          <div className="flex items-center mb-2">
            <img src={Rocket} alt="" className="mr-2 w-[22px]" />
            <h3 className="m-0 text-lg text-[#181D27] font-[600]">
              Create a Campaign{" "}
            </h3>
          </div>
          <p className="text-[14px] text-[#535862] leading-[20px] font-[400]">
            Set up a campaign to start reaching out to your leads. You’ll see
            high level stats on the Campaigns tab and can click into any
            campaign to view more detailed analytics.
          </p>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: "#connections",
      content: (
        <div className="font-inter">
          <div className="flex items-center mb-2">
            <img src={World} alt="" className="mr-2 w-[22px]" />
            <h3 className="m-0 text-lg text-[#181D27] font-[600]">
              Your Network{" "}
            </h3>
          </div>
          <p className="text-[14px] text-[#535862] leading-[20px] font-[400]">
            View all your connections, manage sent invites, and set
            auto-withdraw schedules from your Connections tab.
          </p>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: "#settings",
      content: (
        <div className="font-inter">
          <div className="flex items-center mb-2">
            <img src={Settings} alt="" className="mr-2 w-[20px]" />
            <h3 className="m-0 text-lg text-[#181D27] font-[600]">Settings</h3>
          </div>
          <p className="text-[14px] text-[#535862] leading-[20px] font-[400]">
            Manage account details, workspace settings, notifications, and
            Pipeline preferences.
          </p>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: "#enrichmentCredits",
      content: (
        <div className="font-inter">
          <div className="flex items-center mb-2">
            <img src={Money} alt="" className="mr-2 w-[24px]" />
            <h3 className="m-0 text-lg text-[#181D27] font-[600]">
              Enrichment Credits{" "}
            </h3>
          </div>
          <p className="text-[14px] text-[#535862] leading-[20px] font-[400]">
            Use credits to enhance lead details and live-verify LinkedIn URLs to
            enroll leads into campaigns.
          </p>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: "#activityBar",
      content: (
        <div className="font-inter">
          <div className="flex items-center mb-2">
            <img src={Horn} alt="" className="mr-2 w-[24px]" />
            <h3 className="m-0 text-lg text-[#181D27] font-[600]">
              Activity Feed{" "}
            </h3>
          </div>
          <p className="text-[14px] text-[#535862] leading-[20px] font-[400]">
            Stay updated with a feed of your recent lead interactions in the
            past 24 hours.
          </p>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: "#pylon-chat",
      content: (
        <div className="font-inter">
          <div className="flex items-center mb-2">
            <img src={ChatBubble} alt="" className="mr-2 w-[24px]" />
            <h3 className="m-0 text-lg text-[#181D27] font-[600]">
              Chat with us anytime!{" "}
            </h3>
          </div>
          <p className="text-[14px] text-[#535862] leading-[20px] font-[400]">
            Got questions or need assistance? Our friendly support team is here
            to help—just click the chat bubble, and we'll be with you in no
            time!
          </p>
        </div>
      ),
      disableBeacon: true,
    },
  ];

  const handleJoyrideCallback = (data) => {
    const { action, index, origin, status, type } = data;

    // Navigate to the correct page when moving to specific steps
    if (action === ACTIONS.NEXT) {
      switch (index) {
        case 0:
          navigate("/dashboard");
          setCurrentStepIndex(0);
          setRunTour(true);
          break;
        case 1:
          navigate("/dashboard");
          setCurrentStepIndex(1);
          setRunTour(true);
          break;
        case 2:
          navigate("/accounts");
          setRunTour(true);
          setCurrentStepIndex(2);
          break;
        case 3:
          navigate("/prospecting");
          setRunTour(true);
          setCurrentStepIndex(3);
          break;
        case 4:
          navigate("/campaigns");
          setRunTour(true);
          setCurrentStepIndex(4);
          break;
        case 5:
          navigate("/connections");
          setRunTour(true);
          setCurrentStepIndex(5);
          break;
        case 6:
        case 7:
        case 8:
        case 9:
          navigate("/settings");
          setRunTour(true);
          setCurrentStepIndex(index);
          break;
        default:
          break;
      }
    }

    if (action === ACTIONS.PREV) {
    }

    if (
      (index === 0 && action === ACTIONS.CLOSE) ||
      (index === 0 && status === STATUS.SKIPPED) ||
      (index === 0 && origin === ORIGIN.CLOSE_BUTTON)
    ) {
      console.log("Exiting tour from first step");
      try {
        setRunTour(false);
        setCurrentStepIndex(0);
        setIsGuidedTourRunning(false);
        handleUpdateTour();
        navigate("/dashboard"); // Explicitly navigate to dashboard
        return;
      } catch (error) {
        console.error("Error while exiting tour from first step:", error);
      }
    }

    // Close the tour if using the keyboard close action
    if (action === ACTIONS.CLOSE && origin === ORIGIN.KEYBOARD) {
      setRunTour(false);
      handleUpdateTour();
    }

    // Handle step navigation
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      setCurrentStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Stop the tour when finished or skipped
      setRunTour(false);
      handleUpdateTour();
    }

    console.groupCollapsed(type);
    console.log(data); //eslint-disable-line no-console
    console.groupEnd();
  };

  const getUserProfile = async () => {
    try {
      const response = await getSettings.mutateAsync();
      const isAdmin = response?.user?.role_name !== "Team Members";
      setIsAdmin(isAdmin);
      setUserDetails({
        ...userDetails,
        ...response?.user,
        guided_tour: response?.user?.guided_tour,
        company: response?.company?.company_name,
      });
      if (response?.user?.guided_tour && !runTour) {
        navigate("/dashboard");
      }
      if (!runTour) {
        setIsGuidedTourRunning(response?.user?.guided_tour);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  return (
    <div className="flex bg-white">
      {runTour && (
        <Joyride
          callback={handleJoyrideCallback}
          steps={steps}
          run={runTour}
          continuous
          showSkipButton
          scrollToFirstStep
          hideCloseButton
          styles={{
            options: {
              zIndex: 10000,
            },
            buttonBack: {
              display: "none",
            },
            buttonSkip: {
              padding: "10px 20px",
              backgroundColor: "#fff",
              color: "#5A5F69",
              border: "1px solid #D1D5DB",
              borderRadius: "6px",
              fontWeight: "bold",
              fontSize: "14px",
              cursor: "pointer",
              marginRight: "8px",
              marginLeft: "auto",
              display: "block",
            },
            buttonNext: {
              padding: "10px 20px",
              backgroundColor: "#7F56D9",
              color: "#fff",
              border: "none",
              borderRadius: "6px",
              fontWeight: "bold",
              fontSize: "14px",
              cursor: "pointer",
            },
            tooltip: {
              padding: "16px",
              borderRadius: "12px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              right: currentStepIndex === 7 ? "15px" : "",
              // Apply top position for the first step
              top: currentStepIndex === 0 ? "-50px" : "", // Adjust the position as needed
            },
            tooltipContainer: {
              textAlign: "left",
            },
          }}
          disableOverlayClose
          disableCloseOnEsc
          locale={{
            skip: "Exit Tour",
            next: "Next",
            back: "Back",
            close: "Close",
            last: (
              <div className="flex">
                <span className="mt-[3px] text-[14px]">End Tour</span>
                <span>
                  <img src={Tada} alt="" className="w-[20px] ml-[5px]" />
                </span>
              </div>
            ),
          }}
        />
      )}

      <Sidebar isFailed={isFailed} />
      <div className={`flex-1 bg-[#fff] overflow-x-hidden innerScrollBar`}>
        {children}
      </div>
      <BillingFailure isOpen={isAdmin && isFailed} />
      <BillingFailureTeamMember isOpen={!isAdmin && isFailed} />
      {shouldShowSubscriptionModal && (
        <>
          <SubscriptionWasCancelled
            isOpen={isAdmin && isSubscriptionCanceled}
          />
          <BillingFailureTeamMember
            isOpen={!isAdmin && isSubscriptionCanceled}
          />
        </>
      )}
      {isGuidedTourRunning && (
        <GuidedTour
          isOpen={isGuidedTourRunning}
          onClose={() => {
            setIsGuidedTourRunning(false);
            setRunTour(false);
          }}
          setRunTour={setRunTour}
          handleUpdateTour={handleUpdateTour}
        />
      )}
    </div>
  );
};

export default Layout;
