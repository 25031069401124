const SwitchToggle = (props) => {
  const { checked, onChange, disabled, onKeyDown = () => null } = props;
  return (
    <>
      <label class="custom-switch">
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          onKeyDown={onKeyDown}
        />
        {disabled ? (
          <div className="disabled">
            <span className={`!cursor-not-allowed slider round`}></span>
          </div>
        ) : (
          <span className={`slider round`}></span>
        )}
      </label>
    </>
  );
};

export default SwitchToggle;
