import { useEffect, useState } from "react";
import copyIcon from "assets/images/copy-icon.svg";
import connectIcon from "assets/images/connect-rb2g.svg";
import OutlinedInput from "components/outlinedInput";
import { IoClose } from "react-icons/io5";
import Button from "components/button";
import { useRb2bEnrichLeads } from "services/rb2b-api";

const ConnectRB2BModal = ({
  isOpen,
  onClose,
  setStep,
  webHookURL,
  isLoading,
  setProspectId,
}) => {
  const Rb2bEnrichLeads = useRb2bEnrichLeads();
  const [copiedTooltipVisible, setCopiedTooltipVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [isOpen]);

  useEffect(() => {
    let timer;
    if (copiedTooltipVisible) {
      timer = setTimeout(() => {
        setCopiedTooltipVisible(false);
      }, 800);
    }
    return () => clearTimeout(timer);
  }, [copiedTooltipVisible]);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(webHookURL)
      .then(() => {
        setCopiedTooltipVisible(true);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handleNext = async () => {
    try {
      const response = await Rb2bEnrichLeads.mutateAsync();
      if (response?.status === 200) {
        setProspectId(response?.prospects_id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (!isOpen && !isModalVisible) return null;

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[400ms] ease-in-out ${
        isModalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className="fixed inset-0 bg-black opacity-10"
        onClick={onClose}
      ></div>
      <div
        className={`bg-white pt-[24px] rounded-lg shadow-lg z-10 w-full max-w-[426px] relative transition-all ease-in-out duration-[150ms] ${
          isModalVisible ? "scale-100" : "scale-95"
        }`}
      >
        <IoClose
          className="text-[#667085] text-[20px] w-[24px] h-[24px] absolute right-[15px] top-[15px] cursor-pointer"
          onClick={() => {
            onClose();
          }}
        />
        <img src={connectIcon} alt="connect rb2b" className="mx-auto" />
        <div className="modal-scroll px-[28px] pb-[18px]">
          <div className="mt-[16px] px-[10px]">
            <h4 className="font-[600] text-[18px] text-[#101828] text-center mb-[8px] font-inter">
              RB2B Integration
            </h4>
            <p className="font-normal text-[14px] text-[#475467] text-center font-inter">
              Automatically sync and populate your RB2B leads into a lead list
              in real-time so you can continuously run targeted campaigns on new
              leads.
            </p>
          </div>
          <h6 className="font-[600] text-[14px] text-[#475467] text-left mb-[13px] font-inter ml-2 mt-[29px]">
            Steps to Set-up Your Integration
          </h6>
          <ul className="ml-[17px] mb-[24px] list-decimal">
            <li className="text-[14px] font-[400] text-[#475467] leading-[20px] mb-[15px]">
              Navigate to RB2B Settings - Integrations:
            </li>
            <a
              href="https://app.rb2b.com/integrations/webhook"
              target="_blank"
              rel="noreferrer"
              className="text-[#7F56D9] block font-[500] text-[14px] mb-[15px] ml-[-10px]"
            >
              https://app.rb2b.com/integrations/webhook
            </a>
            <li className="text-[14px] font-[400] text-[#475467] leading-[20px] mb-[15px]">
              In RB2B you’ll see an input field to paste a Webhook.
            </li>
            <div className="mb-[15px]">
              <label className="text-[14px] font-[500] text-[#344054]">
                Your Webhook URL:
              </label>
              <div className="flex items-center justify-center mt-[6px]">
                <OutlinedInput
                  placeholder="Enter link"
                  className="!text-[#101828] !text-[16px] text-ellipsis overflow-hidden whitespace-nowrap max-w-full"
                  value={webHookURL}
                  isLoading={isLoading}
                />
                <div className="relative">
                  <img
                    src={copyIcon}
                    alt="copy icon"
                    className="w-[20px] h-[20px] min-w-[20px] mx-[14px] block cursor-pointer relative hover:opacity-60 transition-opacity duration-200 ease-in-out"
                    onClick={handleCopy}
                  />
                  {copiedTooltipVisible && (
                    <div
                      id="tooltip-hover"
                      role="tooltip"
                      className="absolute z-10 top-[-42px] px-3 py-[4px] text-[12px] font-[400] text-white bg-gray-900 rounded-[6px] shadow-sm border border-[#3C3C3C] transition-opacity duration-300 opacity-100"
                    >
                      Copied!
                    </div>
                  )}
                </div>
              </div>
            </div>
            <li className="text-[14px] font-[400] text-[#475467] leading-[20px] mb-[15px]">
              Paste your the above Webhook URL directly in the input field in
              RB2B and click save.
            </li>
          </ul>
          <p className="font-[300] text-[14px] text-[#475467] text-center font-inter italic">
            New leads that are synced from RB2B will automatically enrich and
            use credits.
          </p>
        </div>
        <div className="flex items-center justify-center border-t-[1.5px] p-[24px] border-[#E4E7EC]">
          <Button
            title="Next"
            secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9]"
            className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow"
            isRounded="6px"
            onClick={() => {
              handleNext();
              setStep(2);
            }}
            disabled={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default ConnectRB2BModal;
