import { useEffect, useState } from "react";
import ReactSelect from "components/select";
import hubspotIcon from "assets/images/Hubspot.svg";
import Button from "components/button";
import info from "assets/images/info.svg";
import arrow from "assets/images/arrow.png";
import { toast } from "react-toastify";
import Tick from "assets/images/name-check.svg";
import SwitchToggle from "components/switchToggle";
import Delete from "assets/images/delete.svg";
import { FaPlus } from "react-icons/fa6";
import {
  useLifeCycleStageDropdownData,
  useLifeCycleStagesBlockList,
  useSyncLifecycleStagesToBlockList,
  useHubspotCollaborators,
  useHubspotOwners,
  useLinkedinStatus,
  useLeadStatus,
  useContactPreference,
  useHubspotUsers,
  useGetHubspotUsers,
  useGetContactPrefrences,
  useGetContactProperties,
  useGetContactFields,
  useUpdateContactFieldsMappings,
} from "services/hubspot-api";

const contactPipleLineField = [
  "First Name",
  "Last Name",
  "Job Title",
  "Company",
  "Website",
  "LinkedIn URL",
];

const contactHubSpotField = [
  "First Name",
  "Last Name",
  "Job Title",
  "Company",
  "Website",
  "LinkedIn URL",
];

const navItems = ["Settings", "Contact", "Company"];
const pipleLineFieldUsers = ["Company Name", "Company Website"];
const hubSpotFieldUsers = ["Company Name", "Website URL"];

const IntegrationSettings = ({ setIsSwitchOn, setShowIntegrationSettings }) => {
  const syncLifeCycleStagesToBlockList = useSyncLifecycleStagesToBlockList();
  const contactPreference = useContactPreference();
  const mapHubspotUsers = useHubspotUsers();
  const updateContactFieldMappings = useUpdateContactFieldsMappings();

  const [selectedItem, setSelectedItem] = useState(navItems[0]);
  const [selects, setSelects] = useState([{ id: 1 }]);
  const [event, setEvent] = useState("");
  const eventList = [""];
  const lifeCycleStageList = [""];
  const leadStatusList = [""];
  const [contactEvent, setContactEvent] = useState(eventList);
  const [contactLifeCycleStage, setContactLifeCycleStage] =
    useState(lifeCycleStageList);
  const [contactLifeCycleStageValue, setContactLifeCycleStageValue] = useState(
    []
  );
  const [contactLeadStatusValue, setContactLeadStatusValue] = useState([]);
  const [contactLeadStatus, setContactLeadStatus] = useState(leadStatusList);
  const [lifeCycleStages, setLifeCycleStages] = useState([]);
  const [blockList, setblockList] = useState([]);
  const [isBlockListEnabled, setIsBlockListEnabled] = useState(false);
  const [isAccountOwnersEnabled, setIsAccountOwnersEnabled] = useState(false);
  const [isCreateNewContactEnabled, setIsCreateNewContactEnabled] =
    useState(false);
  const [isContactFieldMappingEnabled, setIsContactFieldMappingEnabled] =
    useState(false);
  const [selectedCollaborators, setSelectedCollaborators] = useState([]);
  const [collaboratorsData, setCollaboratorsData] = useState([]);
  const [selectedOwners, setSelectedOwners] = useState([]);
  const [hubOwnersData, setHubOwnersData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [hubspotAccountOwnersData, setHubSpotAccountOwnersData] = useState([]);
  const [
    contactFieldPipelineContactsMappingData,
    setContactFieldPipelineContactsMappingData,
  ] = useState([]);

  const [contactFieldHubspotMappingData, setContactFieldHubspotMappingData] =
    useState([]);

  const {
    data: LifeCycleStagesDropdownData,
    isPending: LifeCycleStagesDropdownDataIsPending,
  } = useLifeCycleStageDropdownData();

  const {
    data: LifeCycleStagesBlockList,
    isPending: LifeCycleStagesBlockListIsPending,
  } = useLifeCycleStagesBlockList();

  const { data: apiCollaboratorsData, isPending: collaboratorsPending } =
    useHubspotCollaborators();

  const { data: apiHubOwnersData, isPending: hubOwnerPending } =
    useHubspotOwners();

  const { data: linkedInStatus, isPending: linkedInStatusPending } =
    useLinkedinStatus();

  const { data: leadStatusData, isPending: leadStatusPending } =
    useLeadStatus();

  const { data: hubspotUsersData, isPending: hubspotUsersPending } =
    useGetHubspotUsers();

  const {
    data: userContactPrefrences,
    isPending: userContactPrefrencesPending,
  } = useGetContactPrefrences();

  const {
    data: userContactProperties,
    isPending: userContactPropertiesPending,
  } = useGetContactProperties();

  const { data: userContactFields, isPending: userContactFieldsPending } =
    useGetContactFields();

  useEffect(() => {
    if (userContactFields && userContactFields?.contact_mapping?.length > 0) {
      setIsContactFieldMappingEnabled(userContactFields?.enable_status);
      const contactResponse = userContactFields?.contact_mapping?.map(
        (contact) => {
          const findContactIndex = contactPipleLineField?.findIndex(
            (i) => i === contact?.pipeline_field
          );
          return { ...contact, id: findContactIndex };
        }
      );

      const pipelineContacts = contactResponse?.map((i) => ({
        label: i?.pipeline_field,
        value: i?.pipeline_field,
        id: i?.id,
      }));
      setContactFieldPipelineContactsMappingData(pipelineContacts);
      const hubspotContacts = contactResponse?.map((i) => ({
        label: i?.hubspot_field,
        value: i?.hubspot_field,
        id: i?.id,
      }));
      setContactFieldHubspotMappingData(hubspotContacts);
    } else {
      setIsContactFieldMappingEnabled(false);
      setContactFieldHubspotMappingData([]);
      setContactFieldPipelineContactsMappingData([]);
    }
  }, [userContactFields]);

  useEffect(() => {
    if (hubspotUsersData && hubspotUsersData?.hubspot?.length > 0) {
      setHubSpotAccountOwnersData(hubspotUsersData?.hubspot);
      setIsAccountOwnersEnabled(hubspotUsersData?.enable_status);
    } else {
      setHubSpotAccountOwnersData([""]);
      setIsAccountOwnersEnabled(false);
      setSelectedOwners([]);
      setCollaboratorsData([]);
    }
  }, [hubspotUsersData]);

  useEffect(() => {
    if (
      userContactPrefrences &&
      !linkedInStatusPending &&
      !LifeCycleStagesDropdownDataIsPending &&
      !leadStatusPending
    ) {
      setIsCreateNewContactEnabled(userContactPrefrences?.enable_status);
      if (userContactPrefrences?.preference_items?.length > 0) {
        /** Contact Event Prefill */
        let updatedContactPrefrences =
          userContactPrefrences?.preference_items?.map(
            (i) => i?.linkedin_status_id
          );
        let result = updatedContactPrefrences
          ?.map((id) => {
            const matchedStatus = linkedInStatus.find(
              (status) => status?.id === id
            );

            return matchedStatus ? matchedStatus?.status_name : null;
          })
          .filter((item) => item !== null);

        setContactEvent(result);
        /* END OF CONTACT EVENT PREFILL */

        /** Contact Lifecycle Stages Prefill */
        let updatedContactLifeCycle =
          userContactPrefrences?.preference_items?.map(
            (i) => i?.lifecycle_stage
          );
        let lifecycleResult = updatedContactLifeCycle
          ?.map((label) => {
            const matchedLabel =
              LifeCycleStagesDropdownData?.lifecycle_stages?.find(
                (item) => item?.value === label
              );
            return matchedLabel ? matchedLabel?.label : null;
          })
          .filter((item) => item !== null);
        setContactLifeCycleStage(lifecycleResult);
        /* END OF CONTACT LIFECYCLE STAGES PREFILL */

        /** Contact Lifecycle Stages Prefill */
        let updatedContactLeadStatus =
          userContactPrefrences?.preference_items?.map((i) => i?.lead_status);
        let leadStatusResult = updatedContactLeadStatus
          ?.map((label) => {
            const matchedLabel = leadStatusData?.lead_statuses?.find(
              (item) => item?.value === label
            );
            return matchedLabel ? matchedLabel?.label : null;
          })
          .filter((item) => item !== null);
        setContactLeadStatus(leadStatusResult);
        /* END OF CONTACT LIFECYCLE STAGES PREFILL */

        setSelectedItems(userContactPrefrences?.preference_items || []);
      }
    }
  }, [
    userContactPrefrences,
    linkedInStatusPending,
    LifeCycleStagesDropdownDataIsPending,
    leadStatusPending,
  ]);

  useEffect(() => {
    if (apiCollaboratorsData) {
      setCollaboratorsData(apiCollaboratorsData);
    }
  }, [apiCollaboratorsData]);

  useEffect(() => {
    if (apiHubOwnersData) {
      setHubOwnersData(apiHubOwnersData);
    }
  }, [apiHubOwnersData]);

  useEffect(() => {
    if (LifeCycleStagesBlockList) {
      if (
        LifeCycleStagesBlockList?.[0]?.stages?.length === 0 ||
        LifeCycleStagesBlockList?.length === 0
      ) {
        setblockList([]);
        setSelects([{ id: 1 }]);
        setIsBlockListEnabled(false);
      } else {
        setIsBlockListEnabled(
          LifeCycleStagesBlockList[0]?.enable_status || false
        );
        let data = LifeCycleStagesBlockList[0]?.stages?.map((i, index) => ({
          label: i,
          value: i?.toLowerCase()?.split(" ")?.join()?.replace(/,/g, ""),
          id: index + 1,
        }));
        setblockList(data);
        setSelects(data?.map((_, index) => ({ id: index + 1 })));
      }
    }
  }, [LifeCycleStagesBlockList]);

  const handleSetContactPreference = async () => {
    try {
      const response = await contactPreference?.mutateAsync({
        enable_status: isCreateNewContactEnabled,
        preference_items: selectedItems?.length > 0 ? selectedItems : [],
      });

      if (response?.status === 200) {
        toast.success("User Contact Prefrences Saved Successfully");
      } else if (response?.status === 500) {
        toast.success(response?.message);
      }
      console.log(response, "response");
    } catch (error) {
      console.log(error);
    }
  };

  const handleSyncLifeCycleStages = async () => {
    try {
      const payload = {
        stages: blockList?.length === 0 ? [] : blockList?.map((i) => i?.label),
        enable_status: isBlockListEnabled,
      };
      await syncLifeCycleStagesToBlockList?.mutateAsync(payload);
      toast.success("HubSpot Integration Updated", {
        icon: () => <img src={Tick} alt="name" />,
      });
    } catch (error) {
      toast.success(error?.data?.detail || "Something went wrong", {});
    }
  };

  const mapHubspotUsersToDB = async () => {
    const payload = {
      hubspot: selectedCollaborators?.map((item, index) => {
        return {
          hubspot_user_id: selectedOwners?.[index]?.userId?.toString(),
          hubspot_user_email: selectedOwners?.[index]?.email,
          hubspot_owner_id: selectedOwners?.[index]?.ownerId?.toString(),
          pipeline_user_id: item?.user_id,
          enable_status: false,
        };
      }),
      enable_status: isAccountOwnersEnabled,
    };
    try {
      await mapHubspotUsers?.mutateAsync(payload);
      toast.success("HubSpot Integration Updated", {
        icon: () => <img src={Tick} alt="name" />,
      });
    } catch (error) {
      toast.success(error?.data?.detail || "Something went wrong", {});
    }
  };

  const handleUpdateContactFieldMappings = async () => {
    try {
      const contact_mapping = contactFieldPipelineContactsMappingData
        ?.map((pipelineField, index) => ({
          pipeline_field: pipelineField?.label || "",
          hubspot_field: contactFieldHubspotMappingData?.[index]?.label || "",
        }))
        ?.filter(Boolean);

      const inValidPipelineContactsMapping = contact_mapping
        ?.map((i) => i?.pipeline_field)
        ?.some((j) => j?.length === 0);
      const inValidHubspotContactsMapping = contact_mapping
        ?.map((i) => i?.hubspot_field)
        ?.some((j) => j?.length === 0);

      if (inValidPipelineContactsMapping || inValidHubspotContactsMapping) {
        toast.success("Some pipeline or HubSpot fields are empty.");
        return;
      }

      if (isContactFieldMappingEnabled && contact_mapping?.length === 0) {
        toast.success(
          "Please Select the Contact Field Mapping or toggle off the option"
        );
        return;
      }

      const payload = {
        enable_status: isContactFieldMappingEnabled,
        contact_mapping: contact_mapping,
      };

      await updateContactFieldMappings?.mutateAsync(payload);
      toast.success("User Contact Field Mappings Saved Successfully");
    } catch (error) {
      console.error(
        "Something Went Wrong While Saving the Contact Field Mappings"
      );
    }
  };

  useEffect(() => {
    if (LifeCycleStagesDropdownData) {
      setLifeCycleStages(LifeCycleStagesDropdownData?.lifecycle_stages);
    }
  }, [LifeCycleStagesDropdownData]);

  const handleSaveButton = () => {
    setIsSwitchOn(true);
    setShowIntegrationSettings(false);
  };

  const addSelect = () => {
    setSelects([...selects, { id: selects[selects.length - 1]?.id + 1 }]);
  };

  const removeSelect = (index) => {
    const updatedSelects = selects.filter((_, i) => _?.id !== index);
    setSelects(updatedSelects);
    const updatedBlockList = blockList?.filter((_, i) => _?.id !== index);
    setblockList(updatedBlockList);
  };

  const AddMoreEvent = () => {
    const filterOptions = linkedInStatus
      ?.map((item) => item?.label)
      ?.filter((option) =>
        event ? ![event]?.includes(option) : !contactEvent?.includes(option)
      );
    // return
    setContactEvent([...contactEvent, ""]);
    setContactLifeCycleStage([...contactLifeCycleStage, ""]);
    setContactLeadStatus([...contactLeadStatus, ""]);
  };

  const removeMoreEvent = (index) => {
    const updatedContactEvent = contactEvent.filter((_, i) => i !== index);
    const updatedContactLifeCycleStage = contactLifeCycleStage.filter(
      (_, i) => i !== index
    );
    const updatedContactLifeCycleStageValue =
      contactLifeCycleStageValue?.filter((_, i) => i !== index);
    const updatedcontactLeadStatusValue = contactLeadStatusValue?.filter(
      (_, i) => i !== index
    );
    const updatedContactLeadStatus = contactLeadStatus.filter(
      (_, i) => i !== index
    );
    setContactEvent(updatedContactEvent);
    setContactLifeCycleStage(updatedContactLifeCycleStage);
    setContactLeadStatus(updatedContactLeadStatus);
    setContactLifeCycleStageValue(updatedContactLifeCycleStageValue);
    setContactLeadStatusValue(updatedcontactLeadStatusValue);
  };

  const updateData = (key, value, index = null) => {
    setSelectedItems((prevData) => {
      if (index !== null) {
        // Update the specific item at the given index
        const updatedItems = [...prevData];
        updatedItems[index] = {
          ...updatedItems[index],
          [key]: value,
        };
        return updatedItems;
      } else {
        // Update the key-value pair globally in the selectedItems object
        return {
          ...prevData,
          [key]: value,
        };
      }
    });
  };

  return (
    <div className="pt-7 campaign-toggle">
      <div className="pb-[21px] border-[#E4E7EC] mb-[6px]">
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-row justify-start items-center">
            <img
              src={hubspotIcon}
              alt="HubSpot"
              className="h-[24px] w-[24px]"
            />
            <h4 className="font-[600] text-[#101828] text-[18px] ml-[5px]">
              HubSpot
            </h4>
          </div>
          <div className="flex justify-between items-center">
            <Button
              title={
                syncLifeCycleStagesToBlockList?.isPending ||
                mapHubspotUsers?.isPending ? (
                  <div className="w-[12px] ml-[36px] h-[12px] border-[2px] border-t-[3px] border-[#fff] border-solid rounded-full animate-spin border-t-[#8A43FF]" />
                ) : (
                  "Save Changes"
                )
              }
              onClick={() => {
                if (selectedItem === "Settings") {
                  handleSyncLifeCycleStages();
                  mapHubspotUsersToDB();
                } else {
                  handleSetContactPreference();
                  handleUpdateContactFieldMappings();
                }
              }}
              secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9]"
              className="!text-[12px] !h-[33.71px] !w-[111px] !font-[600] y-axis-shadow !rounded-[8px]"
            />
          </div>
        </div>
        <p className="font-[400] text-[#475467] text-[14px] mt-[5px]">
          Configure and map your HubSpot to Pipeline.
        </p>
      </div>

      <div>
        <div className="w-[366px] h-[40px] border border-[#D0D5DD] rounded-[8px] flex overflow-hidden">
          {navItems.map((item, index) => (
            <div
              key={index}
              className={`flex-1 flex items-center ${
                selectedItem === item && "bg-[#F9FAFB]"
              }`}
              onClick={() => setSelectedItem(item)}
            >
              <div className="flex items-center flex-1 justify-center">
                {selectedItem === item && (
                  <div
                    className={`w-[8px] h-[8px] rounded-full bg-[#17B26A] mr-2 ${
                      selectedItem === item ? "opacity-100" : "opacity-0"
                    } transition-opacity duration-300`}
                  ></div>
                )}
                <p className="text-center text-[14px] font-[600] font-inter cursor-pointer text-[#182230] mt-[-2px]">
                  {item}
                </p>
              </div>
              {index < navItems.length - 1 && (
                <div className="border-r border-[#D0D5DD] h-full"></div>
              )}
            </div>
          ))}
        </div>
      </div>
      {selectedItem === "Settings" && (
        <div className="mt-[33px]">
          <div className="flex flex-row justify-start items-center ml-[12px]">
            <SwitchToggle
              checked={isBlockListEnabled}
              onChange={() => setIsBlockListEnabled(!isBlockListEnabled)}
            />
            <h4 className="font-[600] text-[#101828] text-[18px] ml-[15px]">
              Blocklist
            </h4>
          </div>
          <p className="font-[400] text-[#475467] text-[14px] mt-[10px] ml-[12px]">
            Leads from the Lifecycle stage you select below will be added to
            Pipeline’s blocklist:
          </p>
          <div
            className={`my-[20px] flex hubspot-select newCampaign-select items-end ${
              !isBlockListEnabled && "opacity-30"
            }`}
          >
            <div className="flex flex-col">
              {selects?.map((select, index, arr) => (
                <div key={select.id} className="flex items-center">
                  <div
                    className={`${
                      arr?.length - 1 > index && "mb-[20px]"
                    } w-[374px] h-[44px] ml-[12px]`}
                  >
                    <ReactSelect
                      readOnly={!isBlockListEnabled}
                      options={lifeCycleStages?.filter(
                        (stage) =>
                          !blockList?.some(
                            (block) => block?.value === stage?.value
                          )
                      )}
                      placeholder="Lifecycle Stages"
                      onChange={(selectedOption) => {
                        const updatedBlockList = [...blockList]; // Create a copy of the current blockList
                        updatedBlockList[index] = {
                          ...selectedOption,
                          id: select?.id,
                        }; // Update the specific index with the selected value
                        setblockList(updatedBlockList); // Update the state with the new blockList
                      }}
                      isLoading={LifeCycleStagesDropdownDataIsPending}
                      value={blockList?.find((i) => i?.id === select?.id)}
                    />
                  </div>
                  {arr?.length - 1 !== index ? (
                    <img
                      src={Delete}
                      alt=""
                      className={`w-[20px] h-[20px] ml-[20px] mb-[20px] ${
                        !isBlockListEnabled
                          ? "cursor-not-allowed"
                          : "cursor-pointer"
                      }`}
                      onClick={() => {
                        if (!isBlockListEnabled) {
                          return;
                        }
                        removeSelect(select?.id);
                      }}
                    />
                  ) : (
                    lifeCycleStages?.filter(
                      (stage) =>
                        !blockList?.some(
                          (block) => block?.value === stage?.value
                        )
                    )?.length !== 0 && (
                      <div
                        className={`relative flex items-center mx-[20px]  ${
                          !isBlockListEnabled
                            ? "cursor-not-allowed"
                            : "cursor-pointer"
                        }`}
                        onClick={() => {
                          if (!isBlockListEnabled) {
                            return;
                          }
                          if (blockList?.find((i) => i?.id === select?.id)) {
                            addSelect();
                          } else {
                            toast.success(
                              "Please select a Lifecycle Stage before adding another."
                            );
                          }
                        }}
                      >
                        <FaPlus className="mt-[-2px] text-[#808897] text-[15px]" />
                        <p className="ml-[3px] text-[#808897] text-[13.7px] font-[500] mb-0">
                          Add More
                        </p>
                      </div>
                    )
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="flex justify-start items-center ml-2">
            <img
              src={info}
              alt="info"
              className="mb-[30px] mr-[3px] w-[14px] h-[14px]"
            />
            <p className="w-[400px] h-[59px] font-[400] text-[#808897] text-[14px] mt-[7px] font-inter">
              This is to make sure you do not enroll leads into a campaign that
              are already your customers or in a current deal.
            </p>
          </div>
          <div className="flex flex-row justify-start items-center mt-[26px] ml-[12px]">
            <SwitchToggle
              checked={isAccountOwnersEnabled}
              onChange={() =>
                setIsAccountOwnersEnabled(!isAccountOwnersEnabled)
              }
            />
            <h4 className="font-[600] text-[#101828] text-[18px] ml-[15px]">
              Account Owners
            </h4>
          </div>
          <p className="font-[400] text-[#475467] text-[14px] mt-[10px] ml-[12px]">
            Map Pipeline users to Account Owners in HubSpot
          </p>
          <div className="flex flex-row w-[900px] justify-between items-center">
            <div className="flex-1">
              <p className="font-[400] text-[#666D80] text-[14px] mt-[20px] ml-[12px]">
                Pipeline User
              </p>
              {hubspotAccountOwnersData?.map((user, index) => {
                const activeUser = collaboratorsData.length
                  ? collaboratorsData?.filter(
                      (item) => item?.user_id === user?.pipeline_user_id
                    )?.[index]?.name
                  : "";

                return (
                  <div className="relative">
                    <div
                      className={`w-[374px] h-[44px] my-[20px] flex hubspot-select newCampaign-select ml-3 ${
                        !isAccountOwnersEnabled && "opacity-30"
                      }`}
                      key={index}
                    >
                      <ReactSelect
                        options={
                          !collaboratorsPending
                            ? collaboratorsData?.map((item) => ({
                                label: item?.name,
                                value: item?.name,
                                email: item?.email,
                                user_id: item?.user_id,
                              }))
                            : [
                                {
                                  label: "",
                                  value: "",
                                },
                              ]
                        }
                        value={
                          activeUser?.length > 0
                            ? { label: activeUser, value: activeUser }
                            : null || selectedCollaborators?.[index]
                        }
                        placeholder="Select Pipeline User"
                        onChange={(selectedOption) => {
                          const updatedSelectedCollaborators = [
                            ...selectedCollaborators,
                          ];
                          updatedSelectedCollaborators[index] = {
                            ...selectedOption,
                          };
                          setSelectedCollaborators(
                            updatedSelectedCollaborators
                          );
                        }}
                        readOnly={!isAccountOwnersEnabled}
                      />
                    </div>
                    <div className="absolute inset-y-0 right-0 transform top-0 flex items-center">
                      <img
                        src={arrow}
                        alt="arrow"
                        className="w-[12px] ml-[33px]"
                      />
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="flex-1 ml-[28px]">
              <p className="font-[400] text-[#666D80] text-[14px] mt-[20px] ml-[12px]">
                HubSpot Account Owner
              </p>
              {hubspotAccountOwnersData?.map((owner, index) => {
                const activeUser = collaboratorsData?.length
                  ? hubOwnersData?.owners?.filter(
                      (item) =>
                        String(item?.ownerId) === owner?.hubspot_owner_id
                    )?.[index]
                  : "";

                const name =
                  activeUser !== ""
                    ? `${activeUser?.firstName} ${activeUser?.lastName}`
                    : "";
                return (
                  <div
                    className={`w-[374px] h-[44px] my-[20px] flex hubspot-select newCampaign-select ml-3 ${
                      !isAccountOwnersEnabled && "opacity-30"
                    }`}
                    key={index}
                  >
                    <ReactSelect
                      options={
                        !hubOwnerPending
                          ? hubOwnersData?.owners?.map((item) => ({
                              label: `${item?.firstName} ${item?.lastName}`,
                              value: `${item?.firstName} ${item?.lastName}`,
                              email: item?.email,
                              ownerId: item?.ownerId,
                              userId: item?.activeUserId,
                            }))
                          : [
                              {
                                label: "",
                                value: "",
                              },
                            ]
                      }
                      value={
                        name?.length > 0 && activeUser
                          ? { label: name, value: name }
                          : null || selectedOwners?.[index]
                      }
                      placeholder="Select HubSpot Account Owner"
                      onChange={(option) => {
                        const updatedSelectedOwners = [...selectedOwners];
                        updatedSelectedOwners[index] = {
                          ...option,
                        };
                        setSelectedOwners(updatedSelectedOwners);
                      }}
                      readOnly={!isAccountOwnersEnabled}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
      {selectedItem === "Contact" && (
        <div className="mt-[33px] contactPage">
          <div className="flex flex-row justify-start items-center ml-[12px]">
            <SwitchToggle
              checked={isCreateNewContactEnabled}
              onChange={() =>
                setIsCreateNewContactEnabled(!isCreateNewContactEnabled)
              }
            />
            <h4 className="font-[600] text-[#101828] text-[18px] ml-[5px]">
              Create New Contact
            </h4>
          </div>
          <p className="font-[400] text-[#475467] text-[14px] mt-[10px] ml-[12px]">
            Pipeline will create a new contact when the following events occur
            for a lead.{" "}
          </p>

          <div className="flex justify-start items-center ml-3 mt-1">
            <img
              src={info}
              alt="info"
              className="mt-[4px] mr-[3px] w-[14px] h-[16px]"
            />
            <p className="font-[400] text-[#808897] text-[14px] mt-[7px] font-inter">
              The Pipeline user that reaches out to a contact will be assigned
              as the account owner.
            </p>
          </div>
          <div className="flex flex-row w-full items-center">
            <div className="">
              <p className="font-[400] text-[#666D80] text-[14px] mt-[22px] ml-[12px]">
                Event{" "}
              </p>
              {contactEvent.map((user, index) => (
                <div className="relative w-[435px]">
                  <div
                    className={`w-[374px] h-[44px] my-[20px] flex hubspot-select newCampaign-select ml-3 ${
                      !isCreateNewContactEnabled && "opacity-30"
                    }`}
                    key={index}
                  >
                    <ReactSelect
                      options={
                        !linkedInStatusPending
                          ? linkedInStatus?.map((item) => ({
                              label: item?.status_name,
                              value: item?.id,
                            }))
                          : [
                              {
                                label: "",
                                value: "",
                              },
                            ]
                      }
                      placeholder={user?.length === 0 ? "Select Event" : ""}
                      value={
                        user?.length === 0 ? null : { label: user, value: user }
                      }
                      // onChange={(option) => {
                      //   setSelectedCollaborators(option);
                      // }}
                      onChange={(option) => {
                        const updatedContactEvent = [...contactEvent]; // Create a shallow copy of the state
                        updatedContactEvent[index] = option?.label; // Update the specific index with the new label
                        setContactEvent(updatedContactEvent); // Update the state with the modified array
                        updateData("linkedin_status_id", option?.value, index); // Call updateData with the appropriate parameters
                      }}
                      readOnly={!isCreateNewContactEnabled}
                    />
                  </div>
                  <div className="absolute inset-y-0 right-[0px] transform top-0 flex items-center">
                    <img
                      src={arrow}
                      alt="arrow"
                      className="w-[12px] ml-[33px]"
                    />
                  </div>
                </div>
              ))}
            </div>

            <div className="ml-[31px]">
              <p className="font-[400] text-[#666D80] text-[14px] mt-[22px] ml-[12px]">
                Lifecycle Stage{" "}
              </p>
              {contactLifeCycleStage.map((user, index) => (
                <div className="relative w-[450px]">
                  <div
                    className={`w-[374px] h-[44px] my-[20px] flex hubspot-select newCampaign-select ml-3 ${
                      !isCreateNewContactEnabled && "opacity-30"
                    }`}
                    key={index}
                  >
                    <ReactSelect
                      options={
                        !LifeCycleStagesDropdownDataIsPending
                          ? LifeCycleStagesDropdownData?.lifecycle_stages
                          : [
                              {
                                label: "",
                                value: "",
                              },
                            ]
                      }
                      value={
                        user?.length === 0 ? null : { label: user, value: user }
                      }
                      placeholder={
                        user?.length === 0 ? "Select Lifecycle Stage" : ""
                      }
                      onChange={(option) => {
                        const updatedContactLifecycleStages = [
                          ...contactLifeCycleStage,
                        ]; // Create a shallow copy of the array
                        updatedContactLifecycleStages[index] = option?.label; // Update the label at the specified index
                        setContactLifeCycleStage(updatedContactLifecycleStages); // Update the state with the modified array
                        updateData("lifecycle_stage", option?.value, index); // Call updateData with the new value
                      }}
                      readOnly={!isCreateNewContactEnabled}
                    />
                  </div>
                  <div className="absolute inset-y-0 right-[15px] transform top-0 flex items-center">
                    <img
                      src={arrow}
                      alt="arrow"
                      className="w-[12px] ml-[33px]"
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="ml-[12px]">
              <p className="font-[400] text-[#666D80] text-[14px] mt-[22px] ml-[12px]">
                Lead Status{" "}
              </p>
              {contactLeadStatus.map((user, index) => (
                <div className="relative">
                  <div
                    className={`w-[374px] h-[44px] my-[20px] flex hubspot-select newCampaign-select ml-3 ${
                      !isCreateNewContactEnabled && "opacity-30"
                    }`}
                    key={index}
                  >
                    <ReactSelect
                      options={
                        !leadStatusPending
                          ? leadStatusData?.lead_statuses?.map((item) => ({
                              label: item?.label,
                              value: item?.value,
                            }))
                          : [
                              {
                                label: "",
                                value: "",
                              },
                            ]
                      }
                      placeholder={
                        user?.length === 0 ? "Select Lead Status" : ""
                      }
                      value={
                        user?.length === 0 ? null : { label: user, value: user }
                      }
                      onChange={(option) => {
                        const updatedContactLeadStatus = [...contactLeadStatus]; // Create a shallow copy of the state array
                        updatedContactLeadStatus[index] = option?.label; // Update the specific index with the new label
                        setContactLeadStatus(updatedContactLeadStatus); // Update the state with the modified array
                        updateData("lead_status", option?.value, index); // Call updateData with the new value
                      }}
                      readOnly={!isCreateNewContactEnabled}
                    />
                  </div>
                  {/* <div className="absolute inset-y-0 right-0 transform top-0 flex items-center">
                    <img
                      src={arrow}
                      alt="arrow"
                      className="w-[12px] ml-[33px]"
                    />
                  </div> */}
                  {contactLeadStatus?.length - 1 !== index && (
                    <img
                      src={Delete}
                      alt=""
                      className="w-[20px] h-[20px] ml-[20px] mb-[20px] cursor-pointer absolute right-[-30px] top-[10px]"
                      onClick={() => removeMoreEvent(index)}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
          {contactEvent?.length < 2 && (
            <div
              className={`relative flex items-center justify-end mx-[20px] ${
                isCreateNewContactEnabled
                  ? "cursor-pointer"
                  : "cursor-not-allowed"
              }  w-[120px] ml-auto pr-[10px]`}
              onClick={() => {
                if (isCreateNewContactEnabled) {
                  AddMoreEvent();
                } else {
                  return;
                }
              }}
            >
              <FaPlus className="mt-[-2px] text-[#808897] text-[15px]" />
              <p className="ml-[3px] text-[#808897] text-[13.7px] font-[500] mb-0">
                Add More
              </p>
            </div>
          )}
          <div className="flex flex-row justify-start items-center mt-8 ml-[12px]">
            <SwitchToggle
              checked={isContactFieldMappingEnabled}
              onChange={() =>
                setIsContactFieldMappingEnabled(!isContactFieldMappingEnabled)
              }
            />
            <h4 className="font-[600] text-[#101828] text-[18px] ml-[5px]">
              Contact Field Mapping{" "}
            </h4>
          </div>
          <p className="font-[400] text-[#475467] text-[14px] mt-[10px] ml-[12px]">
            Map the fields below to configure how companies are synced{" "}
          </p>
          <div className="flex justify-start items-center ml-3 mt-1">
            <img
              src={info}
              alt="info"
              className="mt-[4px] mr-[3px] w-[14px] h-[14px]"
            />
            <p className="font-[400] text-[#808897] text-[14px] mt-[7px] font-inter">
              The Pipeline user that reaches out to a contact will be assigned
              as the account owner.
            </p>
          </div>
          <div className="flex flex-row w-[900px] justify-between items-center">
            <div className="flex-1">
              <p className="font-[400] text-[#666D80] text-[14px] mt-[22px] ml-[12px]">
                Pipeline Field{" "}
              </p>
              {contactPipleLineField.map((user, index) => (
                <div
                  className={`relative ${
                    !isContactFieldMappingEnabled && "opacity-70"
                  }`}
                >
                  <div
                    className="w-[374px] h-[44px] my-[20px] flex hubspot-select newCampaign-select ml-3"
                    key={index}
                  >
                    <ReactSelect
                      options={[
                        {
                          label: user,
                          value: user,
                        },
                      ]}
                      placeholder={user}
                      value={contactFieldPipelineContactsMappingData?.find(
                        (i) => i?.id === index
                      )}
                      onChange={(selectedOption) => {
                        let updatedData = [
                          ...contactFieldPipelineContactsMappingData,
                        ];
                        const updatedDataIndex = updatedData.findIndex(
                          (i) => i?.id === index
                        );
                        if (updatedDataIndex !== -1) {
                          updatedData[updatedDataIndex] = {
                            ...selectedOption,
                            id: index,
                          };
                        } else {
                          updatedData = [
                            ...updatedData,
                            {
                              ...selectedOption,
                              id: index,
                            },
                          ];
                        }
                        setContactFieldPipelineContactsMappingData(updatedData);
                      }}
                      placement="auto"
                      readOnly={!isContactFieldMappingEnabled}
                    />
                  </div>
                  <div className="absolute inset-y-0 right-0 transform top-0 flex items-center">
                    <img
                      src={arrow}
                      alt="arrow"
                      className="w-[12px] ml-[33px]"
                    />
                  </div>
                </div>
              ))}
            </div>

            <div className="flex-1 ml-[28px]">
              <p className="font-[400] text-[#666D80] text-[14px] mt-[22px] ml-[12px]">
                HubSpot Field{" "}
              </p>
              {contactHubSpotField.map((owner, index) => (
                <div
                  className={`relative ${
                    !isContactFieldMappingEnabled && "opacity-70"
                  }`}
                >
                  <div
                    className="w-[374px] h-[44px] my-[20px] flex hubspot-select newCampaign-select ml-3"
                    key={index}
                  >
                    <ReactSelect
                      options={
                        !userContactPropertiesPending
                          ? userContactProperties?.all_contact_properties?.map(
                              (item) => ({
                                label: item?.label,
                                value: item?.name,
                              })
                            )
                          : [
                              {
                                label: "",
                                value: "",
                              },
                            ]
                      }
                      placeholder={owner}
                      value={contactFieldHubspotMappingData?.find(
                        (i) => i?.id === index
                      )}
                      placement="auto"
                      onChange={(selectedOption) => {
                        let updatedData = [...contactFieldHubspotMappingData];
                        const updatedDataIndex = updatedData.findIndex(
                          (i) => i?.id === index
                        );
                        if (updatedDataIndex !== -1) {
                          updatedData[updatedDataIndex] = {
                            ...selectedOption,
                            id: index,
                          };
                        } else {
                          updatedData = [
                            ...updatedData,
                            {
                              ...selectedOption,
                              id: index,
                            },
                          ];
                        }
                        setContactFieldHubspotMappingData(updatedData);
                      }}
                      readOnly={!isContactFieldMappingEnabled}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {selectedItem === "Company" && (
        <div className="mt-[33px]">
          <div className="flex flex-row justify-start items-center ml-[12px]">
            <SwitchToggle />
            <h4 className="font-[600] text-[#101828] text-[18px] ml-[5px]">
              Create New Company
            </h4>
          </div>
          <p className="font-[400] text-[#475467] text-[14px] mt-[10px] ml-[12px]">
            Pipeline will create a new company when a Contact is created
          </p>

          <div className="flex justify-start items-center ml-3 mt-1">
            <img
              src={info}
              alt="info"
              className="mt-[4px] mr-[3px] w-[14px] h-[14px]"
            />
            <p className="font-[400] text-[#808897] text-[14px] mt-[7px] font-inter">
              If the company already exists, the new contact will be added under
              the existing Company in HubSpot.
            </p>
          </div>
          <div className="flex flex-row justify-start items-center mt-8 ml-[12px]">
            <SwitchToggle />
            <h4 className="font-[600] text-[#101828] text-[18px] ml-[5px]">
              Company Field Mapping{" "}
            </h4>
          </div>
          <p className="font-[400] text-[#475467] text-[14px] mt-[10px] ml-[12px]">
            Map the fields below to configure how companies are synced{" "}
          </p>
          <div className="flex flex-row w-[900px] justify-between items-center">
            <div className="flex-1">
              <p className="font-[400] text-[#666D80] text-[14px] mt-[23px] ml-[12px]">
                Pipeline Field{" "}
              </p>
              {pipleLineFieldUsers.map((user, index) => (
                <div className="relative">
                  <div
                    className="w-[374px] h-[44px] my-[20px] flex hubspot-select newCampaign-select ml-3"
                    key={index}
                  >
                    <ReactSelect
                      options={[
                        {
                          label: user,
                          value: user,
                        },
                      ]}
                      placeholder={user}
                      onChange={(event) => console.log(event, "event")}
                    />
                  </div>
                  <div className="absolute inset-y-0 right-0 transform top-0 flex items-center">
                    <img
                      src={arrow}
                      alt="arrow"
                      className="w-[12px] ml-[33px]"
                    />
                  </div>
                </div>
              ))}
            </div>

            <div className="flex-1 ml-[28px]">
              <p className="font-[400] text-[#666D80] text-[14px] mt-[23px] ml-[12px]">
                HubSpot Field{" "}
              </p>
              {hubSpotFieldUsers.map((owner, index) => (
                <div
                  className="w-[374px] h-[44px] my-[20px] flex hubspot-select newCampaign-select ml-3"
                  key={index}
                >
                  <ReactSelect
                    options={[
                      {
                        label: owner,
                        value: owner,
                      },
                    ]}
                    placeholder={owner}
                    onChange={(event) => console.log(event, "event")}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default IntegrationSettings;
