import { useMutation, useQuery } from "@tanstack/react-query";
import axiosInt from "services";

const useProspectSearch = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.post("/prospect/search", data);
      return response;
    },
  });

const useGetProspectLeads = () =>
  useMutation({
    mutationFn: async ({ data, limit, page, sort_by, sort_order }) => {
      const response = await axiosInt.post(
        `/prospect/get_prospect_leads?limit=${limit}&page=${page}&sort_by=${sort_by}&sort_order=${sort_order}`,
        data
      );
      return response;
    },
  });

const useGetLeadInfo = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.post("/prospect/lead_info", data);
      return response;
    },
  });
const useGetLeadHistoryInfo = () =>
  useMutation({
    mutationFn: async (data) => {
      const { lead_id, prospect_lead_id } = data;

      let queryParams = {};

      if (lead_id) {
        queryParams.lead_id = lead_id;
      }

      if (prospect_lead_id) {
        queryParams.prospect_lead_id = prospect_lead_id;
      }

      const queryString = new URLSearchParams(queryParams).toString();
      if (lead_id || prospect_lead_id) {
        const response = await axiosInt.get(
          `/outreach_v2/lead_history?${queryString}`
        );
        return response;
      }
    },
  });

const useCreateProspect = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.post("/prospect/create_prospect", data);
      return response;
    },
  });

const useUploadProspectCsv = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.post("/prospect/upload_csv", data);
      return response;
    },
  });

const useProspectColumnMapping = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.post("/prospect/column_mapping", data);
      return response;
    },
  });

const useGetEnrichLeads = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.post(
        "/prospect/import/csv_enrich_leads",
        data
      );
      return response;
    },
  });

const useCompanyEnrichLeads = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.post(
        "/prospect/import/csv_company_enrich_leads",
        data
      );
      return response;
    },
  });

const useCompanySearchLeads = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.post(
        "/prospect/import/csv_company_search_leads",
        data
      );
      return response;
    },
  });

const useGetProspectsList = () =>
  useMutation({
    mutationFn: async (data) => {
      const { limit, page, sort_by, sort_order, company_id, active_tab } = data;
      const response = await axiosInt.post(
        `/prospect/fetch_lists?limit=${limit}&page=${page}&sort_by=${sort_by}&sort_order=${sort_order}&active_tab=${active_tab}`,
        { company_id }
      );
      return response;
    },
  });

const useArchiveProspect = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.put("/prospect/archive_prospect", data);
      return response;
    },
  });

const useEditProspectName = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.put("/prospect/edit_prospect_name", data);
      return response;
    },
  });

const useProspectFiltersInfo = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.post(
        "/prospect/prospect_filters_info",
        data
      );
      return response;
    },
  });

const useExportProspectLeads = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.post(
        "/prospect/export_prospect_leads",
        data
      );
      return response;
    },
  });

const useDeleteProspect = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.delete("/prospect/delete_list", { data });
      return response;
    },
  });

const useGetProspectShareLeads = () =>
  useMutation({
    mutationFn: async ({ company_id, prospect_id }) => {
      const response = await axiosInt.get(
        `/prospect/share/leadlist/${prospect_id}`
      );
      return response;
    },
  });

const useGetCollaborators = () =>
  useMutation({
    mutationFn: async () => {
      const response = await axiosInt.get(
        "/workspace/settings/get_collaborators"
      );
      return response;
    },
  });

const useProspectSearchQ = () =>
  useMutation({
    mutationFn: async ({ q_lead_fuzzy_name }) => {
      const response = await axiosInt.post("/prospect/q_lead_search", {
        q_lead_fuzzy_name,
      });
      return response;
    },
  });

const useGetSchools = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.get(
        "/fields/search-schools?query=" + data
      );
      return response;
    },
  });

const useGetMajors = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.get(
        "/fields/search-majors?query=" + data
      );
      return response;
    },
  });

const useGetSkills = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.get(
        "/fields/search-skills?query=" + data
      );
      return response;
    },
  });
const useGetIndustry = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.get(
        "/fields/industry-search?query=" + data
      );
      return response;
    },
  });

const useGetLocation = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.get(
        "/fields/search-locations?query=" + data
      );
      return response;
    },
  });

const useGetJobTitles = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt?.get("/fields/job-titles?query=" + data);
      return response;
    },
  });

const useManualPeopleEnrichLeads = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.post(
        "/prospect/import/manual_people_enrich_leads",
        data
      );
      return response;
    },
  });

const useManualCompanySearchLeads = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt?.post(
        "/prospect/import/manual_company_search_leads",
        data
      );
      return response;
    },
  });

const useManualCompanyEnrichLeads = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt?.post(
        "/prospect/import/manual_company_enrich_leads",
        data
      );
      return response;
    },
  });

const useShareProspectList = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt?.post("/prospect/share_list", data);
      return response;
    },
  });

const useSearchLeadsFromCopyPaste = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt?.post(
        "/prospect/import/search_from_copypaste_url",
        data
      );
      return response;
    },
  });
const useSearchViaLinkedInPost = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt?.post(
        "/prospect/import/count_of_leads_post_url",
        data
      );
      return response;
    },
  });
const useEnrichLeadsOfViaProfile = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt?.post(
        "/prospect/import/enrich_leads_post_url",
        data
      );
      return response;
    },
  });
const useEnrichLeadsFromCopyPaste = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt?.post(
        "/prospect/import/enrich_lead_from_copypaste_url",
        data
      );
      return response;
    },
  });

const useExportFailedEnrichedLeads = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt?.post(
        `/prospect/export_failed_enrich_leads`,
        data
      );
      return response;
    },
  });

export {
  useProspectSearch,
  useGetProspectLeads,
  useGetLeadInfo,
  useCreateProspect,
  useUploadProspectCsv,
  useCompanySearchLeads,
  useProspectColumnMapping,
  useGetEnrichLeads,
  useGetProspectsList,
  useArchiveProspect,
  useEditProspectName,
  useProspectFiltersInfo,
  useExportProspectLeads,
  useDeleteProspect,
  useGetProspectShareLeads,
  useGetCollaborators,
  useProspectSearchQ,
  useGetSchools,
  useGetMajors,
  useGetSkills,
  useGetJobTitles,
  useGetLocation,
  useManualPeopleEnrichLeads,
  useManualCompanySearchLeads,
  useManualCompanyEnrichLeads,
  useShareProspectList,
  useCompanyEnrichLeads,
  useGetIndustry,
  useGetLeadHistoryInfo,
  useEnrichLeadsFromCopyPaste,
  useSearchLeadsFromCopyPaste,
  useExportFailedEnrichedLeads,
  useSearchViaLinkedInPost,
  useEnrichLeadsOfViaProfile
};
