const Tabs = ({
  tab,
  setTab,
  tabOptions,
  isNewCampaignScreen = false,
  settingTab = false,
  isNewCampaignTabs = false,
  completedTabs = null,
  outlinedTab = false,
}) => {
  const handleCampaignTabNavigation = (targetTab) => {
    if (completedTabs?.includes(targetTab)) {
      setTab(targetTab);
    }
  };

  return (
    <div className="text-sm font-medium text-[#6B7280]">
      <ul
        className={`flex flex-wrap ${
          outlinedTab &&
          "bg-[#FAFAFA] border border-[#E9EAEB] inline-flex justify-start rounded-[9px] p-[4px] "
        }`}
      >
        {tabOptions?.map((item, index) => {
          const isActive = item?.value === tab;
          const isCompleted = completedTabs?.includes(item?.value);
          const isDisabled = !isCompleted && isNewCampaignTabs;
          return (
            <li className={`me-1 ${settingTab && "mr-[8px]"}`} key={index}>
              <span
                onClick={() => {
                  if (isDisabled) return;
                  if (isNewCampaignTabs) {
                    handleCampaignTabNavigation(item.value);
                  } else {
                    setTab?.(item.value);
                  }
                }}
                className={`inline-block px-[10px] py-[4px] mr-4 text-[13.5px] rounded-[6px] font-inter ${
                  isNewCampaignTabs && "!pt-1.5"
                }  ${
                  outlinedTab &&
                  "font-[600] text-[#717680] hover:!text-[#414651] rounded-[7.5px] !mr-1"
                } ${
                  isActive || (isActive && isDisabled)
                    ? " bg-[#EAE3FD] text-[#4338CA] hover:text-[#4338CA] shadow-md "
                    : ""
                } 
                 ${
                   outlinedTab &&
                   (isActive || (isActive && isDisabled)) &&
                   "bg-[#fff] !text-[#414651] font-[600] hover:!text-[#414651]"
                 }
                ${
                  isNewCampaignScreen
                    ? `!font-[400] text-[#252C32] px-[16px]`
                    : `font-[400] text-[#252C32]  ${
                        !isCompleted && isNewCampaignTabs
                          ? ""
                          : "hover:text-[#252C32]"
                      } px-[10px]`
                }
                ${
                  isActive &&
                  isNewCampaignTabs &&
                  "!bg-[#7F56D9] !text-[#fff] hover:!text-[#fff] font-[600] h-[33.39px] !px-3 !pt-1.5"
                }
                ${
                  !isCompleted && isNewCampaignTabs
                    ? "text-[#BBBBBB]"
                    : "cursor-pointer"
                }
                ${isDisabled && "!text-[#CBCCCC] cursor-not-allowed"}
               `}
              >
                {item?.label}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Tabs;
