import React from "react";
import Select, { components } from "react-select";
import userIcon from "assets/images/user.svg";
import CustomDropdownIndicator from "./arrow";
import Clock from "assets/images/clock.svg";
import RB2B from "assets/images/RB2B.png";
import webhook from "assets/images/webhooks.svg";

const ValueContainer = ({
  children,
  hideMultiSelectValues,
  placeholder,
  placeholderImage,
  isRb2b,
  isWebhook,
  ...props
}) => {
  return (
    <components.ValueContainer {...props}>
      <div className="flex">
        {isRb2b ? (
          <img
            src={RB2B}
            alt=""
            className="mr-[8px] mt-[5px] w-[22px] h-[22px]"
          />
        ) : (
          <>
            {isWebhook ? (
              <img
                src={webhook}
                alt=""
                className="mr-[8px] mt-[5px] w-[22px] h-[22px]"
              />
            ) : (
              placeholderImage && (
                <img src={userIcon} alt="" className="mr-[8px]" />
              )
            )}
          </>
        )}
        {React.Children.map(children, (child, index) => {
          if (child && child.type !== components.MultiValue) {
            return child;
          }
        })}
      </div>
    </components.ValueContainer>
  );
};

const CustomSingleValue = (props) => {
  const {
    isHubSpot,
    isNewCampaignSelect,
    placeholderImage,
    isTimeZoneSelect,
    ...rest
  } = props;
  return (
    <components.SingleValue
      {...rest}
      className="flex items-center !text-[#101828]"
    >
      {isHubSpot || (isNewCampaignSelect && !placeholderImage) ? (
        <img
          src={props.data.imageUrl}
          alt={props.data?.label}
          className={`mr-2 ${
            isNewCampaignSelect ? "w-[20px] h-[20px]" : "w-[16px] h-[16px]"
          }`}
        />
      ) : null}
      {isTimeZoneSelect ? (
        <img
          src={Clock}
          alt={Clock}
          className={`mr-2 ${
            isNewCampaignSelect ? "w-[20px] h-[20px]" : "w-[16px] h-[16px]"
          }`}
        />
      ) : null}
      {props.data?.label}
    </components.SingleValue>
  );
};

const ReactSelect = (props) => {
  const {
    onChange,
    options,
    placeholder,
    value,
    isMulti,
    placement,
    isSearch,
    menuMaxHeight,
    onFocus,
    onInputChange,
    handleEnterKeyPressed,
    inputValue,
    selectRef,
    hideMultiSelectValues,
    isHubSpot = false,
    isNewCampaignSelect = false,
    setIsSelectOpen,
    isLeadsComponent = false,
    placeholderImage = false,
    className,
    readOnly = false,
    isLoading = false,
    isTimeZoneSelect = false,
    isRb2b = false,
    isWebhook = false,
  } = props;

  const CustomImgOption = ({ isHubSpot, isTimeZoneSelect, ...props }) => {
    return (
      <components.Option {...props} className="flex items-center !text-red-500">
        {isHubSpot || isNewCampaignSelect ? (
          props.data?.isRb2b ? (
            <img
              src={RB2B}
              alt=""
              className="mr-[8px] mt-[5px] w-[22px] h-[22px]"
            />
          ) : (
            <>
              {props.data?.isWebhook ? (
                <img
                  src={webhook}
                  alt={"webhook"}
                  className={`mr-2 ${
                    isNewCampaignSelect
                      ? "w-[20px] h-[20px]"
                      : "w-[16px] h-[16px]"
                  }`}
                />
              ) : (
                <img
                  src={props.data.imageUrl}
                  alt={props.data?.label}
                  className={`mr-2 ${
                    isNewCampaignSelect
                      ? "w-[20px] h-[20px]"
                      : "w-[16px] h-[16px]"
                  }`}
                />
              )}
            </>
          )
        ) : null}
        {isTimeZoneSelect ? (
          <img
            src={Clock}
            alt={Clock}
            className={`mr-2 ${
              isNewCampaignSelect ? "w-[20px] h-[20px]" : "w-[16px] h-[16px]"
            }`}
          />
        ) : null}
        {props.data?.label}
      </components.Option>
    );
  };
  const handleSpaceKeyPressed = (event) => {
    if (event?.key === " ") {
      event?.preventDefault();
    }
  };
  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      fontSize: "16px",
      color: "#6670854D",
      // marginTop:"5px"
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "16px",
      color: "#667085",
    }),
    valueContainer: (provided) => ({
      ...provided,
      fontSize: "16px",
      color: "#667085",
    }),
  };

  return (
    <>
      {(!isLeadsComponent && onInputChange) ||
      handleEnterKeyPressed ||
      inputValue ||
      selectRef ? (
        <Select
          ref={selectRef}
          onChange={(e) => onChange(e)}
          options={options}
          classNamePrefix={`leads-profile-select`}
          className="!font-inter select-scroll-styling w-full"
          value={""}
          isMulti={isMulti}
          placeholder={placeholder}
          getOptionLabel={(option) => option?.label}
          getOptionValue={(option) => option?.value}
          menuPlacement={placement}
          isSearchable={isSearch}
          maxMenuHeight={menuMaxHeight}
          onFocus={onFocus}
          onInputChange={(value) => onInputChange(value)}
          onKeyDown={(event) => handleEnterKeyPressed(event)}
          inputValue={inputValue}
          styles={customStyles}
          isDisabled={readOnly || isLoading}
          isLoading={isLoading}
        />
      ) : (
        <Select
          onChange={(e) => onChange(e)}
          options={options}
          classNamePrefix={
            isLeadsComponent
              ? "isLeadsComponent-select"
              : "leads-profile-select"
          }
          className="!font-inter select-scroll-styling w-full"
          value={value}
          isMulti={isMulti}
          placeholder={placeholder}
          getOptionLabel={(option) => option?.label}
          getOptionValue={(option) => option?.value}
          menuPlacement={placement}
          isSearchable={isSearch}
          maxMenuHeight={menuMaxHeight}
          onFocus={onFocus}
          onMenuOpen={() => setIsSelectOpen?.(true)}
          onKeyDown={(event) => handleSpaceKeyPressed(event)}
          onMenuClose={() => setIsSelectOpen?.(false)}
          styles={customStyles}
          isDisabled={readOnly || isLoading}
          isLoading={isLoading}
          components={{
            ValueContainer: (props) => (
              <ValueContainer
                {...props}
                hideMultiSelectValues={hideMultiSelectValues}
                placeholder={placeholder}
                placeholderImage={placeholderImage}
                isRb2b={isRb2b}
                isWebhook={isWebhook}
              />
            ),
            Option: (props) => (
              <CustomImgOption
                {...props}
                isHubSpot={isHubSpot}
                isTimeZoneSelect={isTimeZoneSelect}
                isNewCampaignSelect={isNewCampaignSelect}
                isRb2b={isRb2b}
                isWebhook={isWebhook}
              />
            ),
            SingleValue: (props) => (
              <CustomSingleValue
                {...props}
                isHubSpot={isHubSpot}
                isNewCampaignSelect={isNewCampaignSelect}
                isTimeZoneSelect={isTimeZoneSelect}
                placeholderImage={placeholderImage}
              />
            ),
            DropdownIndicator: CustomDropdownIndicator,
          }}
        />
      )}
    </>
  );
};

export default ReactSelect;
